import React, { useState,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';

import DeletePerformanceRecords from 'assets/PerformanceRecords/DeleteTrainingSessionPerformanceRecords.png'
import View from 'assets/PerformanceRecords/ViewTrainingSessionPerformanceRecords.png'
import HomeBtn from 'assets/CorporateProfileSetup/HomeBtn.png'
import tablebg from 'assets/CourseTypeManagement/TableBG.svg'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
// import bg from 'assets/CoporateUserOverView/CoporateUserOverviewBG.png'
import bg from 'assets/bg.png'
import logo from 'assets/logo.png'

import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'
import AccountInfoNew from 'components/AccountInfoNew'
import modal from 'actions/modal'
import { InfoTable7Col } from 'components/Tables/InfoTable7Col'
import AccountInfo from 'components/AccountInfo';
import TableBG from 'components/Tables/TableBG'
import yellowBg from 'assets/BaseImg/yellowBgLong.png'
import darkBG from 'assets/BaseImg/darkBgLong.png'

// table
import SimpleBarReact from "simplebar-react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { corporatePageActions } from 'actions/coporatePageActions';
import information from 'assets/information.png'

const useStyles = makeStyles(() => ({
header: {
        color: '#fffffe',
        fontFamily: 'impact',
        fontSize: '2.5rem'
    },
  // back button
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'fixed',
    bottom: '1rem',
    right: '2rem',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
  // page top
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '12rem'
  },
  container2: {
  width: "100vw",
  height: "84vh",
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  '& h3': {
    marginBottom: '2rem'
  }
  },
  buttonContainer3: {
  cursor: "pointer",
  paddingTop: "2rem",
  display: "flex",
  justifyContent: "space-evenly",
  // '& img': {
  //     width: '38vw'
  //   }
  },
  inputWrapper: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2rem',
      width: '20rem'
    },
    '& img': {
      width: '26vw'
    }
  },
  inputWrapperTrainee: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginTop: '2rem',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2rem',
      width: '20rem'
    },
    '& img': {
      width: '26vw'
    }
  },
  inputWrapperDark: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#666767',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2rem',
      width: '20rem'
    },
    '& img': {
      width: '26vw'
    }
  },
  // for scroll bar
  scroll: {
    margin: '20px',
    padding: '2px',
    width: '147vh',
    height: '54vh',
    // border: '1px solid #ccc',
    borderRadius: '4px',
    /* background-color: #19233c; */
    /* color: #fff; */
    position: "relative",
    right: "1.5rem",
    bottom: "1.5rem"
  },
  // for new scroll bar
  simpleBar: {
    maxHeight: '51vh'
  },
  table: {
    minWidth: 650,
  },
  cell: {
    borderBottom: "none",
    color: "#FFD84C",
    alignItems: "center",
    fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    fontSize: '1.2rem',
  },
  cellHead: {
    borderBottom: "none",
    color: "#FFFFFE",
    alignItems: "center",
    fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    fontSize: '1.2rem',
  },
  highlightRow: {
    border: 'solid',
    borderWidth: '2px',
    color: 'white'
  },
  NohighlightRow: {
    border: 'none',
  },
  darkBG: {
    backgroundColor: 'black',
    zIndex: 1,
    position: "fixed",
    width: "100vw",
    height: "100vh",
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    '& h1': {
      color: 'white',
      fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
      letterSpacing: '1px',
      fontSize: '3vw'
    }
  },
  waiting: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
    outline: "none",
    position: "absolute",
    backgroundColor: "black",
    justifyContent: "center",
    alignItems: "center",
    '& h1': {
      color: '#fff',
      marginBottom: '5%',
      fontSize: '6vh',
      fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
      letterSpacing: '1px',
    },
    '& img': {
      width: '16vw%',
      cursor: 'pointer'
    }
  },
  checkbox: {
    color: '#ffffff',
  },
  information: {
    left: '5vw',
    width: '40px',
    bottom: '10vh',
    height: '40px',
    position: 'absolute',
    cursor: 'pointer',

    '& img': {
      width: '100%',
    }
  }
}));

const TraineePerformanceRecords = () => {
    const dispatch = useDispatch()
    const classes  = useStyles();
    const accountTypeInfo = useSelector((state: any) => state.auth.loggedinuser.user.accounttype)
    const name = useSelector((state: any) => state.auth.loggedinuser.user.name)
    const accessToken = useSelector((state: any) => state.auth.loggedinuser.token.accessToken)
    const userID = useSelector((state: any) => state.auth.loggedinuser.user.id)
    const belongToCorporateId = useSelector((state: any) => state.auth.loggedinuser.user.belongstouser)

    const Delete = () => {
      // dispatch(corporatePageActions.TriggerToSendDeleteIds(true))
      rowToHighLight.forEach((row) => deleteRecords(row))
      dispatch(modal.show.started({type: 'DeletePerformanceRecords', source: ''}))
      setrowToHighLight([])
      // dispatch(push('/performancerecords'))
      settoShowWaitingPage(true)
      setcheckStatus(false)
      setTimeout(() => {
        getcourseInfoForRecordsTable()
        settoShowWaitingPage(false)
      }, 4000);
    }
  // delete using axios for gamerecords
  const deleteRecords = async (id: any) => {
    // console.log(id)
    const DataOfUser = await axios({
      method: 'delete',
      // url: process.env.REACT_APP_BACKEND_URL + '/props/',
      url: `https://museum.createscape.com.sg/gameresult/${id}`,
      headers: { "content-type": "application/json" }
    })
    return DataOfUser;
  }
  const viewGameResult = () => {
    dispatch(modal.show.started({type: 'viewGameResult', source: ''}))
  }
  const temp = () => {
   let x = 1
  }
  let tableDataTrainee: { col1: string; col2: string; col3: string; col4: string; col5: string; col6: string; col7: string; key: string; }[] = []
  const [toStop, setToStop] = useState(false)
  const [toStopRefresh, settoStopRefresh] =useState(false)
  const [toShowWaitingPage, settoShowWaitingPage] = useState(false)

  // checkbox
  const [keyToDelete, setkeyToDelete] = useState(['string'])
  const [placeholder, setplaceholder] = useState(false)
  let arrayToContainID: string[] = []
  const [rowToHighLight, setrowToHighLight] = useState(arrayToContainID)
  const checkBoxInfo = (event: React.ChangeEvent<HTMLInputElement>, f: any) => {
    let checked = event.target.checked
    if (checked === true) {
      rowToHighLight.push(f.key)
    } else {
      let index = rowToHighLight.indexOf(f.key)
      rowToHighLight.splice(index, 1)
    }
    if (rowToHighLight.length != 0) {
      setcheckStatus(true)
    } else {
      setcheckStatus(false)
    }
    rowInfo(f, checked, f.key)
    console.log(rowToHighLight)
  };
  let keyIdDeleteArray: any[] = []
  const rowInfo = (f: any, checked: boolean, key: any) => {
    setkeyToDelete((initialState) => [...initialState, key])
    //dispatch(corporatePageActions.performanceRecordsTableRowIndex(rows.indexOf(f), checked))
  }

  const [justEnterPage, setjustEnterPage] = useState(true)
  useEffect(() => {
    // getUserData()
    if (justEnterPage === true) {
    switch (accountTypeInfo) {
      case 'TRAINEE':
        settoShowWaitingPage(true)
        setTimeout(() => {
          getCourseInfoOfTrainee()
          setjustEnterPage(false)
          settoShowWaitingPage(false)
        }, 1000);
      break;
      case 'CORPORATE':
      settoShowWaitingPage(true)
      setTimeout(() => {
        getcourseInfoForRecordsTable()
        setjustEnterPage(false)
        settoShowWaitingPage(false)
      }, 3000);
      break;
      default:
        temp()
    }
    } else {
      temp()
    }

  })
  const getcourseInfoForRecordsTable = async () => {
    const DataOfUser = await axios ({
      method: 'get',
      url: 'https://museum.createscape.com.sg/course?take=70',
      headers: {
        "content-type": "application/json",
      }
    })
    // console.log(DataOfUser)
    getUserDataforRecordsTable(DataOfUser.data.data)
    return DataOfUser
  }
  function convertDate(value: any) {
    let newDate1 = value.substring(0, 10).split('-').reverse().join('/');
    var hours = parseInt(value.substring(11, 13), 10),
        minutes = parseInt(value.substring(14, 17), 10),
        ampm    = 'am';
    if (hours == 12) {
      ampm = 'pm';
    } else if (hours == 0) {
      hours = 12;
    } else if (hours > 12) {
      hours -= 12;
      ampm = 'pm';
    }

    let newDate2 = hours + ':' + minutes + ' ' + ampm;
    let newConstructedDate = `${newDate1} ${newDate2}`
    return newConstructedDate
  }
  const getUserDataforRecordsTable = async (courseData: any) => {
    const DataOfUser = await axios({
      method: 'get',
      url: 'https://museum.createscape.com.sg/gameresult?order=DESC',
      headers: {
        "content-type": "application/json",
      }
    })

    // setToStop(true)
    // console.log(DataOfUser)
    let gameResultsData = DataOfUser.data.data
    // console.log(gameResultsData)
    let sessionid = 'nil'
    let courseType = 'nil'
    let courseName = 'nil'
    let GroupName = 'nil'
    let SessionDate = 'nil'
    let SessionTime = 'nil'
    let userName = 'Nil'
    const mydate = require('current-date')

    // console.log(courseData)
    let tableData: { col1: string; col2: string; col3: string; col4: string; col5: string; col6: string; col7: string; key: string; }[] = []
    courseData.map((course: any)=>{
    gameResultsData.forEach(
      (gamResult: any) => {
        if (belongToCorporateId.includes(gamResult.user.id) === true && gamResult.course.id === course.id) {
          let info = {
            col1: course.coursetype.name,
            col2: gamResult.course.name,
            col3: gamResult.user.name,
            col4: convertDate(gamResult.datetaken),
            // col4: mydate('date'),
            col5: gamResult.timetaken,
            col6: gamResult.percentcompletion,
            col7: gamResult.hintused,
            key: gamResult.id
          }
          gather(info)
        } else {
          temp()
        }
      }
    )
  })
    function gather(object: any) {
      tableData.push(object)
    }
    console.log(tableData)
    setRows(tableData?.reverse())
    dispatch(corporatePageActions.performanceRecordsTable(sessionid, courseType, courseName, userName, GroupName, SessionDate, SessionTime, tableData))
    return DataOfUser;
  }
  const getCourseInfoOfTrainee = async () => {
    const DataOfUser = await axios({
      method: 'get',
      // url: 'https://museum.createscape.com.sg/gameresult?take=70',
      url: `https://museum.createscape.com.sg/course?page=1&take=70`,
      headers: {
        "content-type": "application/json",
      }
    })
    getTraineeDataforRecordsTable(DataOfUser.data.data)
    return DataOfUser
  }
  const getTraineeDataforRecordsTable = async (courseInfo: any) => {
    const DataOfUser = await axios({
      method: 'get',
      url: `https://museum.createscape.com.sg/gameresult/${userID}?take=70&order=DESC`,
      headers: {
        "content-type": "application/json",
      }
    })
    let gameResultsData = DataOfUser.data.data
    let sessionid = 'nil'
    let courseType = 'nil'
    let courseName = 'nil'
    let GroupName = 'nil'
    let SessionDate = 'nil'
    let SessionTime = 'nil'
    let userName = 'Nil'
    let NewDate = 'NIL'
    const mydate = require('current-date')
    courseInfo.map((course: any)=> {
    gameResultsData.forEach(
      (gamResult: any) =>
      { if (gamResult.course.id === course.id){
        gather({
          col1: course.coursetype.name,
          col2: gamResult.course.name,
          col3: name,
          col4: convertDate(gamResult.datetaken),
          col5: gamResult.timetaken,
          col6: gamResult.percentcompletion,
          col7: gamResult.hintused,
          key: gamResult.id

        })
      }
    }
    )
    })
    function gather(object: any) {
        tableDataTrainee.push(object)
       console.log(tableDataTrainee)
      dispatch(corporatePageActions.performanceRecordsTable(sessionid, courseType, courseName, userName,
      GroupName, SessionDate, SessionTime, tableDataTrainee))
    }
    setRows(tableDataTrainee.reverse())
    return DataOfUser;
  }

  const tableData = useSelector((state: any) => state.corporate.tableData)
  let y = tableData
  // console.log(tableDataTrainee)
  const [rows = [],setRows] = React.useState(
    y
        // [
        //   {
        // col1: 'Course Type',
        // col2: 'Course Name',
        // col3: 'Trainee/User Name',
        // col4: 'Session Date',
        // col5: 'Game Time',
        // col6: 'Quiz Score',
        // col7: 'Hint Used',
        // key: "0"
        //   }
        // ]
      )
  const [rowsTrainee, setRowsTrainee] = React.useState(
    tableDataTrainee
  )
    const [colsCoporate] = React.useState([{
        col1: 'Course Type',
        col2: 'Course Name',
        col3: 'Trainee/User Name',
        col4: 'Session Date',
        col5: 'Game Time',
        col6: 'Percentage Completed',
        col7: 'Hint Used',
        col8: 'Select'
        }]);

  const [colsAgent] = React.useState([{
    col1: 'Course Type',
    col2: 'Course Name',
    col3: 'Trainee/User Name',
    col4: 'Session Date',
    col5: 'Game Time',
    col6: 'Percentage Completed',
    col7: 'Hint Used',
  }]);

  // update button is clicked
  const updateButtonClicked = () => {
    settoShowWaitingPage(true)
    setTimeout(() => {
      getcourseInfoForRecordsTable()
      setjustEnterPage(false)
      settoShowWaitingPage(false)
    }, 3000);
  }

  // update button for trainee is clicked
  const updateButtonTraineeClicked = () => {
    settoShowWaitingPage(true)
    setTimeout(() => {
      getCourseInfoOfTrainee()
      setjustEnterPage(false)
      settoShowWaitingPage(false)
    }, 3000);
  }


  // retrieving selected table rows index and checked status
  const index = useSelector((state: any) => state.corporate.TableRowIndex)
  // let checkStatus = useSelector((state: any) => state.corporate.IsitChecked)
  const [checkStatus, setcheckStatus] =  useState(false)
  const buttons = (value: any) => {
    switch (value) {
      case true:
        return (
          <div className={classes.buttonContainer3}>
            {/* <button onClick={()=> populateTable()}>show records</button> */}
            <div className={classes.inputWrapper} onClick={() => Delete()}>
              <img src={yellowBg} alt="bg" />
              <p>DELETE PERFORMANCE RECORDS</p>
            </div>
            <div className={classes.inputWrapper} onClick={() => updateButtonClicked()}>
              <img src={yellowBg} alt="bg" />
              <p>UPDATE</p>
            </div>
            {/* <div className={classes.inputWrapper} onClick={() => viewGameResult()}>
              <img src={yellowBg} alt="bg" />
              <p>VIEW PERFORMANCE RECORDS</p>
            </div> */}
            {/* <div onClick={() => Delete()}>
              <img src={DeletePerformanceRecords} alt="Delete" />
            </div>
            <div onClick={() => dispatch(push('/PerformanceRecords'))}>
              <img src={View} alt="View" />
            </div> */}
          </div>
        )
      default:
        return (
          <div className={classes.buttonContainer3}>
            {/* <button onClick={() => populateTable()}>show records</button> */}
            <div className={classes.inputWrapperDark}>
              <img src={darkBG} alt="bg" />
              <p>DELETE PERFORMANCE RECORDS</p>
            </div>
            <div className={classes.inputWrapper} onClick={() => updateButtonClicked()}>
              <img src={yellowBg} alt="bg" />
              <p>UPDATE</p>
            </div>
            {/* <div className={classes.inputWrapperDark}>
              <img src={darkBG} alt="bg" />
              <p>VIEW PERFORMANCE RECORDS</p>
            </div> */}
          </div>
        )
    }
  }
  // console.log(accountTypeInfo)
  const typeOfPerformanceRecordPage = (accountType: any) => {
    switch (accountType) {
      case 'CORPORATE':
        return (
          <div>
            <div className={classes.topInfo}>
              <img src={logo} alt="logo" className={classes.logo} />
              <AccountInfoNew />
            </div>
            <div className={classes.container2}>
              <h3 className={classes.header}>PAST TRAINING SESSIONS PERFORMANCE RECORDS</h3>
              <TableBG bg={tablebg}>
                <div className={classes.scroll}>
                  <SimpleBarReact className={classes.simpleBar}>
                    {/* <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}> */}
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        {colsCoporate.map((col) =>
                          <TableRow>
                            <TableCell className={classes.cellHead} align="center">{col.col1}</TableCell>
                            <TableCell className={classes.cellHead} align="center">{col.col2}</TableCell>
                            <TableCell className={classes.cellHead} align="center">{col.col3}</TableCell>
                            <TableCell className={classes.cellHead} align="center">{col.col4}</TableCell>
                            <TableCell className={classes.cellHead} align="center"> {col.col5}</TableCell>
                            <TableCell className={classes.cellHead} align="center"> {col.col6}</TableCell>
                            <TableCell className={classes.cellHead} align="center"> {col.col7}</TableCell>
                            <TableCell className={classes.cellHead} align="center"> {col.col8}</TableCell>
                          </TableRow>
                        )}
                      </TableHead>
                      <TableBody>
                        {rows.map((row: any) => (
                          <TableRow key={row.key} className={rowToHighLight.includes(row.key) === true ? classes.highlightRow : classes.NohighlightRow}>
                            <TableCell align="center" className={classes.cell}>{row.col1}</TableCell>
                            <TableCell className={classes.cell} align="center" >{row.col2}</TableCell>
                            <TableCell className={classes.cell} align="center">{row.col3}</TableCell>
                            <TableCell className={classes.cell} align="center">{row.col4}</TableCell>
                            <TableCell className={classes.cell} align="center">{row.col5}</TableCell>
                            <TableCell className={classes.cell} align="center">{row.col6}</TableCell>
                            <TableCell className={classes.cell} align="center">{row.col7}</TableCell>
                            <TableCell className={classes.cell} align="center"><Checkbox className={classes.checkbox} onChange={(e) => checkBoxInfo(e, row)} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </SimpleBarReact>
                  {/* </CustomScrollbars> */}
                </div>
              </TableBG>
              {buttons(checkStatus)}
            </div>
            <div className={classes.backBtn} onClick={() => dispatch(push('/trainee/overview'))}>
              <img src={BackSymbol} alt="BackSymbol" />
              <p>BACK</p>
            </div>
          </div>
        )
      case 'TRAINEE':
        return (
          <div>
            <div className={classes.topInfo}>
              <img src={logo} alt="logo" className={classes.logo} />
              <AccountInfoNew />
            </div>
            <div className={classes.container2}>
              <h3 className={classes.header}>PAST TRAINING SESSIONS PERFORMANCE RECORDS</h3>
              <TableBG bg={tablebg}>
                <div className={classes.scroll}>
                  <SimpleBarReact className={classes.simpleBar}>
                    {/* <CustomScrollbars autoHide autoHideTimeout={500} autoHideDuration={200}> */}
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        {colsAgent.map((col) =>
                          <TableRow>
                            <TableCell className={classes.cellHead} align="center">{col.col1}</TableCell>
                            <TableCell className={classes.cellHead} align="center">{col.col2}</TableCell>
                            <TableCell className={classes.cellHead} align="center">{col.col3}</TableCell>
                            <TableCell className={classes.cellHead} align="center">{col.col4}</TableCell>
                            <TableCell className={classes.cellHead} align="center"> {col.col5}</TableCell>
                            <TableCell className={classes.cellHead} align="center"> {col.col6}</TableCell>
                            <TableCell className={classes.cellHead} align="center"> {col.col7}</TableCell>
                          </TableRow>
                        )}
                      </TableHead>
                      <TableBody>
                        {rows.map((row: any) => (
                          <TableRow key={row.key}>
                            <TableCell className={classes.cell} align="center">{row.col1}</TableCell>
                            <TableCell className={classes.cell} align="center">{row.col2}</TableCell>
                            <TableCell className={classes.cell} align="center">{row.col3}</TableCell>
                            <TableCell className={classes.cell} align="center">{row.col4}</TableCell>
                            <TableCell className={classes.cell} align="center">{row.col5}</TableCell>
                            <TableCell className={classes.cell} align="center">{row.col6}</TableCell>
                            <TableCell className={classes.cell} align="center">{row.col7}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </SimpleBarReact>
                  {/* </CustomScrollbars> */}
                </div>
              </TableBG>
              <div className={classes.inputWrapperTrainee} onClick={() => updateButtonTraineeClicked()}>
                <img src={yellowBg} alt="bg" />
                <p>UPDATE</p>
              </div>
            </div>
            <div className={classes.backBtn} onClick={() => dispatch(push('/trainee/overview'))}>
              <img src={BackSymbol} alt="BackSymbol" />
              <p>BACK</p>
            </div>
          </div>
        )
        case 'waiting':
          return (
            <div className={classes.waiting}>
              <h1></h1>
            </div>
          )
        default:
          // console.log(accountTypeInfo)
          return(
            <div>
              <h1>ERROR NO ACCOUNT TYPE DETECTED</h1>
            </div>
          )
    }
  }
  // console.log(accountTypeInfo)
  const showWaitingPageOrNot = (yesOrNo: any) => {
    if (yesOrNo === true) {
    return (
    <div className={classes.darkBG}>
      <h1>LOADING TABLE INFORMATION...</h1>
    </div>
    )
    } else {
      temp()
    }
  }
    return (
      <div>
        {/* <div className={toShowWaitingPage===true?classes.darkBG:""}>
          <h1>PLEASE WAIT WHILE WE UPDATE THE TABLE</h1>
        </div> */}
        {showWaitingPageOrNot(toShowWaitingPage)}
        <BaseLayoutAdjustable bg={bg}>
          {typeOfPerformanceRecordPage(accountTypeInfo)}
        {/* {typeOfPerformanceRecordPage("CORPORATE")} */}
          <div className={classes.information} onClick={() => window.open('https://www.createscape.com.sg/documents/trainee-account-user-manual.pdf')} >
            <img src={information}/>
          </div>
        </BaseLayoutAdjustable>
      </div>
    )
}

export default TraineePerformanceRecords;
