import { from, Observable, of } from 'rxjs'
import { ofType, Epic } from 'redux-observable'
import { AnyAction } from 'typescript-fsa'
import { ofAction } from 'typescript-fsa-redux-observable'
import actions from 'actions/coursemanagerinfo'
import { ajax } from 'rxjs/ajax'
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators'

export const createCourseManagerInfo: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.createcoursemanagerinfo.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/coursemanagerinfo`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json'/*  */ },
            body: param.payload.coursemanagerinfo
        }).pipe(
            map(data => {
                return actions.createcoursemanagerinfo.done({
                    params: param.payload,
                    result: { coursemanagerinfo: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.createcoursemanagerinfo.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getCourseManagerInfoByUserId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.getcoursemanagerinfobyuserid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/coursemanagerinfo/` + param.payload.userid,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.getcoursemanagerinfobyuserid.done({
                    params: param.payload,
                    result: { coursemanagerinfo: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.getcoursemanagerinfobyuserid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const updateCourseManagerInfoByUserId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.updatecoursemanagerinfobyuserid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/coursemanagerinfo/` + param.payload.userid,
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.coursemanagerinfo
        }).pipe(
            map(data => {
                return actions.updatecoursemanagerinfobyuserid.done({
                    params: param.payload,
                    result: { coursemanagerinfo: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.updatecoursemanagerinfobyuserid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)