import { propsConstants } from '../constants/index'

const initialState = {
    activeParam: '',
    activeParam1: '',
    locationType: '',
    propsType: '',
    propsName: '',
    file: '',
    levelSequence1: 1,
    levelSequence2: 2,
    levelSequence3: 3,
    levelSequence4: 4,
    phoneNumber1: 0,
    phoneNumber2: 0,
    phoneNumber3: 0,
    phoneNumber4: 0,
    CourseManagerName: 'empty',
    CourseManagerdepartment: 'empty',
    CourseManageremail:'empty',
    CourseManagerrole:'empty',
    CourseManagerpassword:'empty',
    TableRowIndex: null,
    IsitChecked: false,
    PasswordSymbol: '*',
    propsNameParam: 'none',
    propsLoading:true,
    propsData:null,
    messageValueTitle:null,
    messageValueContent:null,
    weblinkValue:null,
    pdfImgeUploadValue: null,
    currentParam:'none',
    quizTextQuestionValue: null,
    quizTextAnswer1Value:null,
    quizTextAnswer2Value:null,
    quizChoiceQnsvalue: null,
    quizChoiceAnswerValues:null,
    quizChoiceAnswerAvalue:null,
    quizChoiceAnswerBvalue: null,
    quizChoiceAnswerCvalue: null,
    quizChoiceAnswerDvalue: null,
    quizChoiceCorrectAnsValue: 'left',
    popUpValue:'none'
};

export default function propsReducer(state = initialState,action:any) {
    switch(action.type) {
        case propsConstants.PARAM_ACTIVE_REQUEST :
            return {
                ...state,
                activeParam:action.param
            }
        case propsConstants.PARAM_ACTIVE_REQUEST1:
          return {
            ...state,
            activeParam1: action.param
          }
        case propsConstants.PARAM_LOCATION_TYPE:
          return {
            ...state,
            locationType: action.param
          }
        case propsConstants.PARAM_PROPS_TYPE:
          return {
            ...state,
            propsType: action.param
          }
        case propsConstants.PARAM_PROPS_NAME:
          return {
            ...state,
            propsName: action.param
          }
        case propsConstants.PARAM_FILE:
          return {
            ...state,
            file: action.param
          }
        case propsConstants.PARAM_LEVEL_SEQUENCE_1:
          return {
            ...state,
            levelSequence1: action.param
          }
        case propsConstants.PARAM_LEVEL_SEQUENCE_2:
          return {
            ...state,
            levelSequence2: action.param
          }
        case propsConstants.PARAM_LEVEL_SEQUENCE_3:
          return {
            ...state,
            levelSequence3: action.param
          }
        case propsConstants.PARAM_LEVEL_SEQUENCE_4:
          return {
            ...state,
            levelSequence4: action.param
          }
        case propsConstants.PARAM_PHONE_NUMBER_1:
          return {
            ...state,
            phoneNumber1: action.param
          }
        case propsConstants.PARAM_PHONE_NUMBER_2:
          return {
            ...state,
            phoneNumber2: action.param
          }
        case propsConstants.PARAM_PHONE_NUMBER_3:
          return {
            ...state,
            phoneNumber3: action.param
          }
        case propsConstants.PARAM_PHONE_NUMBER_4:
          return {
            ...state,
            phoneNumber4: action.param
          }
      case propsConstants.COURSE_MANAGER_SETUP_DETAILS:
        return {
          ...state,
          CourseManagerName: action.name,
          CourseManagerdepartment: action.department,
          CourseManageremail: action.email,
          CourseManagerrole: action.role,
          CourseManagerpassword: action.password,
          PasswordSymbol: action.passwordSymbol
        }
      case propsConstants.TABLE_ROW_INDEX:
        return {
          ...state,
          TableRowIndex: action.index,
          IsitChecked: action.checked
        }
        case propsConstants.PROPS_NAME_PARAM:
          return {
            ...state,
            propsNameParam: action.param
          }
        case propsConstants.GET_PROPS_REQUEST:
            return{
                ...state,
                propsLoading:true,
            }
        case propsConstants.GET_PROPS_SUCCESS:
            return{
                ...state,
                propsData:action.data,
                propsId:action.propsId,
                activeParam:action.paramType,
                messageValue:action.paramMessage,
                weblinkValue:action.paramWeblink
            }
        case propsConstants.UPDATE_PROPS_REQUEST:
          return {
            ...state,
            popUpValue: action.value
          }
          case propsConstants.UPDATE_PDF_IMAGEUPLOAD_REQUEST:
          return {
            ...state,
            pdfImgeUploadValue: action.value
          }
        case propsConstants.UPDATE_MESSAGE_TITLE_REQUEST:
              return {
                  ...state,
                  messageValueTitle:action.value
              }
        case propsConstants.UPDATE_MESSAGE_CONTENT_REQUEST:
          return {
            ...state,
            messageValueContent: action.value2
          }
        case propsConstants.UPDATE_WEBLINK_REQUEST:
            return {
                ...state,
                weblinkValue:action.value
            }
      case propsConstants.UPDATE_QUIZ_QNS:
        return {
          ...state,
          quizTextQuestionValue: action.value,
        }
      case propsConstants.UPDATE_QUIZ_TEXT1:
        return {
          ...state,
          quizTextAnswer1Value: action.value,
        }
      case propsConstants.UPDATE_QUIZ_TEXT2:
        return {
          ...state,
          quizTextAnswer2Value: action.value
        }
      case propsConstants.UPDATE_QUIZ_CHOICE_QUESTION:
        return {
          ...state,
          quizChoiceQnsvalue: action.value,
        }
      case propsConstants.UPDATE_QUIZ_CHOICE:
          return {
            ...state,
            quizChoiceAnswerValues: action.value,
          }
      case propsConstants.UPDATE_QUIZ_CHOICE_A:
        return {
          ...state,
          quizChoiceAnswerAvalue: action.value,
        }
      case propsConstants.UPDATE_QUIZ_CHOICE_B:
        return {
          ...state,
          quizChoiceAnswerBvalue: action.value,
        }
      case propsConstants.UPDATE_QUIZ_CHOICE_C:
        return {
          ...state,
          quizChoiceAnswerCvalue: action.value,
        }
      case propsConstants.UPDATE_QUIZ_CHOICE_D:
        return {
          ...state,
          quizChoiceAnswerDvalue: action.value,
        }
      case propsConstants.UPDATE_QUIZ_CHOICE_CORRECT_ANS:
        return {
          ...state,
          quizChoiceCorrectAnsValue: action.value,
        }
        default:
            return state;
    }
}
