import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface UpdateUserParam { userid: string, user: any }
interface UpdateUserResult { user: any }
interface UpdateUserError { error: string }

interface GetUserParam { userid: string, accounttype: string }
interface GetUserResult { user: any }
interface GetUserError { error: string }

interface DeleteParam { userids: string }
interface DeleteResult { user: any }
interface DeleteError { error: string }

export default {
    updateuserbyid: ac.async<UpdateUserParam, UpdateUserResult, UpdateUserError>(ActionTypes.UPDATE_USERBYID),
    getuserbyaccounttype: ac.async<GetUserParam, GetUserResult, GetUserError>(ActionTypes.GET_USERBYACCOUNTTYPE),
    delete: ac.async<DeleteParam, DeleteResult, DeleteError>(ActionTypes.USER_DELETE),
}