import React, { useState } from 'react';
import { useDispatch } from 'react-redux'

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import artarklogo from '../assets/white-logo.svg'
import landscape from '../assets/landscape-icon.svg'

const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapper : {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    left: 0,
    top: 0,
    background: '#1d2124',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Georgia',

    '& > img': {
      width: '60vw',
      marginBottom: '20vh',
    },

    '& .message' : {

      '& img' : {
        width: '10vw',
        margin: '3vw',
      },

      '& h3' : {
        fontFamily: 'Georgia',
        color: '#756756',
        marginTop: 0,
      },

      '& p' : {
        maxWidth: '60vw',
        fontStyle: 'italic',
        fontSize: '16px',
      },

      /* '& button' : {
        color: '#ffffff',
        background: '#90806d',
        border: 'none',
        borderRadius: '10px',
        padding: '12px 56px',
        fontFamily: 'Georgia',
        fontStyle: 'italic',
        boxShadow: '0px 2px 5px #8f816c',
      } */
    }
  }
}));

export default function IsMobile() {
  const classes = useStyles();
  const maxwidth = useMediaQuery('(max-width: 960px)');
  const minheight = useMediaQuery('(min-height: 480px)');

  return (
    <>
      {(maxwidth === true && minheight === true) ? (
        <div className={classes.wrapper}>
          <img src={artarklogo}/>

          <div className='message'>
            <img src={landscape}/>
            <h3>Best viewed in landscape mode</h3>

            {/* <button>Visit Website</button> */}
          </div>
        </div>
      ) : null }
    </>
  );
};
