import { Observable } from 'rxjs'
import { Epic } from 'redux-observable'
import { AnyAction } from 'typescript-fsa'
import { ofAction } from 'typescript-fsa-redux-observable'
import actions from 'actions/emailvalidation'
import { ajax } from 'rxjs/ajax'
import { catchError, map, mergeMap } from 'rxjs/operators'

export const createEmailValidation: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.createemailvalidation.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/emailvalidate`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.emailvalidation
        }).pipe(
            map(data => {
                return actions.createemailvalidation.done({
                    params: param.payload,
                    result: { emailvalidation: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.createemailvalidation.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getEmailValidationByToken: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.getemailvalidation.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/emailvalidate/` + param.payload.id,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.getemailvalidation.done({
                    params: param.payload,
                    result: { emailvalidation: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.getemailvalidation.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const deleteEmailValidation: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.deleteemailvalidation.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/emailvalidate/` + param.payload.id,
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.deleteemailvalidation.done({
                    params: param.payload,
                    result: { emailvalidation: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.deleteemailvalidation.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)