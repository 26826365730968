import { from, merge, Observable, of } from 'rxjs'
import { ofType, Epic } from 'redux-observable'
import { AnyAction } from 'typescript-fsa'
import { ofAction } from 'typescript-fsa-redux-observable'
import actions from 'actions/corporateinfo'
import modal from 'actions/modal'
import user from 'actions/user'
import auth from 'actions/auth'
import { ajax } from 'rxjs/ajax'
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators'
import traineeinfo from 'actions/traineeinfo'
import traineecourse from 'actions/traineecourse'

export const createCorporateInfo: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.createcorporateinfo.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/corporateinfo`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.corporateinfo
        }).pipe(
            map(data => {
                return actions.createcorporateinfo.done({
                    params: param.payload,
                    result: { corporateinfo: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.createcorporateinfo.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getCorporateInfoByUserId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.getcorporateinfobyuserid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/corporateinfo/` + param.payload.userid,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.getcorporateinfobyuserid.done({
                    params: param.payload,
                    result: { corporateinfo: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.getcorporateinfobyuserid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const updateCorporateInfoByUserId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.updatecorporateinfobyid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/corporateinfo/` + param.payload.userid,
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.corporateinfo
        }).pipe(
            map(data => {
                return actions.updatecorporateinfobyid.done({
                    params: param.payload,
                    result: { corporateinfo: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.updatecorporateinfobyid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const deductCorporateInfoSession: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.deductsession.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/corporateinfo/deductsession/` + param.payload.userid + '/' + param.payload.numberofsession,
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            mergeMap(data => {
                return param.payload.callbackparams.isediting == false ? merge(
                    of(auth.updatesession.started({numberofsession: param.payload.callbackparams.corporatecurrentsession - param.payload.numberofsession})),
                    of(auth.register.started({
                        user: {
                            "name": param.payload.callbackparams.name,
                            "email": param.payload.callbackparams.email,
                            "accounttype": "TRAINEE",
                            "password": param.payload.callbackparams.password,
                            "status": param.payload.callbackparams.status,
                            "belongstoagent": "",
                            "belongstouser": param.payload.callbackparams.belongstouser
                        },
                        userinfo: {
                            "dateofbirth": param.payload.callbackparams.dateofbirth,
                            "startdate": param.payload.callbackparams.startdate,
                            "enddate": param.payload.callbackparams.enddate,
                            "validsessions": param.payload.numberofsession,
                            "groupname": param.payload.callbackparams.groupname,
                            "user": param.payload.callbackparams.belongstouser,
                            "assignedcoursename": param.payload.callbackparams.assignedcoursename,
                            "assignedcourseid": param.payload.callbackparams.assignedcourseid,
                        }
                    })),
                    of(actions.deductsession.done({params: param.payload, result: { corporateinfo: data.response }})),
                ) : (param.payload.callbackparams.password == "TRAINEE PASSWORD*" || param.payload.callbackparams.password == "") ? merge(
                    of(auth.updatesession.started({numberofsession: param.payload.callbackparams.corporatecurrentsession - param.payload.numberofsession})),
                    of(user.updateuserbyid.started({userid: param.payload.callbackparams.traineeid,
                        user: {
                            "name": param.payload.callbackparams.name,
                            "accounttype": "TRAINEE",
                            "status": param.payload.callbackparams.status,
                            "belongstoagent": "",
                            "belongstouser": param.payload.callbackparams.belongstouser
                        }
                    })),
                    of(traineeinfo.updatetraineeinfobyuserid.started(   {userid: param.payload.callbackparams.traineeinfoid,
                        traineeinfo: {
                            "dateofbirth": param.payload.callbackparams.dateofbirth,
                            "startdate": param.payload.callbackparams.startdate,
                            "enddate": param.payload.callbackparams.enddate,
                            "validsessions": param.payload.callbackparams.additionalsessions,
                            "groupname": param.payload.callbackparams.groupname,
                            "user": param.payload.callbackparams.traineeid
                    }})),
                    of(traineecourse.updatetraineecoursebyuserid.started({userid: param.payload.callbackparams.traineeid, traineecourse: {"name": param.payload.callbackparams.assignedcoursename, "course": param.payload.callbackparams.assignedcourseid}})),
                    of(actions.deductsession.done({params: param.payload, result: { corporateinfo: data.response }})),
                    of(modal.show.started({type: 'saveTraineeSetup', source: ''})),
                ) : merge(
                    of(auth.updatesession.started({numberofsession: param.payload.callbackparams.corporatecurrentsession - param.payload.numberofsession})),
                    of(user.updateuserbyid.started({userid: param.payload.callbackparams.traineeid,
                        user: {
                            "name": param.payload.callbackparams.name,
                            "accounttype": "TRAINEE",
                            "password": param.payload.callbackparams.password,
                            "status": param.payload.callbackparams.status,
                            "belongstoagent": "",
                            "belongstouser": param.payload.callbackparams.belongstouser
                        }
                    })),
                    of(traineeinfo.updatetraineeinfobyuserid.started({userid: param.payload.callbackparams.traineeinfoid,
                        traineeinfo: {
                            "dateofbirth": param.payload.callbackparams.dateofbirth,
                            "startdate": param.payload.callbackparams.startdate,
                            "enddate": param.payload.callbackparams.enddate,
                            "validsessions": param.payload.callbackparams.additionalsessions,
                            "groupname": param.payload.callbackparams.groupname,
                            "user": param.payload.callbackparams.traineeid,
                            "assignedcoursename": param.payload.callbackparams.assignedcoursename,
                            "assignedcourseid": param.payload.callbackparams.assignedcourseid,
                    }})),
                    of(traineecourse.updatetraineecoursebyuserid.started({userid: param.payload.callbackparams.traineeid, traineecourse: {name: param.payload.callbackparams.assignedcoursename, course: param.payload.callbackparams.assignedcourseid}})),
                    of(actions.deductsession.done({params: param.payload, result: { corporateinfo: data.response }})),
                    of(modal.show.started({type: 'saveTraineeSetup', source: ''})),
                )
            }),
            catchError(error => {
                if(error.response.message == 'error.number_exceeded'){
                    return of(modal.show.started({type: 'exceedSession', source: ''}), actions.deductsession.failed({
                        params: param.payload,
                        error: error.response.message,
                    }))
                } else {
                    return of(actions.deductsession.failed({
                        params: param.payload,
                        error: error.response.message,
                    }))
                }
            }),
        ),
    ),
)