import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'


import { makeStyles } from '@material-ui/core/styles';

import HomeBtn from '../../../assets/CorporateProfileSetup/HomeBtn.png'
import Edit from '../../../assets/CourseManagerManagement/EditCourseManagerYellow.png'
import Create from '../../../assets/CourseManagerManagement/CreatenewCourseManagerYellow.png'
import DeleteCourse from '../../../assets/CourseManagerManagement/DeleteCourseManagerYellow.png'

import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'
// import bg from 'assets/CoporateUserOverView/CoporateUserOverviewBG.png'
import bg from 'assets/bg.png'
import textbg from 'assets/textfield.svg'
import logo from 'assets/logo.png'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import SaveSymbol from 'assets/BaseImg/saveSymbol.png'
import Calendar from 'assets/BaseImg/calendar.svg'
import tablebg from 'assets/CourseTypeManagement/TableBG.svg'
import yellowBgshort from 'assets/BaseImg/yellowBGshort.png'
import darkBG from 'assets/BaseImg/darkBgShort.png'

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import modal from 'actions/modal'
import corporateinfo from 'actions/corporateinfo'
import course from 'actions/course'

import 'assets/stylesheets/application.css'
import { InfoTable8Col } from 'components/Tables/InfoTable8Col'
// import AccountInfo from 'components/AccountInfo';
import AccountInfoNew from 'components/AccountInfoNew'

// for swiper
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import 'assets/stylesheets/pagination.min.css'
// import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

// import Swiper core and required modules
import SwiperCore, {
  Pagination, Navigation
} from 'swiper/core';

// experiment test swiper
import arrowLeft from 'assets/swiperItems/arrowYellowLeft.png'
import arrowRight from 'assets/swiperItems/arrowYellowRight.png'

// experiement toggle button
import enableButton from 'assets/ToggleButton/enableButton.png'
import disableButton from 'assets/ToggleButton/disableButton.png'
import traineeCoursesAction from 'actions/traineecourse';
import axios from 'axios';
import information from 'assets/information.png'

const useStyles = makeStyles(() => ({
  container2: {
    top: "6rem",
    width: "100vw",
    height: "89vh",
    display: "flex",
    position: "absolute",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  header: {
    color: '#fffffe',
    fontFamily: 'impact',
    fontSize: '2.5rem'
  },
  // page top
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '12rem'
  },
  buttonContainer: {
    width: "103rem",
    height: "11rem",
    cursor: "pointer",
    paddingTop: "2rem",
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '27vw',
      height: '13vh'
    }
  },
  inputWrapperContainer: {
    display: 'flex',
    width: "100vw",
    justifyContent: "center",
  },
  inputWrapperContainerHidden: {
    display: 'flex',
    width: "100vw",
    justifyContent: "center",
    position: "fixed",
    bottom: "74vw",
  },
  inputWrapperDate: {
    position: 'relative',
    width: "24vw",
    height: "13vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: '2rem',
    marginLeft: '2rem',

    '& p': {
      color: '#FFD60F',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2rem',
      width: '20rem',
      height: "auto"
    },
    '& img': {
      width: '25vw'
    }
  },
  inputWrapper: {
    position: 'relative',
    width: "24vw",
    height: "13vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'text',
    marginRight: '2rem',
    marginLeft: '2rem',

    '&[type="date"]': {
      marginLeft: '4rem',
      width: '19rem',
    },

    '&[type="date"]::-webkit-calendar-picker-indicator': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundImage: `url(${Calendar})`,
    },

    '& p': {
      position: "fixed",
      background: "none",
      border: "none",
      color: "#FFD60F",
      cursor: 'default',
      fontFamily: "Impact",
      letterSpacing: '1px',
      fontSize: '1.2rem',
      textAlign: 'center',
      lineHeight: '60px',
    },
    '& img': {
      width: '25vw'
    }
  },
  inputWrapperHidden: {
    opacity: 0,
    position: 'relative',
    width: "24vw",
    height: "13vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: '2rem',
    marginLeft: '2rem',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.5vw',
      width: '20rem'
    },
    '& img': {
      width: '25vw'
    }
  },
  inputWrapperDropdown: {
    position: 'relative',
    width: "24vw",
    height: "13vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: '2rem',
    marginLeft: '2rem',
    '& select': {
      position: "fixed",
      background: "none",
      border: "none",
      color: "#FFD60F",
      fontFamily: "Impact",
      letterSpacing: '1px',
      fontSize: '1.2rem',
      textAlign: 'center',
    },
    '& img': {
      width: '25vw'
    }
  },
  inputDate: {
    background: 'transparent',
    position: 'absolute',
    border: 'none',
    color: '#ffd84d',
    fontFamily: "Impact",
    letterSpacing: "1px",
    fontSize: '1.2rem',
    width: '20rem',
    textAlign: 'center',

    '&[type="date"]': {
      marginLeft: '4rem',
      width: '19rem',
      textAlign: 'center',
    },

    '&[type="date"]::-webkit-calendar-picker-indicator': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundImage: `url(${Calendar})`,
    },

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputDateClicked: {
    background: 'transparent',
    position: 'absolute',
    border: 'none',
    color: '#ffd84d',
    fontFamily: "Impact",
    letterSpacing: "1px",
    fontSize: '1.2rem',
    width: '20rem',
    textAlign: 'center',
    marginLeft: '3rem',
    height: '60px',

    '&[type="date"]::-webkit-calendar-picker-indicator': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundImage: `url(${Calendar})`,
    },

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  input: {
    background: 'transparent',
    position: 'absolute',
    border: 'none',
    textAlign: 'center',
    height: '58px',
    color: '#ffd84d',
    fontFamily: "Impact",
    letterSpacing: "1px",
    fontSize: '1.2rem',
    width: "23rem",

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&[type="date"]::-webkit-calendar-picker-indicator': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundImage: `url(${Calendar})`,
      textAlign: 'center',
    },

    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputPass: {
    top: 0,
    left: 0,
    color: '#ffd84d',
    width: '100%',
    border: 'none',
    padding: '0px 48px',
    position: 'absolute',
    background: 'transparent',
    textAlign: 'center',
    fontSize: '1.2rem',
    height: '100%',
    fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",

    '&::placeholder': {
      color: '#ffd84d83',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  togglebutton: {
    top: '0px',
    right: '25px',
    position: 'absolute',
    height: '100%',
    maxHeight: 'none',
  },
  InputAPass: {
    color: 'white',
  },
  row2: {
    display: 'flex',
    marginTop: '3rem'
  },
  // swiper
  swiperWrapper: {
    width: "100vw",
    height: "84vh",
  },
  //back and save buttons style
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '2vw',
      height: 'auto',
      cursor: 'pointer'
    }
  },
  saveBtn: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3rem',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '2vw',
      height: 'auto',
    }
  },
  pageBottom: {
    display: 'flex',
    position: 'fixed',
    bottom: "1rem",
    right: "2rem",
  },
  containerOverViewData: {
    color: "white",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    "& h4": {
      fontFamily: 'Impact',
      paddingRight: '30px',
      margin: '0',
      display: 'inline',
      marginBottom: '-4px',
    },
    '& h5':{
        fontFamily: "Impact",
        letterSpacing: "1px",
        marginBottom: '0rem'
      },
      '& row': {
        fontFamily: "Impact",
      }
  },
  information: {
    left: '5vw',
    width: '40px',
    bottom: '10vh',
    height: '40px',
    position: 'absolute',
    cursor: 'pointer',

    '& img': {
      width: '100%',
    }
  }
}));

const TraineeUserAccountEdit = () => {
  const dispatch = useDispatch()
  const classes = useStyles();

  const id = useSelector((state: any) => state.auth.loggedinuser.user.id)
  const valsess = useSelector((state: any) => state.auth.loggedinuser.user.corporateinfo.validsessions)
  const corporateenddate = useSelector((state: any) => state.auth.loggedinuser.user.corporateinfo.enddate)
  const numberofsession = useSelector((state: any) => state.auth.numberofsession)
  const error = useSelector((state: any) => state.auth.error)
  const courses = useSelector((state: any) => state.course.data)
  const traineecourseid = useSelector((state: any) => state.traineecourse.selectedcourseid)
  const [toggleOption, settoggleOption] = useState('enable')
  const [startofPage, setstartofPage] = useState(true)
  const [startofCourseType, setStartofCourseType] = useState(true)
  const editData = useSelector((state: any) => state.router.location.state)

  // retrieving inputs
  const [TraineeName, setTraineeName] = useState(editData.name)
  const [TraineeDateOfBirth, setTraineeDateOfBirth] = useState(editData.traineeinfo.dateofbirth)
  const [TraineeEmail, setTraineeEmail] = useState(editData.email)
  const [GroupName, setGroupName] = useState(editData.traineeinfo.groupname)
  const [TraineePassword, setTraineePassword] = useState('TRAINEE PASSWORD*')
  const [StartDate, setStartDate] = useState(editData.traineeinfo.startdate)
  const [EndDate, setEndDate] = useState(editData.traineeinfo.enddate)
  const [AssignedCourseName, setAssignedCourseName] = useState('')
  const [AssignedCourse, setAssignedCourse] = useState('SELECT ASSIGNED COURSE*')
  const [AssignedCourseId, setAssignedCourseId] = useState('')
  const [NumOfSessionsAssigned, setNumOfSessionsAssigned] = useState(editData.traineeinfo.validsessions)
  const [EnableDisable, setEnableDisable] = useState(true)

  function retrieveInfoFromInput1(inputType: any, input: any) {
    inputType(input)
  }

  // save button activation
  const saveButtonClicked = () => {
    if(AssignedCourse == 'SELECT ASSIGNED COURSE*'){
      dispatch(modal.show.started({type: 'courseRequired', source: ''}))
    } else {
      dispatch(corporateinfo.deductsession.started({userid: id, numberofsession: NumOfSessionsAssigned - editData.traineeinfo.validsessions, callbackparams: {
        "name": TraineeName,
        "accounttype": "TRAINEE",
        "password": TraineePassword,
        "status": EnableDisable,
        "belongstoagent": "",
        "belongstouser": id,
        "dateofbirth": TraineeDateOfBirth,
        "startdate": StartDate,
        "enddate": EndDate,
        "previoussessions": editData.traineeinfo.validsessions,
        "additionalsessions": NumOfSessionsAssigned,
        "groupname": GroupName,
        "traineeid": editData.id,
        "traineeinfoid": editData.traineeinfo.id,
        "isediting": true,
        "corporatecurrentsession": numberofsession,
        "assignedcoursename": AssignedCourseName,
        "assignedcourseid": AssignedCourseId,
      }}))
    }
  }

  useEffect(() => {
    if(error == true ) {
      dispatch(modal.show.started({type: 'formHasErrors', source: ''}))
    }

    if (startofPage === true) {
      dispatch(course.getcoursesbyuserid.started({userid: id}))
      setstartofPage(false)
    } else {
      temp()
    }
    dispatch(traineeCoursesAction.gettraineecoursebyuserid.started({userid: editData.id}));
  }, [StartDate, valsess]);

  useEffect(() => {
    if(startofCourseType === true){
      if(courses != null && traineecourseid != ''){
        courses?.data.map((data: any) => {
          if(data.id == traineecourseid){
            setAssignedCourseName(data.name)
            setAssignedCourse(data?.coursetype?.name)
            setStartofCourseType(false);
          }
        });
      }
    }
  }, [traineecourseid, courses]);

  const [isSwiperClicked, setisSwiperClicked] = useState(false)

  function arrowButtonClicked(whichDirection: any) {
    if (whichDirection === 'right') {
      setisSwiperClicked(true)
    } else {
      setisSwiperClicked(false)
    }
  }

  const arrowButtonSeen = (determiningFactor: any) => {
    if (determiningFactor === true) {
      return (
        <div className="arrowBox">
          <div onClick={() => arrowButtonClicked('left')}><img src={arrowLeft} alt="arrowLeft" /> PREVIOUS PAGE</div>
          <img src={arrowRight} alt="arrowRight" style={{opacity: 0}} />
        </div>
      )
    } else {
      return(
        <div className="arrowBox">
          <div><img src={arrowLeft} alt="arrowLeft" style={{ opacity: 0 }} /></div>
          <div onClick={() => arrowButtonClicked('right')}>NEXT PAGE<img src={arrowRight} alt="arrowRight"  /></div>
        </div>
      )
    }
  }

  function retrieveAndCallAPIInput(input: any) {
    courses.data.map((data: any) => {
      if(data.id == input){
        setAssignedCourseName(data.name)
        setAssignedCourse(data?.coursetype?.name)
        setAssignedCourseId(data.id)
        dispatch(traineeCoursesAction.setselectedtraineecourse.started({courseid: data.id}))
      }
    });
  }

  const disableButtonClicked = () => {
    settoggleOption('enable')
    setEnableDisable(true)
  }
  const enableButtonClicked = () => {
    settoggleOption('disable')
    setEnableDisable(false)
  }

  const [values, setValues] = React.useState({
    password: TraineePassword,
    showPassword: false,
  });

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const customToggleButton = (isItEnableOrDisable: any) => {
    if (isItEnableOrDisable === 'disable') {
      // setEnableDisable('disable')
      return (
        <img src={disableButton} alt="disableButton" onClick={() => disableButtonClicked()}/>
      )
    } else {
      return (
        <img src={enableButton} alt="enableButton" onClick={() => enableButtonClicked()}/>
      )
    }
  }

  function temp() {
    let x = 1
  }

  // for date
  const [isDateOfBirthClicked, setisDateOfBirthClicked] = useState(false)
  const dateOfBirthClicked = () => {
    setisDateOfBirthClicked(true)
    let dateInput = document.getElementById("MANAGER")
    if (dateInput !== null) {
      dateInput.click()
      dateInput.click()
    } else {
      temp()
    }
  }

  // get current date
  const mydate = require('current-date')
  const [hasDateInputBeenClick, sethasDateInputBeenClick] = useState(false)
  const [hasDateInputBeenClick1, sethasDateInputBeenClick1] = useState(false)

  const swiperTestPaginationIcon = (LeftorRight: any) => {
    if (LeftorRight === true) {
      return (
        <div className="swiperTestPagination">
          <p>2 / 2</p>
        </div>
      )
    }
    else {
      return (
        <div className="swiperTestPagination">
          <p>1 / 2</p>
        </div>
      )
    }
  }

  return (
    <BaseLayoutAdjustable bg={bg}>
      {/* <div className={classes.topInfo}>
        <img src={logo} alt="logo" className={classes.logo} />
        <AccountInfoNew />
      </div> */}
      <div className={classes.topInfo}>
        <img src={logo} alt="logo" className={classes.logo} />
        <div className={classes.containerOverViewData}>
          <h4>Remaining Sessions: {numberofsession}</h4>
          <AccountInfoNew />
        </div>
      </div>
      <div className="SwiperTestMainContainer">
        <h3>EDIT TRAINEE ACCOUNT</h3>
        <h5>*INDICATES REQUIRED FIELD</h5>
        <div className="swiperTestContainer">
          {arrowButtonSeen(isSwiperClicked)}
          <div className={isSwiperClicked === false ? classes.inputWrapperContainer : classes.inputWrapperContainerHidden}>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" value={TraineeName} className={classes.input} placeholder="TRAINEE NAME*" type="text" onChange={(e) => retrieveInfoFromInput1(setTraineeName, e.target.value)} />
            </div>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" value={TraineeDateOfBirth?.replace(/T.*/,'').split('-').join('-')} className={isDateOfBirthClicked === true ? classes.inputDateClicked : classes.inputDate} placeholder="TRAINEE DATE OF BIRTH*" type={isDateOfBirthClicked === true ? "date" : "text"} onClick={() => dateOfBirthClicked()} onChange={(e) => retrieveInfoFromInput1(setTraineeDateOfBirth, new Date(e.target.value).toISOString())} />
            </div>
          </div>
          <div className={isSwiperClicked === false ? classes.inputWrapperContainer : classes.inputWrapperContainerHidden}>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              {/* <input autoComplete="off" value={TraineeEmail} className={classes.input} placeholder="TRAINEE EMAIL*" type="text" onChange={(e) => retrieveInfoFromInput1(setTraineeEmail, e.target.value)} /> */}
              <p>{TraineeEmail}</p>
            </div>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" value={GroupName} className={classes.input} placeholder="GROUP NAME*" type="text" onChange={(e) => retrieveInfoFromInput1(setGroupName, e.target.value)} />
            </div>
          </div>
          <div className={isSwiperClicked === false ? classes.inputWrapperContainer : classes.inputWrapperContainerHidden}>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              {/* <input autoComplete="off" value={TraineePassword} className={classes.input} placeholder="TRAINEE PASSWORD*" type="password" onChange={(e) => retrieveInfoFromInput1(setTraineePassword, e.target.value)} /> */}
              <input
                placeholder="TRAINEE PASSWORD*"
                type={values.showPassword ? 'text' : 'password'}
                value={TraineePassword}
                autoComplete="new-password"
                className={classes.inputPass}
                onChange={(e) => retrieveInfoFromInput1(setTraineePassword, e.target.value)}
              />
              <InputAdornment position="end" className={classes.togglebutton}>
                    <IconButton className={classes.InputAPass}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            </div>
            <div className={classes.inputWrapperHidden}>
              <img src={textbg} alt="bg" />
            </div>
          </div>
          <div className={isSwiperClicked === false ? classes.inputWrapperContainerHidden : classes.inputWrapperContainer}>
            <div className={classes.inputWrapperDropdown}>
              <img src={textbg} alt="bg" />
              <select value={traineecourseid} onChange={(e) => retrieveAndCallAPIInput(e.target.value)}>
                <option value={'SELECT ASSIGNED COURSE*'}>SELECT ASSIGNED COURSE*</option>
                {courses != null ?
                    courses.data.map((data: any) => {
                      return (<option key={data.id} value={data.id}>{data.name}</option>)
                    })
                : null}
              </select>
            </div>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <p className={classes.input}>{AssignedCourse != 'SELECT ASSIGNED COURSE*' ? AssignedCourse :  'COURSE TYPE'}</p>
              {/* <input className={classes.input} autoComplete="off" placeholder="GROUP NAME*" type="text" id="COMPANYNAME" name="GROUPNAME" onChange={(e) => retrieveInfoFromInput1(setGroupName, e.target.value)} /> */}
            </div>
          </div>
          <div className={isSwiperClicked === false ? classes.inputWrapperContainerHidden : classes.inputWrapperContainer}>
            <div className={classes.inputWrapperDropdown}>
              <img src={textbg} alt="bg" />
              {/* <select value={'NIL'} onChange={(e) => retrieveInfoFromInput1(setNumOfSessionsAssigned, e.target.value)}>
                <option value="NIL">ASSIGN NO. OF SESSIONS*</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select> */}
              <input autoComplete="off" value={NumOfSessionsAssigned} className={classes.input} min={NumOfSessionsAssigned} max={1000} placeholder="SET NUMBER OF SESSIONS*" type="NUMBER" onChange={(e) => retrieveInfoFromInput1(setNumOfSessionsAssigned, e.target.value)} />
            </div>
            <div className={classes.inputWrapperDate}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" value={new Date(StartDate).toISOString()?.replace(/T.*/,'').split('-').join('-')} className={hasDateInputBeenClick===true? classes.inputDateClicked:classes.inputDate} placeholder="SELECT START DATE OF ACCOUNT*" type={hasDateInputBeenClick === true ? "date" : "text"} min={mydate('date')} max={new Date(corporateenddate).toISOString()?.replace(/T.*/,'').split('-').join('-')} onClick={() => sethasDateInputBeenClick(true)} onChange={(e) => retrieveInfoFromInput1(setStartDate, new Date(e.target.value).toISOString())} />
            </div>
          </div>
          <div className={isSwiperClicked === false ? classes.inputWrapperContainerHidden : classes.inputWrapperContainer}>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              {/* <div className={classes.dateStorage}> */}
              <input autoComplete="off" value={new Date(EndDate).toISOString()?.replace(/T.*/,'').split('-').join('-')} className={hasDateInputBeenClick1 === true ? classes.inputDateClicked : classes.inputDate} placeholder="SELECT END DATE OF ACCOUNT*" type={hasDateInputBeenClick1 === true ? "date" : "text"} min={mydate('date')} max={new Date(corporateenddate).toISOString()?.replace(/T.*/,'').split('-').join('-')} onClick={() => sethasDateInputBeenClick1(true)} onChange={(e) => retrieveInfoFromInput1(setEndDate, new Date(e.target.value).toISOString())} />
                {/* <img src={datesymbol} alt="datesymbol" /> */}
              {/* </div> */}
            </div>
            <div className={classes.inputWrapper}>
              {customToggleButton(toggleOption)}
            </div>
            {/* <div className={classes.inputWrapperHidden}>
              <img src={textbg} alt="bg" />
            </div> */}
          </div>
        </div>
      </div>
      {swiperTestPaginationIcon(isSwiperClicked)}
      <div className={classes.pageBottom}>
        <div className={classes.backBtn} onClick={() => dispatch(push('/corporate/traineelisting'))}>
          <img src={BackSymbol} alt="BackSymbol" />
          <p>BACK</p>
        </div>
        <div className={classes.saveBtn} onClick={() => saveButtonClicked()}>
          <img src={SaveSymbol} alt="SaveSymbol" />
          <p>SAVE</p>
        </div>
      </div>
      <div className={classes.information} onClick={() => window.open('https://www.createscape.com.sg/documents/corporate-account-user-manual.pdf')} >
        <img src={information}/>
      </div>
    </BaseLayoutAdjustable>
  )
}

export default TraineeUserAccountEdit;
