import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import axios from 'axios';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import cancel from '../assets/cancel.png'
import artist01 from '../assets/artists/masturah-profile.png'
import artist02 from '../assets/artists/jeffrey-profile.png'
import artist03 from '../assets/artists/idris-profile.png'
import artist04 from '../assets/artists/valerie-profile.png'
import featuredart01 from '../assets/artists/masturah-featuredart.png'
import featuredart02 from '../assets/artists/jeffrey-featuredart.png'
import featuredart03 from '../assets/artists/idris-featuredart.png'
import featuredart04 from '../assets/artists/valerie-featuredart.png'
import fullvideo from '../assets/videos/full.mp4'
import videoposter from '../assets/videos/videoposter.jpg'
import instruction from '../assets/desktop-instruction.jpg'

import ResetButton from 'assets/ForgetPassModal/ResetYellow.png'
import BackBtn from 'assets/ForgetPassModal/BackBtn.png'
import ForgetPassModalWords from 'assets/ForgetPassModal/ForgetPassModalWords.jpg'
import save from '../assets/save.png'
import nosave from '../assets/nosave.png'
import yes from 'assets/CourseManagerManagement/YesYellow.png'
import no from 'assets/CourseManagerManagement/No.png'
import OkayBtn from 'assets/OkayBtn.png'
import CloseBtn from 'assets/closebutton.svg'
import yellowBg from 'assets/BaseImg/yellowBgwithDesign.png'
import darkBg from 'assets/BaseImg/darkBgwithDesign.png'
import textfieldbg from 'assets/textfield.svg'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import play from 'assets/play.png'
import pause from 'assets/pause.png'
import { isMobile } from 'react-device-detect';

// for game ressults
import performancerecordsBG from 'assets/PerformanceRecords/performanceRecordsBG.png'
import hourGlass from 'assets/PerformanceRecords/hourGlass.png'
import lightBulbDark from 'assets/PerformanceRecords/lightBulbDark.png'
import lightBulbLigted from 'assets/PerformanceRecords/lightBulbLigted.png'
import GameResultsLightBulb from 'components/GameResultsLightBulb'

import modal from 'actions/modal'
import user from 'actions/user'
import auth from 'actions/auth'
import course from 'actions/course'
import coursetype from 'actions/coursetype'
import TextField1 from 'components/Inputs/TextField1'
import 'assets/stylesheets/application.css'
import gameresult from 'actions/gameresult';
import { relative } from 'path';
import { ConfigBase } from 'aws-sdk/lib/config-base';
import TrainingCourseManagement from 'routes/corporate/TrainingCourseSetup';
import IconSwiper from './platform/IconSwiper';

const useStyles = makeStyles((theme: Theme) => createStyles({
  modal: {
    width: '100%',
    height: 'auto',
  },
  instructionpaper: {
    top: '50%',
    width: '100%',
    outline: 'none',
    maxWidth: '1170px',
    position: 'absolute',
    transform: 'translate(0%, -50%)',
    fontFamily: 'Georgia',
    textAlign: 'center',
    left: 0,
    right: 0,
    margin: '0 auto',
    borderRadius: '14px',
    background: '#ffffff',

    '@media (max-width: 960px)' : {
      height: '100vh',
      weight: '100vw',
    },

    '& .instruction': {
      padding: '5vw',

      '@media (max-width: 960px)' : {
        '& + div': {
          top: '10.4vh',
          right: '.8vw',
          width: '23px',
          zIndex: '2',
        },

        padding: '0',
      },

      '& img': {
        width: '100%',
      }
    },
  },

  instructionpapermobile: {
    top: '50%',
    width: '100%',
    outline: 'none',
    position: 'absolute',
    maxWidth: '100%',
    transform: 'translate(0%, -50%)',
    fontFamily: 'Georgia',
    textAlign: 'center',
    left: 0,
    right: 0,
    margin: '0 auto',
    borderRadius: '14px',
    background: '#ffffff',

    '@media (max-width: 960px)' : {
      height: '100vh',
      weight: '100vw',
    },

    '& .instruction': {
      padding: '0vw',

      '@media (max-width: 960px)' : {
        '& + div': {
          top: '10.4vh',
          right: '.8vw',
          width: '23px',
          zIndex: '2',
        },

        padding: '0',
      },

      '& img': {
        width: '100%',
      }
    },
  },

  paper: {
    top: '50%',
    width: '100%',
    maxWidth: '680px',
    outline: 'none',
    position: 'absolute',
    transform: 'translate(0%, -50%)',
    fontFamily: 'Georgia',
    textAlign: 'center',
    left: 0,
    right: 0,
    margin: '0 auto',

    '@media (max-width: 960px)' : {
      height: '82vh',
      maxWidth: '630px',
    },

    '& h1': {
      color: '#fff',
      marginBottom: '5%',
      fontSize: '6vh'
    },
    '& img': {
      cursor: 'pointer'
    }
  },

  papertwo: {
    top: '50%',
    outline: 'none',
    textAlign: 'center',
    width: 'auto',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Georgia',
  },

  backCancel: {
    width: '100%',
    outline: 'none',
    textAlign: 'center',
    position: 'absolute',
    top: '50%',
    transform: 'translate(0%,-50%)',
    '& img': {
      width: '16vw',
      cursor: 'pointer'
    }
  },
  warn: {
    color: '#FCDA55',
    fontFamily: 'impact',
    letterSpacing: '1px',
    paddingBottom: '1rem',
    fontSize: '8vh'
  },
  warningWords: {
    color: '#FFFF',
    fontFamily: 'impact',
    letterSpacing: '1px',
    fontSize: '6vh'
  },
  warningWords1: {
    color: '#FFFF',
    fontFamily: 'impact',
    letterSpacing: '1px',
    paddingBottom: '4rem',
    fontSize: '6vh'
  },
  forget: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    textAlign: "center",
    justifyContent: "center",
    '& h3': {
      color: '#FFD60F',
      marginBottom: '5%'
    },
    '& h4': {
      color: '#FFFFFE',
      marginBottom: '5%'
    },
    '& img': {
      width: '56vw',
    },
    alignItems: 'center'
  },
  cancelBackBtn: {
    display: 'flex',
    justifyContent: 'center',
    height: '10vh'
  },
  Button: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    position: 'relative',
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: '4rem',
    marginTop: "4rem",
    marginBottom: "4rem",
    '& img': {
      width: '100%',
    },
    '& p': {
      color: '#FFFFFF',
      position: 'absolute',
      fontFamily: 'impact',
      letterSpacing: '1px',
      fontSize: '3rem',
      marginBottom: '0rem'
    }
  },
  BackButton: {
    cursor: 'pointer',
    position: 'absolute',
    right: '0rem',
    bottom: '0rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: '2rem',
    marginBottom: '2rem',
    '& img': {
      width: '100%',
    },
    '& p': {
      color: '#FFD84C',
      fontFamily: 'impact',
      letterSpacing: '1px',
      fontSize: '1.2rem',
      paddingLeft: '0.5rem'
    }
  },
  forgetPass: {
    color: '#FFD84E',
    fontFamily: 'impact',
    letterSpacing: '1px',
    fontSize: '3rem',
  },
  enterEmailAddress: {
    color: '#FFFFFF',
    fontFamily: 'impact',
    letterSpacing: '1px',
    fontSize: '3rem',
    width: '80rem'
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  // for input box
  input: {
    // backgroundImage:`url(${bg})`,
    background: 'transparent',
    position: 'absolute',
    width: '58%',
    border: 'none',
    textAlign: 'center',
    height: '45px',
    fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    color: '#ffd84d',
    fontSize: '3rem',

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputWrapper: {
    marginTop: '3rem',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  GameResults: {
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    '& h1': {
      color: '#fff',
      fontFamily: 'impact',
      letterSpacing: '1px',
      marginTop: '7rem',
      marginBottom: '3rem',
      fontSize: '3rem'
    }
  },
  gameResultBG: {
    width: '68rem',
    zIndex: -1
  },
  forAlignment: {
    display: "flex",
    justifyContent: 'center',
    alignItems: "center",
  },
  recordsMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    width: "56rem",
    height: "26rem",
    justifyContent: "space-between",
  },
  hintsUsed: {
    display: "flex",
    alignItems: 'center',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      marginTop: '2rem',
      marginRight: '1rem'
    },
    '& img': {
      width: '3rem',
    }
  },
  quizScoreTimeTakenBox: {
    display: 'flex',
    alignItems: "flex-end",
    paddingBottom: '11rem',
    justifyContent: 'center',
  },
  takenTime: {
    display: 'flex',
    alignItems: 'center',
    height: '1.6rem',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      fontSize: '1.2rem'
    }
  },
  portion1Box: {
    display: 'flex',
    width: '28rem',
    justifyContent: 'center'
  },
  portion1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: "16rem",
    marginTop: '7rem'
  },
  scoreQuiz: {
    color: '#FFFFFF',
    fontFamily: 'impact',
    letterSpacing: '1px',
    fontSize: '1.2rem',
    margin: '0rem'
  },
  questionsAnswered: {
    display: 'flex',
    width: '23rem',
    alignItems: 'center',
    justifyContent: 'center'
  },
  outOf10: {
    color: "#FFFFFF",
    // width: "10rem",
    fontFamily: "ds-digitalbold",
    margin: "0rem",
    letterSpacing: "1px",
    fontSize: "50px",
    paddingRight: "1rem",
  },
  QAC: {
    color: '#FFFFFF',
    fontFamily: 'ds-digitalbold',
    letterSpacing: '1px',
    marginRight: '1rem',
    width: '1rem',
    fontSize: '11px'
  },
  portion2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
  },
  timeValue: {
    color: '#FFFFFF',
    fontFamily: 'ds-digitalbold',
    letterSpacing: '1px',
    fontSize: '50px'
  },

  // back button
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'fixed',
    bottom: '1rem',
    right: '2rem',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw',
      height: '100%',
    }
  },
  closebutton: {
    position: 'absolute',
    top: '0px',
    right: '-40px',
    width: '30px',
    cursor: 'pointer',

    '& img': {
      width: '100%',
    }
  },
  closebuttonmobile: {
    position: 'absolute',
    top: '40px',
    right: '24px',
    width: '40px',
    cursor: 'pointer',
    zIndex: 2,

    '& img': {
      width: '100%',
    }
  },
  artistTwoColumn: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left',
    borderRadius: '14px',
    overflow: 'hidden',
    background: '#ffffff',
    boxShadow: '-1px 2px 7px #ffffff',
    paddingBottom: '30px',

    '@media (max-width: 960px)' : {
      height: '78vh',
      overflowY: 'scroll',
    },

    '& .artwork': {
      width: '100%',
      marginBottom: '2vw',
      height: 'auto',

      '& img': {
        height: 'auto',
        width: '100%',
      },
    },

    '& .right': {
      width: '75%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingRight: '2vw',
      paddingLeft: '2vw',

      '& p': {
        fontSize: '14px',
        lineHeight: '20px',
        height: '120px',
        overflowY: 'scroll',
        fontStyle: 'italic',

        '@media (max-width: 960px)' : {
          height: 'auto',
          overflowY: 'hidden',
        },
      },

      '& button': {
        background: '#8d806f',
        color: '#ffffff',
        border: 'none',
        borderRadius: '7px',
        padding: '12px',
        fontStyle: 'italic',
        letterSpacing: '2px',
        width: '230px',
      }
    },

    '& .left': {
      width: '25%',
      paddingLeft: '2vw',

      '& img': {
        objectFit: 'contain',
        width: '100%',
        objectPosition: 'top center',
      },

      '& h5': {
        position: 'relative',
        color: '#76634E',
        margin: '10px 0 0',
        fontSize: '10px',
        fontFamily: 'Georgia',
      },

      '& h3': {
        position: 'relative',
        color: '#76634E',
        margin: '10px 0 4px',
        fontSize: '12px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontFamily: 'Georgia',

        '& span': {
          fontSize: '10px',
          textTransform: 'initial',
        },

        '& .divider': {
          background: '#76634E',
          position: 'absolute',
          bottom: -5,
          left: 0,
          width: '40px',
          height: '1px',
        }
      },
    },
  },
  artistDetailTwoColumn: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left',
    borderRadius: '14px',
    overflow: 'hidden',
    height: '80vh',
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: '-1px 2px 7px #ffffff',
    paddingBottom: '30px',
    maxHeight: '500px',

    '@media (max-width: 960px)' : {
      paddingBottom: '0px',
    },

    '& .left': {
      position: 'relative',
      width: '50%',
      paddingLeft: '2vw',

      '& img': {
        width: '100%',
      }
    },

    '& .right': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      textAlign: 'left',
      marginTop: '10px',
      width: '50%',
      paddingRight: '2vw',
      paddingLeft: '2vw',

      '& h5': {
        margin: 0,
        color: '#76634E',
        fontSize: '11px',
        fontFamily: 'Georgia',
      },

      '& h3': {
        position: 'relative',
        color: '#76634E',
        margin: '0 0 10px',
        fontFamily: 'Georgia',

        '& span': {
          fontSize: '10px',
          textTransform: 'initial',
        },

        '& .divider': {
          background: '#76634E',
          position: 'absolute',
          bottom: -5,
          left: 0,
          width: '40px',
          height: '1px',
        }
      },

      '& p': {
        fontSize: '14px',
        color: '#484A4B',
        height: '180px',
        overflowY: 'scroll',

        '& strong': {
          fontStyle: 'italic',
          fontWeight: 'bold',
        }
      },

      '& button': {
        background: '#8d806f',
        color: '#ffffff',
        border: 'none',
        borderRadius: '7px',
        padding: '12px',
        fontStyle: 'italic',
        letterSpacing: '2px',
        width: '230px',
      }
    },
  },
  twoRow: {
    margin: 'auto',

    '& .imageContainer': {
      position: 'relative',
      height: '70vh',
      width: 'auto',
      marginTop: '-12vh',

      '@media (max-width: 960px)' : {
        height: '56vh',
        marginTop: '-22vh',
      },

      '& img': {
        objectFit: 'contain',
        height: '100%',
        objectPosition: 'center',
      }
    },

    '& .descriptionContainer': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      textAlign: 'left',
      marginTop: '10px',

      '& h5': {
        margin: 0,
        color: '#ffffff',
        fontSize: '11px',
        fontFamily: 'Georgia',

        '@media (max-width: 960px)' : {
          fontSize: '9px',
        },
      },

      '& h3': {
        position: 'relative',
        color: '#ffffff',
        margin: '0 0 10px',
        fontFamily: 'Georgia',

        '@media (max-width: 960px)' : {
          fontSize: '11px',
        },

        '& .divider': {
          background: '#ffffff',
          position: 'absolute',
          bottom: -5,
          left: 0,
          width: '40px',
          height: '1px',
        }
      },

      '& p': {
        fontSize: '12px',
        color: '#ffffff',
        fontStyle: 'italic',
        lineHeight: '1.2em',

        '@media (max-width: 960px)' : {
          fontSize: '10px',
          lineHeight: '1.1em',
        },
      }
    },
  },
  emailnotfound: {
    position: 'absolute',
    bottom: '-20px',
    color: '#ffffff',
    fontSize: '20px',
  },
  directory: {
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '14px',
    overflow: 'hidden',
    alignItems: 'center',
    background: '#ffffff',
    boxShadow: '-1px 2px 7px #ffffff',
    padding: '2vw',
    textAlign: 'center',

    '@media (max-width: 960px)' : {
      padding: '4vw',
      height: '100%',
      overflowY: 'scroll',
    },

    '& h3': {
      fontSize: '30px',
    },

    '& #videoplayer': {
      position: 'relative',
      margin: '20px auto',

      '& video': {
        width: '20vw',
        cursor: 'pointer',
        margin: '0 auto',

          '@media (max-width: 960px)' : {
            width: '50vw',
          },
      },

      '& .videocontrols': {
        left: '0px',
        top: '0px',
        cursor: 'pointer',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        transition: '1s background ease',
        background: 'rgba(0, 0, 0, 0.0)',

        '&:hover': {
          background: 'rgba(0, 0, 0, 0.7)',
        },

        '& > div': {
          width: '50px',

          '& img': {
            width: '100%',
          }
        },

        '& > .pause': {
          transition: '1s opacity ease',
          opacity: 0,

          '&:hover': {
            opacity: 1,
          }
        },
      },
    },

    '& p': {
      fontSize: '12px',
      color: '#484A4B',
      fontStyle: 'italic',

      '& strong': {
        fontStyle: 'italic',
        fontWeight: 'bold',
      }
    },
  },
  coloredbox: {
    background: '#fbf4ec',
    border: '1px solid #d1bfab',
    padding: '2vw',
    borderRadius: '10px',
    fontStyle: 'italic',

    '& h5': {
      fontSize: '10px',
      fontFamily: 'Georgia',
      color: '#76634E',
      margin: '0 0 6px',
    },

    '& h4': {
      fontSize: '16px',
      fontFamily: 'Georgia',
      color: '#76634E',
      margin: '0 0 20px',
      position: 'relative',

      '& .divider': {
        background: '#af9c87',
        position: 'absolute',
        transform: 'translateX(-50%)',
        bottom: -5,
        left: '50%',
        width: '40px',
        height: '1px',
      }
    },
  }
}));

export default function TransitionsModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let arraytoStorekeyToHighLight: string[] = []
  let aggregatedid = ''
  const modalShown = useSelector((state: any) => state.modal.modalShown)
  const type = useSelector((state: any) => state.modal.data)
  const weblink = useSelector((state: any) => state.props.weblinkValue)
  const messageTitle = useSelector((state: any) => state.props.messageValueTitle) // need to follow the console log next state props: {activeParam: "message"}
  const [traineeIds, setTraineeIds] = useState(arraytoStorekeyToHighLight)
  const [corporateIds, setCorporateIds] = useState(arraytoStorekeyToHighLight)
  const [emailNotFound, setEmailNotFound] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const maxwidth = useMediaQuery('(max-width: 960px)');

  let iframe = document.getElementById('GalleryIFrame');

  const vidRef = useRef<HTMLVideoElement>(null);

  let isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME';

  const [formData, setData] = useState({
    email: '',
  });

  const [formError, setFormError] = useState({
      email: false,
  });
  // data for game results
  //const HintUsed = useSelector((state: any) => state.corporate.hintUsed)
  //const TimeTaken = useSelector((state: any) => state.corporate.timetaken)
  //const QuizScore = useSelector((state: any) => state.corporate.quizescore)
  //const userId = useSelector((state: any) => state.corporate.userIds)
  //const GameResultId = useSelector((state: any) => state.corporate.deleteId)
  // console.log(GameResultId)
  // console.log(`hintused: ${HintUsed} TqimeTaken: ${TimeTaken} QuizScore: ${QuizScore} userId: ${userId}`)
  // console.log(HintUsed)
  // console.log(typeof(HintUsed))

  const closeModal = (actions: any) => {
    if( actions != null ){
      if(actions.cleardata != null){
        switch(actions.cleardata) {
          case 'auth':
              dispatch(auth.authclear.started({}))
            break;
        }
      }

      if(actions.redirectpage != ''){
        dispatch(push(actions.redirectpage))
      }
    }

    setTimeout(() => {
      dispatch(modal.hide.started({}))
    }, 500)
  }

  const deleteRow = () => {
    // dispatch(modalActions.courseManagerRow(true))
    handleRemoveRow()
    dispatch(modal.hide.started({}))
  }

  const showWayFinder = (index: number) => {
    if (isIFrame(iframe) && iframe.contentWindow) {
      iframe.contentWindow.postMessage({
        'wayfinder': 'showGal0' + index
      }, "*");
    }
    dispatch(modal.hide.started({}));
  }

  const playVideo = () => {
    setIsMuted(true);
    vidRef.current && vidRef.current.play();
    if (isIFrame(iframe) && iframe.contentWindow) {
      iframe.contentWindow.postMessage({
        muted: true
      }, "*");
    }
  }

  const pauseVideo = () => {
    setIsMuted(false);
    vidRef.current && vidRef.current.pause();
    if (isIFrame(iframe) && iframe.contentWindow) {
      iframe.contentWindow.postMessage({
        muted: false
      }, "*");
    }
  }

  const afterGameResult = () => {
    dispatch(modal.hide.started({}));
  }
  // retrieve data from backend
  const rows = useSelector((state: any) => state.modal.tableRows)
  const index = useSelector((state: any) => state.props.TableRowIndex)
  // const performanceRecordsKey = useSelector((state: any) => state.corporate.PRkey)
  // console.log(performanceRecordsKey)
  // to delete
  function handleRemoveRow() {
    // console.log(rows)
    let x = rows.splice(index, 1)
    // console.log(x)
    // console.log(rows)
    //dispatch(modalActions.courseManagerRow(rows))
    // setRows(initialState => [...initialState = rows])
    //dispatch(propsActions.TableRowIndex(null, false))
  };

  const returnBack = () => {
    dispatch(push('/corporate/overview'))
    dispatch(modal.hide.started({}))
  }

  const deleteRecordsForPerformance = (data: any) => {
    dispatch(gameresult.deletegameresult.started({gameresultids: data.source}))
    dispatch(modal.hide.started({}))
    // dispatch(push('/traineeusermanagement'))
  }

  const deleteRecordsForPerformanceForTrainee = () => {
    //deleteRecords(GameResultId)
    dispatch(modal.hide.started({}))
    dispatch(push('/corporate/overview'))
  }

  // delete using axios for gamerecords
  const deleteRecords = async (id: any) => {
    // console.log(id)
    const DataOfUser = await axios({
      method: 'delete',
      // url: process.env.REACT_APP_BACKEND_URL + '/props/',
      url: `https://museum.createscape.com.sg/gameresult/${id}`,
      headers: {
        "content-type": "application/json",
        "Authorization": "Bearer "+accessToken
      }
    })
    return DataOfUser;
  }

  const includeTraineeIds = async (ids: any) => {
    ids.map(async (item: any, i: number, ids: any) => {
        const DataOfUser = await axios({
          method: 'get',
          url: 'https://museum.createscape.com.sg/users/' + item + '/TRAINEE',
          headers: {
            "content-type": "application/json",
          }
        })

        corporateIds.push(item);
        if (i + 1 === ids.length) {
          let corpids = corporateIds.join(',')
          dispatch(user.delete.started({userids: corpids}))
        }

        let datacollected = DataOfUser.data.data;

        datacollected.map((data: any, i: number, ids: any)=> {
          traineeIds.push(data.id);
          if (i + 1 === ids.length) {
            let traineeids = traineeIds.join(',')
            dispatch(user.delete.started({userids: traineeids}))
          }
        })
    })
  }
  //const urlWithId = useSelector((state: any) => state.corporate.deleteIds)
  //const traineeUrlWithid = useSelector((state: any) => state.corporate.traineeDeleteUrl)
  const accessToken = useSelector((state: any) => state.user.accessToken)
  /* const deleteUserInfo = async () => {
    const response = await axios({
      method: 'delete',
      // url: process.env.REACT_APP_BACKEND_URL + 'auth/register',
      url: traineeUrlWithid,
      headers: {
        "content-type": "application/json",
        "Authorization": "Bearer "+accessToken
      }
    })
    deleteUser()
    return response
  }
  const deleteUser = async () => {
    const response = await axios({
      method: 'delete',
      // url: process.env.REACT_APP_BACKEND_URL + 'auth/register',
      url: urlWithId,
      headers: {
        "content-type": "application/json",
        "Authorization": "Bearer "+accessToken
        }
     })
    return response
  } */

  const getRandomString = (length: any) => {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  const submitButton = (formData: any) => {
    let err = [false];
    let email = formData.email;
    let token = getRandomString(40);
    let checker = (arr: any[]) => arr.every(v => v === false);

    if(isEmail(email)){
      axios.get('https://museum.createscape.com.sg/users/findbyemail/' + email).then(data => {

            setEmailNotFound(false);

            Object.entries(formData).map(([key, value]) => {
              if((key == 'email') && (value == '')) {
                  err[0] = true;
              } else if ((key == 'email') && (value != '')) {
                  err[0] = false;
              }
            });

            setFormError({...formError, email: err[0]});

            if(checker(err)) {
              axios.post('https://www.createscape.com.sg/login/mailer.php', {email, form: 'reset', token: data.data.id + '__' + token}, {
                  headers: { 'Content-Type': 'multipart/form-data' },
              }).then(data => {
                }
              ).catch(err => {
                  console.log(err)
                  return null
              });

              axios.post('https://museum.createscape.com.sg/emailvalidate', {token: data.data.id + '__' + token}, {
                  headers: { 'Content-Type': 'application/json' },
              }).then(data => {
                  dispatch(modal.hide.started({}))
                  }
              ).catch(err => {
                  console.log(err)
                  return null
              })
            } else {
                return false;
            }
        }
      ).catch(err => {
        setEmailNotFound(true);
        console.log(err)
        return null
      });
    }
  }

  const isEmail = ( email: any ) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ( re.test(email) ) { return true }
    else { return false }
  }

  const changeHandler = (e: { target: { name: any; value: any } }) => {
      setFormError({...formError, [e.target.name]: false});
      setData({...formData, [e.target.name]: e.target.value})
  }

  const deleteUserAccount = (data: any) => {
    includeTraineeIds(data.source)
    closeModal(null);
  }

  const deleteCourses = (data: any) => {
    dispatch(course.deletecourse.started({courseids: data.source}))
    closeModal(null);
  }

  const deleteCourseTypes = (data: any) => {
    dispatch(coursetype.deletecoursetype.started({coursetypeid: data.source}))
    closeModal(null);
  }

  const ModalType = (data: any) => {
    console.log(data);
    if (data == null) {
      //
    } else {
      switch (data.type) {
        case 'viewGameResult':
          return (
            <div>
              <div className={classes.GameResults}>
                <h1>TRAINING SESSIONS PERFORMANCE RECORDS</h1>
                <div className={classes.forAlignment}>
                  <img src={performancerecordsBG} alt="performancerecordsBG" className={classes.gameResultBG} />
                  <div className={classes.recordsMainContainer}>
                    {/* {lightbulbNum(HintUsed)} */}
                    {/* <GameResultsLightBulb /> */}
                    {/* <div className={classes.hintsUsed}>
                        <p>HINT USED:</p>
                        <img src={lightBulbDark} alt="lightBulbDark" />
                      </div> */}
                    <div className={classes.quizScoreTimeTakenBox}>
                      <div className={classes.portion1Box}>
                        <div className={classes.portion1}>
                          <p className={classes.scoreQuiz}>QUIZ SCORE:</p>
                          <div className={classes.questionsAnswered}>
                            <p className={classes.outOf10}>Quiz Score / 10</p>
                            <p className={classes.QAC}>QUESTIONS ANSWERED CORRECTLY</p>
                          </div>
                        </div>
                      </div>
                      <div className={classes.portion1Box}>
                        <div className={classes.portion2}>
                          <div className={classes.takenTime}>
                            <img src={hourGlass} alt="hourGlass" />
                            <p>TIME TAKEN:</p>
                          </div>
                          <p className={classes.timeValue}>Time Taken</p>
                          {/* <p className={classes.timeValue}>01 : 30 : 00</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.backBtn} onClick={() => afterGameResult()}>
                <img src={BackSymbol} alt="BackSymbol" />
                <p>BACK</p>
              </div>
            </div>
          )
        case 'saveTraineeSetup':
          return (
            <div className={classes.paper}>
              <h1>Success!<br />The entered details have been saved.</h1>
              <div>
                <img src={OkayBtn} alt="cancel" onClick={() => closeModal({redirectpage: '/corporate/traineelisting', cleardata: 'auth'})} />
              </div>
            </div>
          )
        case 'showArtwork':
          return (
            <div className={classes.papertwo}>
              <div className={classes.twoRow}>
                <div className='imageContainer'>
                  <img src={`https://museumgallerybucket.s3.ap-southeast-1.amazonaws.com/${data.source.data[0].file}`}/>
                  <div className='descriptionContainer'>
                    <h5>{data.source.data[0].artist.name}</h5>
                    <h3>{data.source.data[0].name}<div className='divider'></div></h3>
                    <p>{data.source.data[0].painttype}<br />{data.source.data[0].width + ' x ' + data.source.data[0].height + ' cm'}<br />{data.source.data[0].year}</p>
                  </div>
                  <div className={classes.closebutton}>
                    <img src={CloseBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
                  </div>
                </div>
              </div>
            </div>
          )
        case 'showArtist':
            return (<div>
              {data.source == '1' ?
                <div className={classes.paper}>
                  <div className={classes.artistTwoColumn}>
                    <div className='artwork'>
                      <img src={featuredart01}/>
                    </div>
                    <div className='left'>
                      <img src={artist01} />
                      <h5>Gallery 01</h5>
                      <h3>Masturah Sha’ari<br /><span>b. 1969, Singapore</span><div className='divider'></div></h3>
                    </div>
                    <div className='right'>
                      <p><strong>Masturah Sha’ari</strong> is a designer, artist and art gallerist. She has designed numerous award-winning books and publications. She received a Certificate of Commendation at the Conqueror Horseman Design &amp; Print Awards in 2000. Her paintings are abstract expressions inspired by her experiences through her life journey and have been exhibited in Singapore and Malaysia. Masturah was appointed Chairman of the 2021 Singapore Women Artists Exhibition, organised by the Federation of Art Societies Singapore and held at the Singapore Chinese Cultural Centre.<br /><br />

Masturah earned her MA in Asian Art Histories from Goldsmiths, University of London. She received her BA, majoring in Economics and Malay Studies, from the National University of Singapore, and Diploma in Design Communication from LASALLE College of the Arts. She strongly supports multiculturalism and inclusivity, as well as the bridging of traditional and contemporary art practices.
                      </p>
                      <button onClick={() => showWayFinder(1)}>Navigate to Gallery 01</button>
                    </div>
                  </div>
                  <div className={classes.closebutton}>
                    <img src={CloseBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
                  </div>
                </div>
              : data.source == '2' ?
              <div className={classes.paper}>
                  <div className={classes.artistTwoColumn}>
                    <div className='artwork'>
                      <img src={featuredart02}/>
                    </div>
                    <div className='left'>
                      <img src={artist02} />
                      <h5>Gallery 02</h5>
                      <h3>Jeffrey Wandly<br /><span>b. 1964, Singapore</span><div className='divider'></div></h3>
                    </div>
                    <div className='right'>
                      <p><strong>Jeffrey Wandly</strong> captures the essence of heritage and historical landmarks with ink and acrylic on canvas. Fondly called the “building whisperer”, he paints in his distinctive style of intuitive and fluid strokes a visual diary filled with intimate portraits of the energy and vitality of inhabited spaces. He has had solo shows at Artshow Busan, Art Expo Malaysia and Singapore Contemporary, and has exhibited in Singapore, Malaysia, Indonesia, Hong Kong, Korea and Vietnam. His works can be found in the collections of Singapore and foreign government ministries, organisations and private individuals.<br /><br />
Jeffrey graduated from the National University of Singapore with a Bachelor of Architecture (Honours). He is a recipient of the MENDAKI Excellence Award; and the Architecture Angullia Scholarship. He holds a Master of Project Management from Queensland University of Technology and is a project management professional and associate member of the Singapore Institute of Architects. Jeffrey received the Merit Award at the Singapore Tourism Board’s Saces Sculptor Competition (1980) and the Third prize (Seniors) at the Takashimaya National Student Painting Competition (1992). He won the Best of Show Award at SeniKita by Kamal Arts Gallery, Wisma Geylang Serai (2020).
                      </p>
                      <button onClick={() => showWayFinder(2)}>Navigate to Gallery 02</button>
                    </div>
                  </div>
                  <div className={classes.closebutton}>
                    <img src={CloseBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
                  </div>
                </div> : data.source == '3' ? <div className={classes.paper}>
                  <div className={classes.artistTwoColumn}>
                    <div className='artwork'>
                      <img src={featuredart03}/>
                    </div>
                    <div className='left'>
                      <img src={artist03} />
                      <h5>Gallery 03</h5>
                      <h3>Idris Mohamed Ali<br /><span>b. 1944, Singapore</span><div className='divider'></div></h3>
                    </div>
                    <div className='right'>
                      <p><strong>Idris Mohamed Ali</strong> is one of Singapore’s most senior Malay artists. For more than 50 years, Idris has captured Singapore’s landscapes in colourful watercolours, and exhibited at numerous group exhibitions, including three successful solo shows. His works can be found in the collections of the National Gallery Singapore, National Heritage Board, Ministry of Foreign Affairs and various government agencies in Singapore and Malaysia, as well as private organisations and individuals.<br /><br />

A member of the Angkatan Pelukis Aneka Daya or APAD (Association of Artists of Various Resources), Idris has taught many young students under its Tunas (Budding) programme, as well as mentored art teachers. Idris is a recipient of the inaugural Hadiah Warisan (Heritage Prize) by Malay Heritage Foundation (2021) and two awards by APAD—Pingat APAD (1974) and Cipta Mekar (1990). He has also illustrated various award-winning publications. Through his talent, dedication, hard work and versatility, Idris Ali has contributed greatly to Singapore’s art scene.
                      </p>
                      <button onClick={() => showWayFinder(3)}>Navigate to Gallery 03</button>
                    </div>
                  </div>
                  <div className={classes.closebutton}>
                    <img src={CloseBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
                  </div>
                </div> :
                <div className={classes.paper}>
                <div className={classes.artistTwoColumn}>
                  <div className='artwork'>
                    <img src={featuredart04}/>
                  </div>
                  <div className='left'>
                    <img src={artist04} />
                    <h5>Gallery 04</h5>
                    <h3>Valerie Ng<br /><span>b. 1974, Malaysia</span><div className='divider'></div></h3>
                  </div>
                  <div className='right'>
                    <p><strong>Valerie Ng</strong> attended the Alternative Foundation in Fine Art at The Slade School of Fine Art Summer School, University College of London. Working mostly in oil on canvas or acrylic on paper, her paintings are in an abstract expressionist style, created from her explorations in colour, light, depth, form and texture, as well as inspired by the natural elements, hues and shapes in the environment. Her paintings have been shown at the Singapore Art Museum as well as the National Art Gallery, Malaysia. She has had solo exhibitions at the Substation, Maya Gallery, Mulan Gallery and Wetterling Teo Gallery, as well as exhibited at Jendela Gallery at the Esplanade. Her works have been auctioned by Larasati Auctioneers in Singapore.<br /><br />

A Malaysian based in Singapore, Valerie has done artist residencies in Italy, Vermont USA, Iceland and Finland. Her works can be found in corporate and private collections in Singapore, Malaysia, Australia, New Zealand, UK, USA, Mexico, Germany and Sweden. She won the Bronze Award in the United Overseas Bank (UOB) Painting of the Year 2018, First Prize (Abstract medium category) in 2004 as well as ‘Highly-Commended’ awards in 2006.  She received a Distinction in the Malaysian Art Competition, 2007 and selected for the Young Contemporaries 2006 in Malaysia.
                    </p>
                    <button onClick={() => showWayFinder(4)}>Navigate to Gallery 04</button>
                  </div>
                </div>
                <div className={classes.closebutton}>
                  <img src={CloseBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
                </div>
              </div> }
            </div>)
        case 'showDirectory':
          return (
            <div className={classes.paper}>
              <div className={classes.directory}>
                <div className='descriptionContainer'>
                  <h3>mayaspace</h3>
                  <p>Maya Gallery presents <strong>Mayaspace</strong>, a project that allows audiences worldwide to interact with artworks in an immersive environment. It is anchored in the <strong>Art Ark</strong>, a visionary space comprising eight exhibition galleries with amazing views of the Singapore landscape. This virtual platform makes possible the bridging of art and technology, as well as facilitate the preservation and sharing of our cultural heritage.</p>
                </div>
                <div id="videoplayer">
                  <video ref={vidRef}  preload="auto" poster={videoposter}>
                      <source src={fullvideo} type="video/mp4" />
                  </video>
                  <div className="videocontrols">
                    {isMuted == false ?
                      <div className="play" onClick={() => playVideo()}><img src={play}/></div> :
                      <div className="pause" onClick={() => pauseVideo()}><img src={pause}/></div>
                    }
                  </div>
                </div>
                <div className={classes.coloredbox}>
                  <h4>Virtual Encounters<div className='divider'></div></h4>
                  <p>Marking the first virtual exhibition at the Art Ark, <strong>Virtual Encounters</strong> features the works of Singapore artists Valerie Ng, Masturah Sha’ari, Jeffrey Wandly and Idris Ali. Presented across four galleries, the exhibition showcases paintings in watercolour, acrylic, oil and ink. Virtual Encounters celebrates the diverse artistic styles of these four local artists, highlighting selected works that represent the depth and breadth of their art practice to date. </p>
                </div>

                <div className={classes.closebutton}>
                    <img src={CloseBtn} alt="cancel" onClick={() => {
                      dispatch(modal.hide.started({})); pauseVideo()}
                    } />
                  </div>
              </div>
            </div>
          )
        case 'showArtistDetail':
          return (<div>
            {data.source == '1' ?
              <div className={classes.paper}>
                <div className={classes.artistDetailTwoColumn}>
                  <div className='left'>
                    <img src={artist01} />
                  </div>
                  <div className='right'>
                    <h5>Gallery 01</h5>
                    <h3>Masturah Sha’ari<br /><span>b. 1969, Singapore</span><div className='divider'></div></h3>
                    <p><strong>Masturah Sha’ari</strong> is a designer, artist and art gallerist. She has designed numerous award-winning books and publications. She received a Certificate of Commendation at the Conqueror Horseman Design &amp; Print Awards in 2000. Her paintings are abstract expressions inspired by her experiences through her life journey and have been exhibited in Singapore and Malaysia. Masturah was appointed Chairman of the 2021 Singapore Women Artists Exhibition, organised by the Federation of Art Societies Singapore and held at the Singapore Chinese Cultural Centre.<br /><br />

                    Masturah earned her MA in Asian Art Histories from Goldsmiths, University of London. She received her BA, majoring in Economics and Malay Studies, from the National University of Singapore, and Diploma in Design Communication from LASALLE College of the Arts. She strongly supports multiculturalism and inclusivity, as well as the bridging of traditional and contemporary art practices.
                    </p>
                  </div>
                </div>
                <div className={classes.closebutton}>
                  <img src={CloseBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
                </div>
              </div>
            : data.source == '2' ?
            <div className={classes.paper}>
                <div className={classes.artistDetailTwoColumn}>
                  <div className='left'>
                    <img src={artist02} />
                  </div>
                  <div className='right'>
                    <h5>Gallery 02</h5>
                    <h3>Jeffrey Wandly<br /><span>b. 1964, Singapore</span><div className='divider'></div></h3>
                    <p><strong>Jeffrey Wandly</strong> captures the essence of heritage and historical landmarks with ink and acrylic on canvas. Fondly called the “building whisperer”, he paints in his distinctive style of intuitive and fluid strokes a visual diary filled with intimate portraits of the energy and vitality of inhabited spaces. He has had solo shows at Artshow Busan, Art Expo Malaysia and Singapore Contemporary, and has exhibited in Singapore, Malaysia, Indonesia, Hong Kong, Korea and Vietnam. His works can be found in the collections of Singapore and foreign government ministries, organisations and private individuals.<br /><br />

Jeffrey graduated from the National University of Singapore with a Bachelor of Architecture (Honours). He is a recipient of the MENDAKI Excellence Award; and the Architecture Angullia Scholarship. He holds a Master of Project Management from Queensland University of Technology and is a project management professional and associate member of the Singapore Institute of Architects. Jeffrey received the Merit Award at the Singapore Tourism Board’s Saces Sculptor Competition (1980) and the Third prize (Seniors) at the Takashimaya National Student Painting Competition (1992). He won the Best of Show Award at SeniKita by Kamal Arts Gallery, Wisma Geylang Serai (2020).
                      </p>
                  </div>
                </div>
                <div className={classes.closebutton}>
                  <img src={CloseBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
                </div>
              </div> : data.source == '3' ? <div className={classes.paper}>
                <div className={classes.artistDetailTwoColumn}>
                  <div className='left'>
                    <img src={artist03} />
                  </div>
                  <div className='right'>
                    <h5>Gallery 03</h5>
                    <h3>Idris Mohamed Ali<br /><span>b. 1944, Singapore</span><div className='divider'></div></h3>
                    <p><strong>Idris Mohamed Ali</strong> is one of Singapore’s most senior Malay artists. For more than 50 years, Idris has captured Singapore’s landscapes in colourful watercolours, and exhibited at numerous group exhibitions, including three successful solo shows. His works can be found in the collections of the National Gallery Singapore, National Heritage Board, Ministry of Foreign Affairs and various government agencies in Singapore and Malaysia, as well as private organisations and individuals.<br /><br />

A member of the Angkatan Pelukis Aneka Daya or APAD (Association of Artists of Various Resources), Idris has taught many young students under its Tunas (Budding) programme, as well as mentored art teachers. Idris is a recipient of the inaugural Hadiah Warisan (Heritage Prize) by Malay Heritage Foundation (2021) and two awards by APAD—Pingat APAD (1974) and Cipta Mekar (1990). He has also illustrated various award-winning publications. Through his talent, dedication, hard work and versatility, Idris Ali has contributed greatly to Singapore’s art scene.
                    </p>
                  </div>
                </div>
                <div className={classes.closebutton}>
                  <img src={CloseBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
                </div>
              </div> :
              <div className={classes.paper}>
              <div className={classes.artistDetailTwoColumn}>
                <div className='left'>
                  <img src={artist04} />
                </div>
                <div className='right'>
                <h5>Gallery 04</h5>
                  <h3>Valerie Ng<br /><span>b. 1974, Malaysia</span><div className='divider'></div></h3>
                  <p><strong>Valerie Ng</strong> attended the Alternative Foundation in Fine Art at The Slade School of Fine Art Summer School, University College of London. Working mostly in oil on canvas or acrylic on paper, her paintings are in an abstract expressionist style, created from her explorations in colour, light, depth, form and texture, as well as inspired by the natural elements, hues and shapes in the environment. Her paintings have been shown at the Singapore Art Museum as well as the National Art Gallery, Malaysia. She has had solo exhibitions at the Substation, Maya Gallery, Mulan Gallery and Wetterling Teo Gallery, as well as exhibited at Jendela Gallery at the Esplanade. Her works have been auctioned by Larasati Auctioneers in Singapore.<br /><br />

A Malaysian based in Singapore, Valerie has done artist residencies in Italy, Vermont USA, Iceland and Finland. Her works can be found in corporate and private collections in Singapore, Malaysia, Australia, New Zealand, UK, USA, Mexico, Germany and Sweden. She won the Bronze Award in the United Overseas Bank (UOB) Painting of the Year 2018, First Prize (Abstract medium category) in 2004 as well as ‘Highly-Commended’ awards in 2006.  She received a Distinction in the Malaysian Art Competition, 2007 and selected for the Young Contemporaries 2006 in Malaysia.
                  </p>
                </div>
              </div>
              <div className={classes.closebutton}>
                <img src={CloseBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div> }
          </div>)
        case 'showInfo':
          return (
            <div className={isMobile === true ? classes.instructionpapermobile : classes.instructionpaper } >
              <div className='instruction'>
                {isMobile === true ? <IconSwiper /> : <img src={instruction} /> }
              </div>
              <div className={isMobile === true ? classes.closebuttonmobile : classes.closebutton }>
                <img src={CloseBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'saveCorporateSetup':
          return (
            <div className={classes.paper}>
              <h1>Success!<br />The entered details have been saved.</h1>
              <div>
                <img src={OkayBtn} alt="cancel" onClick={() => closeModal({redirectpage: '/admiral/corporatelisting', cleardata: 'auth'})} />
              </div>
            </div>
          )
        case 'saveAgentSetup':
            return (
              <div className={classes.paper}>
                <h1>Success!<br />The entered details have been saved.</h1>
                <div>
                  <img src={OkayBtn} alt="cancel" onClick={() => closeModal({redirectpage: '/admiral/agentlisting', cleardata: 'auth'})} />
                </div>
              </div>
            )
        case 'saveCourseType':
            return (
              <div className={classes.paper}>
                <h1>Success!<br />The entered details have been saved.</h1>
                <div>
                  <img src={OkayBtn} alt="cancel" onClick={() => closeModal({redirectpage: '/corporate/coursetypelisting', cleardata: 'auth'})} />
                </div>
              </div>
            )
        case 'imageDimensionError':
          return (
            <div className={classes.paper}>
              <h1>Image dimension is wrong.</h1>
              <div>
                <img src={OkayBtn} alt="Ok" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'fileSizeError':
          return (
            <div className={classes.paper}>
              <h1>File size must be lower than 1MB.</h1>
              <div>
                <img src={OkayBtn} alt="Ok" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'cancelBack':
          return (
            <div className={classes.backCancel}>
              <h1 className={classes.warn}>WARNING!</h1>
              <h1 className={classes.warningWords}> ARE YOU SURE YOU WANT TO LEAVE THE GAME?</h1>
              <h1 className={classes.warningWords1}>YOU WILL NOT BE ABLE TO BE ABLE TO CONTINUE BACK ONCE YOU LEAVE.</h1>
              <div className={classes.cancelBackBtn}>
                <div className={classes.Button} onClick={() => dispatch(modal.hide.started({}))}>
                  <p>CANCEL</p>
                  <img src={darkBg} alt="cancel" />
                </div>
                <div className={classes.Button} onClick={() => returnBack()}>
                  <p>LEAVE</p>
                  <img src={yellowBg} alt="cancel" />
                </div>
              </div>
            </div>
          )
        case 'saveProps':
          return (
            <div className={classes.paper}>
              <h1>Success!<br />The entered details have been saved.</h1>
              {/* <h1>Changes have been saved.</h1> */}
              <div>
                {/* <img src={save} alt="save" onClick={() => updateProps(activeParam)} /> */}
                <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'saveCourseManagerSetup':
          return (
            <div className={classes.paper}>
              <h1>Success!<br />The entered details have been saved.</h1>
              <div>
                <img src={OkayBtn} alt="cancel" onClick={() => closeModal({redirectpage: '/corporate/coursemanagerlisting', cleardata: 'auth'})} />
              </div>
            </div>
          )
        case 'forgetPassword':
          return (
            <div>
              <div className={classes.forget}>
                <div className={classes.innerContainer}>
                  <p className={classes.forgetPass}>Forgot your password?</p>
                  <p className={classes.enterEmailAddress}>Enter your email address and we'll send you a link to reset your password</p>
                </div>
                {/* <TextField1 placeholder="Email Address" type="text" /> */}
                <div className={classes.inputWrapper}>
                  <img src={textfieldbg} alt="textfieldbg" />
                  {/* <form onSubmit={e => e.preventDefault()}> */}
                    <input className={classes.input} onChange={changeHandler} onKeyPress={(e) => e.key === 'Enter' && submitButton(formData)} placeholder="EMAIL ADDRESS" type="text" id="email" name="email" autoComplete="off" />
                    {emailNotFound != false ? <p className={classes.emailnotfound}>Email is not registered.</p> : null }
                  {/* </form> */}
                </div>
                <div className={classes.Button} onClick={() => submitButton(formData)}>
                  <p>RESET</p>
                  <img src={yellowBg} alt="cancel" />
                </div>
                {/* <div className="submit"></div> */}
              </div>
              {/* <div className={classes.BackButton}>
                      <img src={BackBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
                    </div> */}
              <div className={classes.BackButton} onClick={() => dispatch(modal.hide.started({}))}>
                <img src={BackSymbol} alt="BackSymbol" />
                <p>BACK</p>
              </div>
            </div>

          )
        case 'DeleteCourseManager':
          return (
            <div className={classes.paper}>
              <h1>Are you sure you want to delete this course manager?</h1>
              <div>
                <img src={yes} alt="yes" onClick={() => deleteRow()} />
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'courseTypeRequired':
          return (
            <div className={classes.paper}>
              <h1>Course type is required. Please try again!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'courseRequired':
          return (
            <div className={classes.paper}>
              <h1>Course is required. Please try again!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'escapeRoomRequired':
          return (
            <div className={classes.paper}>
              <h1>Escape room is required. Please try again!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'courseNameRequired':
          return (
            <div className={classes.paper}>
              <h1>Course name is required. Please try again!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'courseDescriptionRequired':
          return (
            <div className={classes.paper}>
              <h1>Course description is required. Please try again!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'gameDurationRequired':
          return (
            <div className={classes.paper}>
              <h1>Game duration is required. Please try again!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'wrongCredentials':
          return (
            <div className={classes.paper}>
              <h1>Wrong email or password. Please try again!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'passwordNotMatch':
          return (
            <div className={classes.paper}>
              <h1>Password does not match!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'emptyField':
          return (
            <div className={classes.paper}>
              <h1>Fields must not be empty!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'passwordLength':
          return (
            <div className={classes.paper}>
              <h1>Password must be atleast 8 characters!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'passwordChanged':
          return (
            <div className={classes.paper}>
              <h1>Password changed successfully!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => {dispatch(modal.hide.started({})); dispatch(push('/'))}} />
            </div>
          </div>
          )
        case 'notAnEmailAddress':
          return (
            <div className={classes.paper}>
              <h1>Username must be a valid email address. Please try again!</h1>
              <div>
                <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
          </div>
          )
        case 'selectAssignedCourse':
          return (
            <div className={classes.paper}>
              <h1>Please select assigned course to play a game!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'noSessionRemaining':
          return (
            <div className={classes.paper}>
              <h1>You do not have available session credit please purchase a package!</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'formHasErrors':
            return (
              <div className={classes.paper}>
                <h1>The form has an invalid values.<br />Please review and answer all form fields!</h1>
              <div>
                <img src={OkayBtn} alt="cancel" onClick={() => {
                    dispatch(modal.hide.started({}))
                    dispatch(auth.authclear.started({}))
                }} />
              </div>
            </div>
            )
        case 'DeleteCourses':
          return (
            <div className={classes.paper}>
              <h1>Are you sure you want to delete this course?</h1>
              <div>
                <img src={yes} alt="yes" onClick={() => deleteCourses(data)} />
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'DeleteCourseType':
          return (
            <div className={classes.paper}>
              <h1>Are you sure you want to delete this course type?</h1>
              <div>
                <img src={yes} alt="yes" onClick={() => deleteCourseTypes(data)} />
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'DeleteTrainingCouse':
          return (
            <div className={classes.paper}>
              <h1>Are you sure you want to delete this training course?</h1>
              <div>
                <img src={yes} alt="yes" />
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'exceedSession':
          return (
            <div className={classes.paper}>
            <h1>Your do not have enough available sessions.</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'conflictAccount':
          return (
            <div className={classes.paper}>
            <h1>Email address already in use.</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        case 'DeletePerformanceRecords':
          return (
            <div className={classes.paper}>
              <h1>The selected records has been deleted</h1>
              <div>
                <img src={yes} alt="yes" onClick={() => deleteRecordsForPerformance(data)} />
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
                {/* <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} /> */}
              </div>
            </div>
          )
        case 'DeletePerformanceRecordsForTrainee':
          return (
            <div className={classes.paper}>
              <h1>Are you sure you want to delete this training session performance records?</h1>
              <div>
                <img src={yes} alt="yes" onClick={() => deleteRecordsForPerformanceForTrainee()} />
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'DeleteUserAccount':
          return (
            <div className={classes.paper}>
              <h1>Are you sure you want to delete this corporate account?</h1>
              <div>
                <img src={yes} alt="yes" onClick={() => deleteUserAccount(data)}/>
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'deleteTraineeAccount':
          return (
            <div className={classes.paper}>
              <h1>Are you sure you want to delete this trainee account?</h1>
              <div>
                <img src={yes} alt="yes" onClick={() => deleteUserAccount(data)}/>
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'DeleteAgentAccount':
          return (
            <div className={classes.paper}>
              <h1>Are you sure you want to delete this agent / reseller account?</h1>
              <div>
              <img src={yes} alt="yes" onClick={() => deleteUserAccount(data)}/>
                <img src={no} alt="no" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'saveProfileSetup':
          return (
            <div className={classes.paper}>
              <h1>Success!<br />The entered details have been saved.</h1>
              <div>
                {/* <img src={yes} alt="yes" onClick={() => deleteRecordsForPerformanceForTrainee()} /> */}
                <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
              </div>
            </div>
          )
        case 'saveCourseSetup':
            return (
              <div className={classes.paper}>
                <h1>Success!<br />The entered details have been saved.</h1>
              <div>
                <img src={OkayBtn} alt="cancel" onClick={() => closeModal({redirectpage: '/corporate/courselisting', cleardata: 'auth'})} />
              </div>
            </div>
            )
        case 'startTrainingCourseSession':
          return (
            <div className={classes.paper}>
              <h1>Success!<br />The entered details have been saved.</h1>
            <div>
              <img src={OkayBtn} alt="cancel" onClick={() => dispatch(modal.hide.started({}))} />
            </div>
          </div>
          )
        default:
          return (
            <h1>Default</h1>
          )
      }
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal}`}
      open={modalShown}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000,
        style: { backgroundColor: 'rgba(0,0,0,0.7)' }
      }}
    >
      <Fade in={modalShown}>
        {
          ModalType(type)
        }
      </Fade>
    </Modal>
  );
};
