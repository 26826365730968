import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import { makeStyles } from '@material-ui/core/styles';

// import AccountInfo from 'components/AccountInfo';
import AccountInfoNew from 'components/AccountInfoNew'
import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'

import bg from 'assets/PlatformHome/AuthoringPlatformBG1.png'
import logo from 'assets/logo.png'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import home from 'assets/PlatformHome/Home.png'
import homeActive from 'assets/PlatformHome/galleryActive.png'
import secret from 'assets/PlatformHome/Secret.png'
import secretActive from 'assets/PlatformHome/ChamberActive.png'
import mummyhouse from 'assets/PlatformHome/MummyHouse.png'
import mummyhouseActive from 'assets/PlatformHome/PyramidActive.png'
import galaxy from 'assets/PlatformHome/Galaxy.png'
import galaxyActive from 'assets/PlatformHome/ControlRoomActive.png'
import backtbtn from 'assets/PlatformHome/CourseEditPageBackBtn.png'
import { propsActions } from 'actions/propsActions'

const useStyles = makeStyles(() => ({
  containerAuthoringPlatform: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    '& .col-sm-6': {
      cursor: 'pointer',
    },
  },
  requiredfield: {
    color: "#FFD84C",
    fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    paddingBottom: '1rem',
    },
    images: {
        width: '38rem',
        cursor: 'pointer'
    },
    header: {
        fontFamily: 'impact',
        color: '#FFD140',
        fontSize: '2rem'
    },
    // back button
    backBtn: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      position: 'fixed',
      bottom: '1rem',
      right: '2rem',
      '& p': {
        color: '#FFD84E',
        fontFamily: 'impact',
        fontSize: '1.2rem',
        letterSpacing: '1px',
        paddingLeft: '1rem'
      },
      '& img': {
        width: '3vw%',
        height: '100%',
      }
    },
  // page top
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
    height: '12vh'
  },
  logo: {
    width: '12rem'
  },
}));

const AuthoringPlatformHome = () => {
    const dispatch = useDispatch();
    const classes  = useStyles();
    const activeParam = useSelector((state:any) => state.propsReducer.activeParam);
    const editData = useSelector((state: any) => state.router.location.state);

    const paramType1 = (type:string) => {
      dispatch(propsActions.activeParam(type))
    }

    return (
        <BaseLayoutAdjustable bg={bg}>
              <div className={classes.topInfo}>
                <img src={logo} alt="logo" className={classes.logo} />
                <AccountInfoNew />
              </div>
                <div className={classes.containerAuthoringPlatform}>
                        <h3 className={classes.header}>AUTHORING PLATFORM</h3>
                <div className="row">
                    <div className="col-sm-6">
                        {(activeParam === 'gallery') ?
                          <img src={homeActive} alt="homeActive" className={classes.images} onClick={() => dispatch (push('/corporate/gallery', {courseid: editData, environment: activeParam}))}/>
                            :
                      <img src={home} alt="home" className={classes.images} onMouseEnter={() => paramType1('gallery')}/>
                        }
                    </div>
                    <div className="col-sm-6">
                       {(activeParam === 'pyramid') ?
                          <img src={secretActive} alt="homeActive" className={classes.images} onClick={() => dispatch (push('/corporate/pyramid', {courseid: editData, environment: activeParam}))}/>
                            :
                          <img src={secret} alt="home" className={classes.images} onMouseEnter={() => paramType1('pyramid')}/>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        {(activeParam === 'chamber') ?
                          <img src={mummyhouseActive} alt="homeActive" className={classes.images} onClick={() => dispatch (push('/corporate/chamber', {courseid: editData, environment: activeParam}))}/>
                            :
                          <img src={mummyhouse} alt="home" className={classes.images} onMouseEnter={() => paramType1('chamber')}/>
                        }
                    </div>
                    <div className="col-sm-6">
                        {(activeParam === 'controlroom') ?
                          <img src={galaxyActive} alt="homeActive" className={classes.images} onClick={() => dispatch (push('/corporate/controlroom', {courseid: editData, environment: activeParam}))}/>
                            :
                          <img src={galaxy} alt="home" className={classes.images} onMouseEnter={() => paramType1('controlroom')}/>
                        }
                    </div>
                </div>
            </div>
          <div className={classes.backBtn} onClick={() => dispatch(push('/corporate/overview'))}>
            <img src={BackSymbol} alt="BackSymbol" />
            <p>BACK</p>
          </div>
        </BaseLayoutAdjustable>
    )
}

export default AuthoringPlatformHome;
