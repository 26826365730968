import user from './user'
import auth from './auth'
import modal from './modal'
import accountpackage from './accountpackage'
import coursemanagerinfo from './coursemanagerinfo'
import coursetype from './coursetype'
import environment from './environment'
import escaperoom from './escaperoom'
import gameresult from './gameresult'
import corporateinfo from './corporateinfo'
import corporateReducer from './coporatePageReducer'
import course from './course'
import exhibits from './exhibits'
import propsReducer from './propsReducer'
import traineeinfo from './traineeinfo'
import traineecourse from './traineecourse'
import validation from './validation'
import emailvalidation from './emailvalidation'

export default {
    ...user,
    ...auth,
    ...modal,
    ...accountpackage,
    ...corporateinfo,
    corporateReducer,
    propsReducer,
    ...course,
    ...coursemanagerinfo,
    ...coursetype,
    ...environment,
    ...escaperoom,
    ...gameresult,
    ...exhibits,
    ...traineeinfo,
    ...traineecourse,
    ...validation,
    ...emailvalidation,
}