import { combineEpics } from 'redux-observable'
import { show, hide } from './modal'
import { createAccountPackage, getAccountPackageByUserId, updateAccountPackageByUserId } from './accountpackage'
import { createCorporateInfo, getCorporateInfoByUserId, updateCorporateInfoByUserId, deductCorporateInfoSession } from './corporateinfo'
import { createCourse, getCoursesByUserId, updateCourse, deleteCourse } from './course'
import { createCourseManagerInfo, getCourseManagerInfoByUserId, updateCourseManagerInfoByUserId } from './coursemanagerinfo'
import { createCourseType, getCourseTypById, updateCourseType, deleteCourseType } from './coursetype'
import { createEnvironment, getEnvironment, updateEnvironment, deleteEnvironment } from './environment'
import { createEscaperoom, getEscaperoomByCourseId, updateEscaperoom, deleteEscaperoom } from './escaperoom'
import { getGameresultByUserId, getGameresultByCorporateId, deleteGameResults } from './gameresult'
import { createProps, getPropsByCourseId, getPropsByCourseIdAndLocationId, updateProps, deleteProps } from './exhibits'
import { createTraineeInfo, getTraineeInfoByUserId, updateTraineeInfoByUserId } from './traineeinfo'
import { createTraineeCourse, getTraineeCoursesByUserId, updateTraineeCourseByUserId, selectedTraineeCourse } from './traineecourse'
import { createAgentInfo, getAgentInfoByUserId, updateAgentInfoByUserId } from './agentinfo'
import { deleteUser, getUserByAccountType, updateUserById } from './user'
import { login, logout, registerUser, updateSession, authClear } from './auth'
import { createValidation } from './validation'
import { createEmailValidation, getEmailValidationByToken, deleteEmailValidation } from './emailvalidation'

export default combineEpics(
    show,
    hide,
    createAccountPackage,
    getAccountPackageByUserId,
    updateAccountPackageByUserId,
    createCorporateInfo,
    getCorporateInfoByUserId,
    updateCorporateInfoByUserId,
    deductCorporateInfoSession,
    createCourse,
    getCoursesByUserId,
    updateCourse,
    deleteCourse,
    createCourseManagerInfo,
    getCourseManagerInfoByUserId,
    updateCourseManagerInfoByUserId,
    createCourseType,
    getCourseTypById,
    updateCourseType,
    deleteCourseType,
    createEnvironment,
    getEnvironment,
    updateEnvironment,
    deleteEnvironment,
    createEscaperoom,
    getEscaperoomByCourseId,
    updateEscaperoom,
    deleteEscaperoom,
    getGameresultByUserId,
    getGameresultByCorporateId,
    deleteGameResults,
    createProps,
    getPropsByCourseId,
    getPropsByCourseIdAndLocationId,
    createTraineeCourse,
    getTraineeCoursesByUserId,
    updateTraineeCourseByUserId,
    selectedTraineeCourse,
    updateProps,
    deleteProps,
    createTraineeInfo,
    getTraineeInfoByUserId,
    updateTraineeInfoByUserId,
    createAgentInfo,
    getAgentInfoByUserId,
    updateAgentInfoByUserId,
    login,
    logout,
    registerUser,
    authClear,
    updateSession,
    updateUserById,
    getUserByAccountType,
    deleteUser,
    createValidation,
    createEmailValidation,
    getEmailValidationByToken,
    deleteEmailValidation,
)