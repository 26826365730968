import { CoporateConstants } from 'constants/index'

const initialState = {
  // trainee setup
  traineeName: null,
  traineeDateOfBirth: null,
  traineeEmail: null,
  groupName: null,
  hasCourseTypes: null,
  hasCourses: null,
  traineePassword: null,
  TraineepasswordSymbol: null,
  TraineeCourseType: null,
  TraineeAssignedCourse: null,
  TraineeAssignedNumOfSession: null,
  TraineeStartDate: null,
  TraineeEndDate: null,
  TraineeEnableOrDisable: null,
  //trainee Table
  traineeTableName: "NIL",
  traineeTableGroupName: "NIL",
  traineeCourseType: "NIL",
  traineeAssignedCourse: "NIL",
  traineeEndDate: "NIL",
  traineeTableData: [{
    col1: 'NIL',
    col2: 'NIL',
    col3: 'NIL',
    col4: 'NIL',
    col5: 'NIL',
    col6: 'NIL',
    key: '1'
    }]
  ,
  // performance records table
  sessionId: null,
  courseType: null,
  courseName: null,
  TraineeName: null,
  GroupName: null,
  SessionDate: null,
  SessionTime: null,
  tableData: [],
  // performance records table index
  TableRowIndex: null,
  IsitChecked: false,
  // performance records ids to delete
  deleteId: null,
  // trigger to send data for delete ids
  toSent: false,
  // game results information
  hintUsed: null,
  timetaken: null,
  quizescore: null,
  userId: null,
  gameResultId: null,

  // corporate profile setup
  masterAccountManagerName: null,
  companyName: null,
  masterAccountManagerEmail: null,
  masterAccountManagerPassword: null,
  masterAccPasswordSymbol: null,
  // triiger to sent to back end
  triggerProfileSetup: false,
  deleteIds: null,
  traineeDeleteUrl: null,
  // session id storage
  GeneratedSessionId: null,
  // for performance record page ids
  belongToCorporateIds: null
};

export default function corporate(state = initialState, action: any) {
  switch (action.type) {
    case CoporateConstants.TRAINEE_ACCOUNT_SETUP:
      return {
        ...state,
        traineeName: action.traineeName,
        traineeDateOfBirth: action.traineeDateOfBirth,
        traineeEmail: action.traineeEmail,
        groupName: action.groupName,
        traineePassword: action.traineePassword,
        TraineepasswordSymbol: action.passwordSymbol,
        TraineeCourseType: action.TraineeCourseType,
        TraineeAssignedCourse: action.TraineeAssignedCourse,
        TraineeAssignedNumOfSession: action.TraineeAssignedNumOfSession,
        TraineeStartDate: action.TraineeStartDate,
        TraineeEndDateTraineeEndDate: action.TraineeEndDateTraineeEndDate,
        TraineeEnableOrDisable: action.TraineeEnableOrDisable,
      }
    case CoporateConstants.CORPORATE_PROFILE_SETUP:
      return {
        ...state,
        masterAccountManagerName: action.masterAccountManagerName,
        companyName: action.companyName,
        masterAccountManagerEmail: action.masterAccountManagerEmail,
        masterAccountManagerPassword: action.masterAccountManagerPassword,
        masterAccPasswordSymbol: action.MasterAccPasswordSymbol
      }
    case CoporateConstants.TRAINEE_ACCOUNT_TABLE:
      return {
        ...state,
        traineeTableName: action.name,
        traineeTableGroupName: action.groupName,
        traineeCourseType: action.courseType,
        traineeAssignedCourse: action.AssignedCourse,
        traineeEndDate: action.endDate,
        traineeTableData: action.traineeTableData
      }
    case CoporateConstants.PERFORMANCE_RECORDS_TABLE:
      // console.log(`inside reducer : ${action.tableData}`)
      return {
        ...state,
        sessionId: action.sessionId,
        courseType: action.courseType,
        courseName: action.courseName,
        TraineeName: action.TraineeName,
        GroupName: action.GroupName,
        SessionDate: action.SessionDate,
        SessionTime: action.SessionTime,
        tableData: action.tableData
      }
    case CoporateConstants.PERFORMANCE_RECORDS_TABLE_ROW_INDEX:
      console.log(action.checked)
      return {
        ...state,
        TableRowIndex: action.index,
        IsitChecked: action.checked,
        // PRkey: action.PRkey
      }
    case CoporateConstants.PERFORMANCE_RECORDS_DELETE_IDS:
      // console.log(action.index)
      // console.log(action.id)
      return {
        ...state,
        deleteId: action.id,
      }
    case CoporateConstants.TRIGGER_TO_SENT_DELETE_IDS:
      console.log(action.toSent)
      return {
        ...state,
        toSent: action.toSent
      }
    case CoporateConstants.GAME_RESULTS_INFORMATION:
      return {
        ...state,
        hintUsed: action.hintUsed,
        timetaken: action.timetaken,
        quizescore: action.quizescore,
        userId: action.userId,
        gameResultId: action.gameResultId
      }
    case CoporateConstants.TRIGGER_TO_SEND_TO_BACKEND:
      return {
        ...state,
        triggerProfileSetup: action.toSentProfileSetup

      }
    case CoporateConstants.TRIGGER_TO_DELETE_USER_ACCOUNT:
      return {
        ...state,
        deleteIds: action.IdsToDelete,
        traineeDeleteUrl: action.TodeleteTraineeInfo
      }
    case CoporateConstants.HAS_COURSE_TYPES:
      return {
        ...state,
        hasCourseTypes: action.coursetypes
      }
    case CoporateConstants.HAS_COURSES:
      return {
        ...state,
        hasCourses: action.courses
      }
    case CoporateConstants.SESSION_ID_STORAGE:
      return {
        ...state,
        GeneratedSessionId: action.SessionId
      }
    case CoporateConstants.PERFORMANCE_RECORDS_BELONG_TO_COPORATEID:
      console.log()
      return {
        ...state,
        belongToCorporateIds: action.userid
      }
    default:
      return state;
  }
}


