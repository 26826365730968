import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { makeStyles } from '@material-ui/core/styles'
import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'
import modal from 'actions/modal'
import axios from 'axios';
import bg from 'assets/bg.png'
import logo from 'assets/logo.png'
import dropdownBG from 'assets/InputSmall.svg'
import textbg from 'assets/InputSmall.svg'
import EscapeRoomAuthoring from 'assets/TrainingCourseSetup/EscapeRoomAuthoringPlatform.png'
import FeedbackFormDisabled from 'assets/TrainingCourseSetup/FeedbackForm-disabled.png'
import InstructionFormDisabled from 'assets/TrainingCourseSetup/InstructionForm-disabled.png'
import EscapeRoomAuthoringDisabled from 'assets/TrainingCourseSetup/EscapeRoomAuthoringPlatform-disabled.png'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import SaveSymbol from 'assets/BaseImg/saveSymbol.png'
import AccountInfoNew from 'components/AccountInfoNew'
import enableButton from 'assets/ToggleButton/enableButtonType1.png'
import disableButton from 'assets/ToggleButton/disableButtonType1.png'
import information from 'assets/information.png'

const useStyles = makeStyles(() => ({
    requiredfield: {
        color: "#FFD84C",
        fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
        paddingBottom: '1rem',
    },
    button: {
        width: '100%',
        cursor: 'pointer'
    },
    buttonInput: {
        '& img' : {
            width:'100%',
        }
    },
  header: {
    fontFamily: "Impact",
    fontSize: "2.5rem",
    letterSpacing: "4px",
    paddingBottom: "2rem",
    color: '#FFFFFF',
    width: '67rem',
  },
  row: {
    display:'flex',
    justifyContent: 'center'
  },
  input: {
    // backgroundImage:`url(${bg})`,
    background: 'transparent',
    position: 'absolute',
    width: '100%',
    border: 'none',
    height: '80px',
    padding: '0 66px',
    textAlign: 'center',
    color: '#ffd84d',
    fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    fontSize: '1.2rem',
    letterSpacing: '1px',

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    textAlign: 'center',
    '& img': {
      width: '100%',
    }
  },
  dropdownborder: {
    width: '100%',
  },
  Group: {
    width: "18.9vw",
    border: "solid",
    height: "10vh",
    borderColor: "#FFFFFF",
    backgroundColor: "none",
    '& .Mui-selected': {
      backgroundColor: '#F4D000',
      color: '#FFFFFE'
    },
  },
  B1: {
    backgroundColor: '#342D12',
    color: '#FFFFFF',
  },
  word1: {
    fontFamily: 'impact',
    fontSize: '1.2rem'
  },
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '2vw',
      height: 'auto',
      cursor: 'pointer'
    }
  },
  saveBtn: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3rem',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '2vw',
      height: 'auto',
    }
  },
  pageBottom: {
    display: 'flex',
    position: 'fixed',
    bottom: "1rem",
    right: "2rem",
  },
  // page top
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '12rem'
  },
  hideInput: {
    position: 'absolute',
    top: '100rem'
  },
  enableDisableButton: {
    width: "100%",
    cursor: 'pointer'
  },
  information: {
    left: '5vw',
    width: '40px',
    bottom: '10vh',
    height: '40px',
    position: 'absolute',
    cursor: 'pointer',

    '& img': {
      width: '100%',
    }
  }
}));

const CourseEdit = () => {
  const dispatch = useDispatch()
  const classes  = useStyles();
  const id = useSelector((state: any) => state.auth.loggedinuser.user.id)
  const corporatetype = useSelector((state: any) => state.auth.loggedinuser.user.corporateinfo.corporatetype)
  const [toStop, setToStop] = useState(false)
  const temp = () => {
    let x = 1
  }

  let tableData: { name: string; id: string; }[] = []
  let tableData2: { name: string; id: string; }[] = []
  const editData = useSelector((state: any) => state.router.location.state)
  const [courseTypeFromAPI, setcourseTypeFromAPI] = useState(tableData)
  const [escaperoomFromAPI, setEscaperoomFromAPI] = useState(tableData2)

  const getCourseType = async () => {
    const DataOfUser = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/coursetype/` + id,
      headers: {
        "content-type": "application/json",
      }
    })
    let datacollected = DataOfUser.data.data
    setcourseTypeFromAPI(datacollected)
    return DataOfUser;
  }

  const updateCourse = async () => {
    const req = {
      "name": CourseName,
      "description": CourseDescription,
      "instruction": "",
      "feedback": "",
      "coursetype": CourseType,
      "duration": gameDuration,
      "user": id,
      "escaperoom": selectEscapeRoom,
      "status": EnableDisable
    }
    const response = await axios({
      method: 'PATCH',
      url: `${process.env.REACT_APP_BACKEND_URL}/course/` + editData.id,
      headers: {
        "content-type": "application/json",
     },
      data: req
    })
    //createEscapeRoom(response.data.id)
    return response;
  }

  const getAllEscaperoom = async () => {
    /* const req = {
      "name": selectEscapeRoom,
      "duration": gameDuration,
      "course": courseID
    } */
    const response = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_BACKEND_URL}/escaperoom`,
      headers: {
        "content-type": "application/json",
      }
    })
    let datacollected = response.data.data
    setEscaperoomFromAPI(datacollected)
    return response;
  }

  // axios
  function sentToBackEnd() {
    updateCourse()
  }

  // for the toggle button
  /* const [alignment, setAlignment] = React.useState<string | null>('left');

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    setEnableDisableButton(newAlignment)
    setAlignment(newAlignment);
  }; */

  // new custom toggle button
  const [EnableDisable, setEnableDisable] = useState(editData.status)
  const [toggleOption, settoggleOption] = useState(editData.status)
  const disableButtonClicked = () => {
    settoggleOption('enable')
    setEnableDisable(true)
  }
  const enableButtonClicked = () => {
    settoggleOption('disable')
    setEnableDisable(false)
  }
  const customToggleButton = (isItEnableOrDisable: any) => {
    if (isItEnableOrDisable === 'disable') {
      return (
        <img src={disableButton} alt="disableButton" onClick={() => disableButtonClicked()} className={classes.enableDisableButton}/>
      )
    } else {
      return (
        <img src={enableButton} alt="enableButton" onClick={() => enableButtonClicked()} className={classes.enableDisableButton}/>
      )
    }
  }
  // retrieving inputs from reducer
  // retrieving inputs
  const [CourseType, setCourseType] = React.useState(editData?.coursetype?.id)
  const [CourseName, setCourseName] = React.useState(editData.name)
  const [CourseDescription, setCourseDescription] = React.useState(editData.description)
  const [selectEscapeRoom, setselectEscapeRoom] = React.useState(editData.escaperoom.id)
  const [gameDuration, setgameDuration] = React.useState(editData.duration)

  useEffect(() => {
    console.log(CourseType);
    if(editData.status) {
      settoggleOption('enable');
    } else {
      settoggleOption('disable');
    }
    customToggleButton(toggleOption);
    if (toStop === false) {
      getCourseType()
      getAllEscaperoom()
      setToStop(true)
    } else {
      temp()
    }
  }, []);

  function retrieveInfoFromInput(inputType: any, input: any) {
    switch (inputType) {
      case 'coursetype':
        setCourseType(input)
        break;
      case 'name':
        setCourseName(input)
        break;
      case 'description':
        setCourseDescription(input)
        break;
      case 'gameduration':
          setgameDuration(input)
          break;
      case 'escaperoom':
        setselectEscapeRoom(input)
        break;
    }
  }

  const saveButtonClicked = () => {
    if(CourseType == 'SELECT COURSE TYPE*' || CourseType == undefined){
      dispatch(modal.show.started({type: 'courseTypeRequired', source: ''}))
    } else if (selectEscapeRoom == 'SELECT ESCAPE ROOM*') {
      dispatch(modal.show.started({type: 'escapeRoomRequired', source: ''}))
    } else if (CourseName == '') {
      dispatch(modal.show.started({type: 'courseNameRequired', source: ''}))
    } else if (CourseDescription == '') {
      dispatch(modal.show.started({type: 'courseDescriptionRequired', source: ''}))
    } else if (gameDuration == '') {
      dispatch(modal.show.started({type: 'gameDurationRequired', source: ''}))
    } else {
      dispatch(modal.show.started({type: 'saveCourseSetup', source: ''}))
      sentToBackEnd()
    }
  }

  // course type retrieve from api
  // function option(id:any,name:any) {
  //   <option value={id}>{name}</option>
  // }
  // uploading forms
  const instructionFormClicked = () => {
    let x = document.getElementById("instructionform")
    if (x !== null) {
      x.click()
    } else {
      console.log('null')
    }
  }

  const feedbackFormClicked = () => {
    let x = document.getElementById("feedbackform")
    if (x !== null) {
      x.click()
    } else {
      console.log('null')
    }
  }
  let intructionform = new FormData()
  let feedbackform = new FormData()
  const uploadInstructionForm = (e: any) => {
    if (e.target.files[0].name.includes('.pdf')=== true) {
      let uploadedFile = e.target.files[0]
      uploadedFile !== null ? intructionform.append('InstructionForm', uploadedFile) : temp()
    } else {
      alert('invalid file format uploaded, upload only pdf format')
    }
  }

  const uploadFeedbackForm = (e: any) => {
    if (e.target.files[0].name.includes('.pdf')=== true) {
      let uploadedFile = e.target.files[0]
      uploadedFile !== null?feedbackform.append('FeedbackForm', uploadedFile) : temp()
    } else {
      alert('invalid file format uploaded, upload only pdf format')
    }
  }

  return (
    <BaseLayoutAdjustable bg={bg}>
      <div className={classes.topInfo}>
        <img src={logo} alt="logo" className={classes.logo} />
        <AccountInfoNew />
      </div>
      <div className="containerUserAccountSetup">
        <div className={classes.row}>
          <h3 className={classes.header}>TRAINEE COURSE SETUP / AUTHORING</h3>
        </div>
        <div className="row justify-content-center pb-3">
          <div className="col-6">
            <h4 className={classes.requiredfield}>* INDICATES REQUIRED FIELD</h4>
          </div>
        </div>
        <div className="row">
          {/* <button onClick={()=> test() }>test</button> */}
          <div className="col-4 ">
            <div className="dropdown2">
              <img src={dropdownBG} alt="bg" className={classes.dropdownborder} />
              <select id='template-select' value={CourseType} onChange={(e) => retrieveInfoFromInput('coursetype', e.target.value)}>
                <option>SELECT COURSE TYPE*</option>
                {courseTypeFromAPI.map(course => <option key={course.id} value={course.id}>{course.name}</option>)}
              </select>
            </div>
          </div>
          <div className="col-4">
            {/* <TextFieldShort placeholder="COURSE NAME*" type="text"/> */}
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" value={CourseName} className={classes.input} placeholder="COURSE NAME*" type="text" onChange={(e) => retrieveInfoFromInput('name', e.target.value)}/>
            </div>
          </div>
          <div className="col-4 ">
            {/* <TextFieldShort placeholder="COURSE DESCRIPTION*" type="text" /> */}
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" value={CourseDescription} className={classes.input} placeholder="COURSE DESCRIPTION*" type="text" onChange={(e) => retrieveInfoFromInput('description', e.target.value)} />
            </div>
          </div>
        </div>
        <div className="row pb-4">
          {/* <div className="col-4 ">
            <img src={InstructionForm} alt="InstructionForm" className={classes.button} onClick={()=> instructionFormClicked()}/>
          </div>
          <div className="col-4 ">
            <img src={Feedback} alt="Feedback" className={classes.button} onClick={()=> feedbackFormClicked()}/>
          </div> */}
          <div className="col-4 ">
            <img src={InstructionFormDisabled} alt="InstructionForm" className={classes.button} /* onClick={()=> instructionFormClicked()} *//>
          </div>
          <div className="col-4 ">
            <img src={FeedbackFormDisabled} alt="Feedback" className={classes.button} /* onClick={()=> feedbackFormClicked()} *//>
          </div>
          <div className="col-4">
            <div className="dropdown2">
              <img src={dropdownBG} alt="bg" className={classes.dropdownborder} />
              <select value={gameDuration} onChange={(e) => retrieveInfoFromInput('gameduration', e.target.value)}>
                <option value="60">60 MIN</option>
                <option value="90">90 MIN</option>
                <option value="120">120 MIN</option>
                <option value="180">180 MIN</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-4">
            <div className="dropdown2">
              <img src={dropdownBG} alt="bg" className={classes.dropdownborder} />
              <select value={selectEscapeRoom} onChange={(e) => retrieveInfoFromInput('escaperoom', e.target.value)}>
                <option>SELECT ESCAPE ROOM*</option>
                {escaperoomFromAPI.map(escaperoom => <option key={escaperoom.id} value={escaperoom.id}>{escaperoom.name}</option>)}
              </select>
            </div>
          </div>
          {corporatetype == 'PREMIUM' ?
            <div className="col-4" onClick={() => dispatch (push('/corporate/authoringplatform', editData.id))}>
              <img src={EscapeRoomAuthoring} alt="EscapeRoomAuthoring" className={classes.button} />
            </div>
            :
            <div className="col-4">
              <img src={EscapeRoomAuthoringDisabled} alt="EscapeRoomAuthoring" className={classes.button} />
            </div>
          }
          <div className="col-4">
            {customToggleButton(toggleOption)}
          </div>
        </div>
      </div>
      <input autoComplete="off" type="file" id="instructionform" onChange={(e)=> uploadInstructionForm(e)} accept="pdf/.pdf" className={classes.hideInput}/>
      <input type="file" id="feedbackform" onChange={(e)=> uploadFeedbackForm(e)} accept="pdf/.pdf" className={classes.hideInput}/>
      <div className={classes.pageBottom}>
        <div className={classes.backBtn} onClick={() => dispatch(push('/corporate/courselisting'))}>
          <img src={BackSymbol} alt="BackSymbol" />
          <p>BACK</p>
        </div>
        <div className={classes.saveBtn} onClick={() => saveButtonClicked()}>
          <img src={SaveSymbol} alt="BackSymbol" />
          <p>SAVE</p>
        </div>
      </div>
      <div className={classes.information} onClick={() => window.open('https://www.createscape.com.sg/documents/corporate-account-user-manual.pdf')} >
        <img src={information}/>
      </div>
    </BaseLayoutAdjustable>
  );
}

export default CourseEdit;
