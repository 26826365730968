import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';

// Import Swiper styles
// import "swiper/swiper.min.css";
import 'assets/stylesheets/swiper.min.css'
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

// import "./styles.css";
import none from 'assets/platform/none.png'
import img_message from 'assets/platform/message.png'
import pdf from 'assets/platform/pdf.png'
import img_weblink from 'assets/platform/weblink.png'
import none_active from 'assets/platform/none_active.png'
import message_active from 'assets/platform/message_active.png'
import weblink_active from 'assets/platform/weblink_active.png'
import pdf_active from 'assets/platform/pdf_active.png'
import quizChoice from 'assets/platform/QuizChoice.png'
import quizChoiceActive from 'assets/platform/quizChoiceActive.png'
import quizText from 'assets/platform/QuizText.png'
import quizTextActive from 'assets/platform/quizTextActive.png'
import instruction1 from 'assets/mobile-instruction1.jpg'
import instruction2 from 'assets/mobile-instruction2.jpg'
import instruction3 from 'assets/mobile-instruction3.jpg'
import instruction4 from 'assets/mobile-instruction4.jpg'

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from "swiper/core";

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const useStyles = makeStyles((theme) => ({
  paramType: {
    // overflow: 'hidden',
    width: '8vh',
    float: 'left',
    paddingRight: '10px',
    cursor: 'pointer'
    // "& img:last-child": {
    //   paddingRight: '0px',
    // }
  },
  paramType2: {
    right: '1.5rem',
    width: '9vh',
    height: 'auto',
    position: 'relative',
    cursor: 'pointer'

  },
  paramType2Active: {
    right: '1.5rem',
    bottom: '1vh',
    width: '12vh',
    height: 'auto',
    position: 'relative',
    cursor: 'pointer'

  },
  paramType2NonActive: {
    width: '8.5vh',
    paddingTop: '0.4rem',
    cursor: 'pointer'
  },
  paramType3NonActive: {
    width: '12vh',
    paddingTop: '0.7rem',
    position: 'relative',
    right: '1.5rem',
    cursor: 'pointer'
  },
  paramContent: {
    width: '100%',
    marginTop: '5%',
  },
  paramMessage: {
    padding: '5% 6%',
    border: '1px solid #fff',
    height: '200px',
    "&>p": {
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
    },
    "& textarea": {
      background: 'transparent',
      width: '100%',
      height: '80%',
      color: '#fff',
      fontSize: 15,
      lineHeight: '1.5',
    }
  },
  paramWeblink: {
    "& > p": {
      color: theme.palette.primary.main,
    },
    "& > input": {
      backgroundColor: 'transparent',
      border: '1px solid #fff',
      width: '100%',
      height: '40px',
      color: '#fff',
      paddingLeft: 15

    }
  },
  mySwiper: {
    margin: '0rem',
    width: '100vw',
    height: '100vh',
    background: '#000000',

    '& swiper-button-next': {
      color: 'yellow'
    },
    '& element': {
      marginRight: '0'
    },
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      background: '#000000',
    }
  }
}));

export default function IconSwiper() {
  // const swiperRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeParam = useSelector((state: any) => state.props.activeParam)
  const paramType = (type: string) => {
    //dispatch(propsActions.activeParam(type))
  }

  return (
    <>
      <Swiper
        // ref={swiperRef}
        slidesPerView={1}
        centeredSlides={true}
        navigation={true}
        className={classes.mySwiper}
      >
        <SwiperSlide>
          <img src={instruction1}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={instruction2}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={instruction3}/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={instruction4}/>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
