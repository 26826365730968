// Notes
// tried to use the css file application.css to design a button that fits the description in the preview for corporate user but could not succeed, for now the best option is use the photo first 28 Apr 2021
// application.css in stylesheets folder in assests.
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { CallHistoryMethodAction, push } from 'connected-react-router'

import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';
import logo from 'assets/logo.png'
import information from 'assets/information.png'

import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'
import bg from 'assets/bg.png'
import 'assets/stylesheets/application.css';
import AccountInfoNew from 'components/AccountInfoNew'
import AccountInfoUser from 'components/AccountInfoUser'
import CapitalLandLogo from 'assets/CoporateUserOverView/CapitalLandLogo.png'
import yellowBgLong from 'assets/BaseImg/yellowBgLong.png'
import Anchor from 'assets/CoporateUserOverView/Anchor.png'
import darkBgLong from 'assets/BaseImg/darkBgLong.png'
import { template } from '@babel/core';

const useStyles = makeStyles(() => ({
  container1: {
    width: "100vw",
    height: "84vh",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  row1: {
    display: 'flex',
    flexDirection: 'column'
  },
  overview: {
        width:'250%',
        marginTop:'10px',
        minWidth:'150px',
        maxWidth:'170px',
        margin:'0 auto',
        cursor:'pointer',
        '& img' : {
            width:'250%',
        }
    },
  overviewHidden: {
    width: '250%',
    marginTop: '10px',
    minWidth: '150px',
    maxWidth: '170px',
    margin: '0 auto',
    opacity: 0,
    '& img': {
      width: '250%',
    }
  },
    headerBar : {
        padding:'40px 0px',
        display: 'flex',
        float:'right',
        color:'white',
        marginright:'20px',
        justifyContent: 'center',
        '& img': {
            height: '50%'
        },
        '& h4': {
            paddingRight: '6px',
            paddingTop: '9px',
            float:'right',
            fontFamily: 'impact'
        },
        '& h5': {
            fontFamily: 'impact'
        }
    },
  mainContainer: {
    display: 'flex',
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  topInfo: {
    width: "100vw",
    display: "flex",
    alignItems: "center",
    paddingLeft: "6rem",
    paddingRight: "6rem",
    justifyContent: "space-between",
  },
  logo: {
    width: '12rem'
  },
  containerOverViewData: {
  padding: "40px",
  marginLeft: '5rem',
  marginRight: '2rem',
  color: "white",
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  "& h4": {
    fontFamily: "Impact",
    margin: "10px",
  },
  '& h5':{
      fontFamily: "Impact",
      letterSpacing: "1px",
      marginBottom: '0rem'
    },
    '& row': {
      fontFamily: "Impact",
    }
  },
  hidden: {
    opacity: 0
  },
  inputWrapper: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingBottom: '2rem',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '2rem',
    }
  },
  inputWrapper1: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingBottom: '2rem',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '2rem',
      width: "21.5rem"
    }
  },
  inputWrapperHidden: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    paddingBottom: '2rem',
    opacity: 0,
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '2rem',
    }
  },
  temporary : {
    display: 'flex',
    flexDirection: 'column',
    width: "100vw",
    height: "85vh",
    justifyContent: "center",
  },
  information: {
    left: '5vw',
    width: '40px',
    bottom: '10vh',
    height: '40px',
    position: 'absolute',
    cursor: 'pointer',

    '& img': {
      width: '100%',
    }
  }
}));

const TraineeOverview = () => {
    const dispatch = useDispatch()
    const classes  = useStyles();
    const mydate = require('current-date')
  // retrieving data from reducer

  const accountTypeInfo = useSelector((state: any) => state.auth.loggedinuser.user.accounttype)
  const name = useSelector((state: any) => state.auth.loggedinuser.user.name)
  const id = useSelector((state: any) => state.auth.loggedinuser.user.id)
  const validsessions = useSelector((state: any) => state.auth.loggedinuser.user.traineeinfo.validsessions)
  const groupname = useSelector((state: any) => state.auth.loggedinuser.user.traineeinfo.groupname)
  const enddate = useSelector((state: any) => state.auth.loggedinuser.user.traineeinfo.enddate)
  const belongstouser = useSelector((state: any) => state.auth.loggedinuser.user.belongstouser)
  const [spareState, setSpareState] = React.useState('nothing')
  const courseId = 'ac27d963-a722-40da-8330-f7ad179f41e6'

  // session id
  const accessToken = useSelector((state: any) => state.auth.loggedinuser.token.accessToken)
  // let sessionIdComponent1 = id.substring(0, 13)
  // let sessionIdComponent2 = accessToken.substring(0, 15)
  // function getRandomString(length: any) {
  //   var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   var result = '';
  //   for (var i = 0; i < length; i++) {
  //     result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  //   }
  //   return result;
  // }
  // let randomcode = getRandomString(10)
  // let sessionId = `session-${sessionIdComponent1}-${sessionIdComponent2}-${randomcode}`

  // const Validation = async () => {
  //   const req = {
  //     "session": sessionId,
  //     "user": id,
  //     "course": 'sample course',
  //     "logo": 'sample logo',
  //     "props": 'sample props',
  //     "token": accessToken
  //   }
  //   const DataOfUser = await axios({
  //     method: 'post',
  //     // url: process.env.REACT_APP_BACKEND_URL + '/props/',
  //     url: 'https://museum.createscape.com.sg/validate',
  //     headers: {
  //       "content-type": "application/json",
  //       "Authorization": "Bearer "+accessToken
  //     },
  //     data: req
  //   })
  //   return DataOfUser;
  // }

  function temp() {
    let x = 1
  }

  const getTraineeExtraInfo = async () => {
    const DataOfUser = await axios({
      method: 'get',
      // url: process.env.REACT_APP_BACKEND_URL + '/props/',
      url: `https://museum.createscape.com.sg/traineeinfo/${id}`,
      headers: {
        "content-type": "application/json",
        "Authorization": "Bearer "+accessToken
      },
    })
    return DataOfUser;
  }

  const getTraineeLogo = async () => {
    console.log(belongstouser);
    const DataOfUser = await axios({
      method: 'get',
      // url: process.env.REACT_APP_BACKEND_URL + '/props/',
      url: `https://museum.createscape.com.sg/corporateinfo/${belongstouser}`,
      headers: {
        "content-type": "application/json",
        "Authorization": "Bearer "+accessToken
      },
    })
    return DataOfUser.data.logo;
  }

  const [companyname, setcompanyname] = useState('NIL')
  const [coporateValidSession, setcoporateValidSession] = useState('NIL')
  const getCoporateInfo = async () => {
    const DataOfUser = await axios({
      method: 'get',
      // url: process.env.REACT_APP_BACKEND_URL + '/props/',
      url: 'https://museum.createscape.com.sg/corporateinfo?page=1&take=80',
      headers: {
        "content-type": "application/json",
        "Authorization": "Bearer "+accessToken
      },
    })
    let dataset = DataOfUser.data.data
    dataset.map((data: any)=> {
      if (data.user.id === id) {
        setcompanyname(data.companyname)
        setcoporateValidSession(data.validsessions)
      } else {
        temp()
      }
    })
    return DataOfUser;
  }

  const [isItStartOfPage, setisItStartOfPage] = useState(true)

  useEffect(() => {
    console.log(accountTypeInfo);
    if (isItStartOfPage === true) {
      switch (accountTypeInfo) {
        case 'TRAINEE':
          if (accountTypeInfo === 'TRAINEE') {
            getTraineeExtraInfo()
          } else {
            temp()
          }
        break;
        case 'CORPORATE':
          getCoporateInfo()
          break;
      default:
        temp()
      }
      if (accountTypeInfo === 'TRAINEE') {
        getTraineeExtraInfo()
      } else {
        temp()
      }
      setisItStartOfPage(false)
    } else {
      temp()
    }
    // store(`userid: ${id}, name: ${name}, account type: ${accountTypeInfo}, courseid: ${courseId}, sessionid: ${sessionId}`)
    // SentDataToGameResult()
    // getUserData()
    // getUserDataforRecordsTable()

  })

  const traineeStartGame = () => {
    dispatch(push("/trainee/startsession"))
  }
  const traineeUserPage = ()=> {
    dispatch(push('/corporate/traineelisting'))
  }

  const traineeGameRecords = () => {
    dispatch(push('/trainee/performancerecords'))
  }

    const AccountType = (type: string) => {
      switch(type) {
        case 'CORPORATE':
          return(
            <BaseLayoutAdjustable bg={bg}>
              <div className={classes.mainContainer}>
                <div className={classes.topInfo}>
                  <img src={logo} alt="logo" className={classes.logo} />
                  <div className={classes.containerOverViewData}>
                    <h5>{companyname}</h5>
                    <h4> | </h4>
                    <h4>{type} ACCOUNT</h4>
                    <h4> | </h4>
                    {/* <img src={CapitalLandLosgo} alt="CapitalLandLogo" />
                    <h4> | </h4> */}
                    {/* <h4>Package 1</h4>
                    <h4> | </h4> */}
                    <h4>Remaining Session: {coporateValidSession}</h4>
                    <h4> | </h4>
                    <h4>{mydate('date')}</h4>
                  </div>
                  <AccountInfoUser name={name} />
                  {/* <AccountInfoUser name="TEST" /> */}
                </div>
                <div className={classes.container1}>
                  <div className={classes.row1}>
                    {/* <div className={classes.inputWrapper} onClick={() => dispatch(push('/'))}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>CORPORATE PROFILE SETUP</p>
                    </div> */}
                    <div className={classes.inputWrapper} onClick={() => dispatch(push('/corporate/profilesetup'))}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>CORPORATE PROFILE SETUP</p>
                    </div>
                    <div className={classes.inputWrapper}>
                      <img src={darkBgLong} alt="bg" />
                      <p>COURSE MANAGER MANAGEMENT</p>
                    </div>
                    {/* <div className={classes.inputWrapper} onClick={() => dispatch(push('/coursemanagermanagement'))}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>COURSE MANAGER MANAGEMENT</p>
                    </div> */}
                    {/* <div className={classes.inputWrapper} onClick={() => traineeUserPage()}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>TRAINEE MANAGEMENT</p>
                    </div> */}
                  </div>
                  <div className={classes.row1}>
                    <div className={classes.inputWrapper}>
                      <img src={darkBgLong} alt="bg" />
                      <p>COURSE TYPE MANAGEMENT</p>
                    </div>
                    {/* <div className={classes.inputWrapper} onClick={() => dispatch(push('/coursetypemanagement'))}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>COURSE TYPE MANAGEMENT</p>
                    </div> */}
                    <div className={classes.inputWrapper} onClick={() => traineeUserPage()}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>TRAINEE MANAGEMENT</p>
                    </div>
                    {/* <div className={classes.inputWrapper1} onClick={() => dispatch(push('/performancerecords'))}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>PAST TRAINING SESSIONS PERFORMANCE RECORDS</p>
                    </div> */}
                    {/* <div className={classes.inputWrapperHidden}>
                      <img src={yellowBgLong} alt="bg" />
                    </div> */}
                  </div>
                </div>
              </div>
            </BaseLayoutAdjustable>
          )
        case 'MANAGER':
          return (
            <BaseLayoutAdjustable bg={bg}>
              <div className={classes.mainContainer}>
                <div className={classes.topInfo}>
                  <img src={logo} alt="logo" className={classes.logo} />
                  <div className={classes.containerOverViewData}>
                    <h5>International SOS</h5>
                    <h4> | </h4>
                    <h4>{type} ACCOUNT</h4>
                    <h4> | </h4>
                    {/* <img src={CapitalLandLogo} alt="CapitalLandLogo" />
                    <h4> | </h4> */}
                    <h4>Package 1</h4>
                    <h4> | </h4>
                    <h4>Remaining Session: 5</h4>
                    <h4> | </h4>
                    <h4>{mydate('date')}</h4>
                  </div>
                  <AccountInfoUser name={name} />
                  {/* <AccountInfoUser name="TEST" /> */}
                </div>
                <div className={classes.container1}>
                  <div className={classes.row1}>
                    <div className={classes.inputWrapper} onClick={() => dispatch(push('/corporate/profilesetup'))}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>CORPORATE PROFILE SETUP</p>
                    </div>
                    {/* <div className={classes.inputWrapper} onClick={() => dispatch(push('/coursemanagermanagement'))}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>COURSE MANAGER MANAGEMENT</p>
                    </div> */}
                    <div className={classes.inputWrapper} onClick={() => dispatch(push('/playgallery'))}>
                      <img src={darkBgLong} alt="bg" />
                      <p>ENTER TRAINING COURSE</p>
                    </div>
                    <div className={classes.inputWrapper} onClick={() => dispatch(push('/corporate/traineelisting'))}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>TRAINEE MANAGEMENT</p>
                    </div>
                  </div>
                  <div className={classes.row1}>
                    {/* <div className={classes.inputWrapper} onClick={() => dispatch(push('/coursegroupmanagement'))}>
                      <img src={darkBgLong} alt="bg" />
                      <p>COURSE GROUP MANAGEMENT</p>
                    </div> */}
                    <div className={classes.inputWrapper}>
                      <img src={darkBgLong} alt="bg" />
                      <p>COURSE GROUP MANAGEMENT</p>
                    </div>
                    {/* <div className={classes.inputWrapper1} onClick={() => dispatch(push('/performancerecords'))}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>PAST TRAINING SESSIONS PERFORMANCE RECORDS</p>
                    </div> */}
                    <div className={classes.inputWrapperHidden}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>TRAINEE MANAGEMENT</p>
                    </div>
                  </div>
                </div>
              </div>
            </BaseLayoutAdjustable>
          )
        case 'TRAINEE':
          return (
            <BaseLayoutAdjustable bg={bg}>
              <div className={classes.mainContainer}>
                <div className={classes.topInfo}>
                  <img src={logo} alt="logo" className={classes.logo} />
                  <div className={classes.containerOverViewData}>
                    {/* <h5>JOHN_DOE123</h5>
                    <h4> | </h4> */}
                    {/* <h4>{name}</h4> */}
                    {/* <h4>testUser</h4> */}
                    {/* <h4> | </h4> */}
                    <h4>{groupname}</h4>
                    <h4> | </h4>
                    {/* <h4>testGroup</h4> */}
                    <h4>{type} ACCOUNT</h4>
                    {/* <h4> | </h4>
                    <h4>COURSE 1</h4> */}
                    <h4> | </h4>
                    <h4>Remaining Session: {validsessions}</h4>
                    <h4> | </h4>
                    <h4>Expiry Date: {enddate?.replace(/T.*/,'').split('-').reverse().join('/')}</h4>
                  </div>
                  {/* <AccountInfoNew /> */}
                  <AccountInfoUser name={name}/>
                </div>
                <div className={classes.container1}>
                  <div className={classes.row1}>
                    <div className={classes.inputWrapper} onClick={() => dispatch(push('/trainee/profilesetup'))} >
                      <img src={yellowBgLong} alt="bg" />
                      <p>USER PROFILE SETUP</p>
                    </div>
                    <div className={classes.inputWrapper} onClick={() => traineeStartGame()}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>ENTER TRAINING COURSE</p>
                    </div>
                    <div className={classes.inputWrapper1} onClick={() => traineeGameRecords()}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>PAST TRAINING SESSIONS PERFORMANCE RECORDS</p>
                    </div>
                  </div>
                </div>
              </div>
            </BaseLayoutAdjustable>
          )
        case 'playGame':
          return (
            <BaseLayoutAdjustable bg={bg}>
              <div className={classes.mainContainer}>
                <div className={classes.topInfo}>
                  <img src={logo} alt="logo" className={classes.logo} />
                  <div className={classes.containerOverViewData}>
                    <h5>USER1_123</h5>
                    <h4> | </h4>
                    <h4>USER 1</h4>
                    <h4> | </h4>
                    <h4>USER 1 GROUP</h4>
                    <h4> | </h4>
                    <h4>COURSE 1</h4>
                    <h4> | </h4>
                    <h4>Remaining Session: 5</h4>
                    <h4> | </h4>
                    <h4>{mydate('date')}</h4>
                  </div>
                  <AccountInfoUser name={name} />
                </div>
                <div className={classes.container1}>
                  <div className={classes.row1}>
                    {/* <div className={classes.inputWrapper} onClick={() => dispatch(push('/playgallery'))} > */}
                    {/* <div className={classes.inputWrapper} onClick={() => window.open('https://www.createscape.com.sg/staging/playgallery/index.html')}>\ */}
                    <div className={classes.inputWrapper} onClick={() => window.open('https://www.createscape.com.sg/login/games/escaperoom/index.html')}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>INTERNATIONAL SOS WONDERS</p>
                    </div>
                    {/* <div className={classes.inputWrapper} onClick={() => dispatch(push('/playpyramid'))}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>PYRAMID</p>
                    </div>
                    <div className={classes.inputWrapper} onClick={() => dispatch(push('/playcontrolroom'))}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>CONTROL ROOM</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </BaseLayoutAdjustable>
          )
        case 'oldversion':
          return (
            <BaseLayoutAdjustable bg={bg}>
              <div className={classes.mainContainer}>
                <div className={classes.topInfo}>
                  <img src={logo} alt="logo" className={classes.logo} />
                  <div className={classes.containerOverViewData}>
                    <h5>International SOS</h5>
                    <h4> | </h4>
                    <h4>{type} ACCOUNT</h4>
                    <h4> | </h4>
                    {/* <img src={CapitalLandLosgo} alt="CapitalLandLogo" />
                    <h4> | </h4> */}
                    <h4>Package 1</h4>
                    <h4> | </h4>
                    <h4>Remaining Session: 5</h4>
                    <h4> | </h4>
                    <h4>{mydate('date')}</h4>
                  </div>
                  <AccountInfoUser name={name} />
                  {/* <AccountInfoUser name="TEST" /> */}
                </div>
                <div className={classes.container1}>
                  <div className={classes.row1}>
                    <div className={classes.inputWrapper} onClick={() => dispatch(push('/corporate/profilesetup'))}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>CORPORATE PROFILE SETUP</p>
                    </div>
                    <div className={classes.inputWrapper} onClick={() => dispatch(push('/coursemanagermanagement'))}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>COURSE MANAGER MANAGEMENT</p>
                    </div>
                    <div className={classes.inputWrapper} onClick={() => traineeUserPage()}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>TRAINEE MANAGEMENT</p>
                    </div>
                  </div>
                  <div className={classes.row1}>
                    <div className={classes.inputWrapper} onClick={() => dispatch(push('/coursegroupmanagement'))}>
                      <img src={darkBgLong} alt="bg" />
                      <p>COURSE GROUP MANAGEMENT</p>
                    </div>
                    {/* <div className={classes.inputWrapper1} onClick={() => performanceRecordsPage()}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>PAST TRAINING SESSIONS PERFORMANCE RECORDS</p>
                    </div> */}
                    <div className={classes.inputWrapperHidden}>
                      <img src={yellowBgLong} alt="bg" />
                    </div>
                  </div>
                </div>
              </div>
            </BaseLayoutAdjustable>
          )
        case 'ADMIRAL':
          return (
            <BaseLayoutAdjustable bg={bg}>
              <div className={classes.mainContainer}>
                <div className={classes.topInfo}>
                  <img src={logo} alt="logo" className={classes.logo} />
                  {/* <div className={classes.containerOverViewData}>
                    <h4>{type} ACCOUNT</h4>
                    <h4> | </h4>
                    <h4>{name} GROUP 1</h4>
                    <h4> | </h4>
                    <h4>COURSE 1</h4>
                    <h4> | </h4>
                    <h4>Remaining Session: 5</h4>
                    <h4> | </h4>
                    <h4>{mydate('date')}</h4>
                  </div> */}
                  <AccountInfoUser name={name} />
                </div>
                <div className={classes.container1}>
                  <div className={classes.row1}>
                    <div className={classes.inputWrapper} onClick={() => dispatch(push('/corporate'))} >
                      <img src={yellowBgLong} alt="bg" />
                      <p>CORPORATE ACCOUNT MANAGEMENT</p>
                    </div>
                    <div className={classes.inputWrapper} onClick={() => dispatch(push('/agentmanagement'))}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>AGENTS /RESELLER MANAGEMENT</p>
                    </div>
                  </div>
                </div>
              </div>
            </BaseLayoutAdjustable>
          )
        default:
          return (
            <h1>No valid account type given</h1>
          )
      }
    }

    return (
      <div>
        {/* {AccountType(accountTypeInfo)} */}
        <BaseLayoutAdjustable bg={bg}>
              <div className={classes.mainContainer}>
                <div className={classes.topInfo}>
                  <img src={logo} alt="logo" className={classes.logo} />
                  <div className={classes.containerOverViewData}>
                    {/* <h5>JOHN_DOE123</h5>
                    <h4> | </h4> */}
                    {/* <h4>{name}</h4> */}
                    {/* <h4>testUser</h4> */}
                    {/* <h4> | </h4> */}
                    <h4>{groupname}</h4>
                    <h4> | </h4>
                    {/* <h4>testGroup</h4> */}
                    <h4>TRAINEE ACCOUNT</h4>
                    {/* <h4> | </h4>
                    <h4>COURSE 1</h4> */}
                    <h4> | </h4>
                    <h4>Remaining Session: {validsessions}</h4>
                    <h4> | </h4>
                    <h4>Expiry Date: {enddate?.replace(/T.*/,'').split('-').reverse().join('/')}</h4>
                  </div>
                  {/* <AccountInfoNew /> */}
                  <AccountInfoUser name={name}/>
                </div>
                <div className={classes.container1}>
                  <div className={classes.row1}>
                    <div className={classes.inputWrapper} onClick={() => dispatch(push('/trainee/profilesetup'))} >
                      <img src={yellowBgLong} alt="bg" />
                      <p>USER PROFILE SETUP</p>
                    </div>
                    <div className={classes.inputWrapper} onClick={() => traineeStartGame()}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>ENTER TRAINING COURSE</p>
                    </div>
                    <div className={classes.inputWrapper1} onClick={() => traineeGameRecords()}>
                      <img src={yellowBgLong} alt="bg" />
                      <p>PAST TRAINING SESSIONS PERFORMANCE RECORDS</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.information} onClick={() => window.open('https://www.createscape.com.sg/documents/trainee-account-user-manual.pdf')} >
                <img src={information}/>
              </div>
            </BaseLayoutAdjustable>
        {/* {AccountType("CORPORATE")} */}
      </div>
    )
}

export default TraineeOverview;
function dispatch(arg0: CallHistoryMethodAction<[string, unknown?]>) {
  throw new Error('Function not implemented.');
}

