import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import { makeStyles } from '@material-ui/core/styles'
import modal from 'actions/modal'
import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'
import TextFieldPassword1 from 'components/Inputs/TextFieldPassword1'

// import bg from 'assets/CoporateUserOverView/CoporateUserOverviewBG.png'
import bg from 'assets/bg.png'
import yellowBG from 'assets/BaseImg/yellowBgLong.png'
import darkBgLong from 'assets/BaseImg/darkBgLong.png'
import yellowBGwithDesign from 'assets/BaseImg/yellowBgwithDesign.png'
import textbg from 'assets/textfield.svg'
import dropdownBG from 'assets/InputSmall.svg'
import headerlogo from 'assets/logo.png'
// import BackBtn from 'assets/TraineeUserManagement/TraineeUserAccountManagementBackBtnpng.png'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import SaveSymbol from 'assets/BaseImg/saveSymbol.png'

import 'assets/stylesheets/application.css'
// import Slider1 from 'components/Slider/Slider1'
import Slider1 from 'components/Slider/Slider2'
import { yellow } from '@material-ui/core/colors'
import AccountInfoNew from 'components/AccountInfoNew'
import AccountInfoUser from 'components/AccountInfoUser'
import classes from '*.module.css'
import information from 'assets/information.png'

import axios from 'axios';

const useStyles = makeStyles(() => ({
  login: {
    width: '100%',
    marginTop: '10px',
    minWidth: '150px',
    maxWidth: '170px',
    margin: '0 auto',
    cursor: 'pointer',
    '& img': {
      width: '100%',
    }
  },
  requiredfield: {
    color: "#FFD84C",
    fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    letterSpacing: '1px',
    paddingBottom: '1rem',
  },
  //back and save buttons style
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
      cursor: 'pointer'
    }
  },
  saveBtn: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3rem',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
  pageBottom: {
    display: 'flex',
    position: 'fixed',
    bottom: "1rem",
    right: "2rem",
  },
  // page top
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '12rem'
  },
  container5: {
    width: "100vw",
    height: "84vh",
    position: "absolute",
    textAlign: "center",
    marginTop: "7vh"
  },
  header: {
    fontFamily: "Impact",
    fontSize: "2rem",
    letterSpacing: "4px",
    paddingBottom: "2rem",
    color: '#FFFFFF'
  },
  // text input field
  input: {
    background: 'transparent',
    position: 'absolute',
    border: 'none',
    textAlign: 'center',
    color: '#ffd84d',
    fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    fontSize: '1.2rem',
    letterSpacing: '1px',

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  Displayinput: {
    // backgroundImage:`url(${bg})`,
    background: 'transparent',
    position: 'absolute',
    border: 'none',
    textAlign: 'center',
    color: '#ffd84d',
    fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
  },
  inputWrapperDropDown: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    fontSize: '1.2rem',
    letterSpacing: '1px',

    '& select': {
      position: "absolute",
      background: "none",
      border: "none",
      color: "#FFD60F",
      fontFamily: "Impact",
      letterSpacing: '1px',
    }
  },
  inputWrapperHidden: {
    position: 'relative',
    opacity: 0,
    img: {
      opacity: 0,
      display: "none"
    }
  },
  // for the column
  container6: {
    // width: "100vw",
    // height: "71vh",
    display: "flex",
    // position: "absolute",
    textAlign: "center",
    justifyContent: "center",
    marginTop: "1rem"
  },
  column1: {
    display: "flex",
    flexDirection: "column",
    width: "25vw",
  },
  // dropdown bar
  dropdownborder: {
    width: '100%',
  },
  // download instruction button
  downloadInstructions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& img': {
      width: '100%'
    },
    '& p': {
      position: 'fixed',
      fontFamily: 'impact',
      letterSpacing: '1px',
      color: '#FFFFFF',
      fontSize: '1.2rem'
    }
  },
  // start button holder
  startButtonHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '5rem',
    cursor: 'pointer',
    '& img': {
      width: '15vw'
    },
    '& p': {
      position: 'fixed',
      fontFamily: 'impact',
      letterSpacing: '1px',
      color: '#FFFFFF',
      fontSize: '2vw',
      marginBottom: "0px"
    }
  },
  information: {
    left: '5vw',
    width: '40px',
    bottom: '10vh',
    height: '40px',
    position: 'absolute',
    cursor: 'pointer',

    '& img': {
      width: '100%',
    }
  }
}));

const StartTrainingCourseSession = () => {
  const dispatch = useDispatch()
  const classes = useStyles();
  const [displayCriteria, setdisplayCriteria] = useState('default')
  const [logo, setLogo] = useState('default')
  // receiving from input
  const [traineeName, settraineeName] = useState('empty')
  const [coursetype, setcoursetype] = useState('empty')
  const [traineeGroup, settraineeGroup] = useState('empty')
  const [courseDescription, setcourseDescription] = useState('empty')
  const [selectedCourseId, setselectedCourseId] = useState('empty')

  const [toStop, setToStop] = useState(false)
  const temp = () => {
    let x = 1
  }
  useEffect(() => {
    getTraineeLogo();
    if (toStop === false) {
      getCourse()
      setToStop(true)
    } else {
      temp()
    }
  });

  // axios
  let tableData: { name: string; id: string; }[] = []
  const [courseTypeFromAPI, setcourseTypeFromAPI] = useState(tableData)
  // const getCourseType = async () => {
  //   const DataOfUser = await axios({
  //     method: 'get',
  //     url: 'https://museum.createscape.com.sg/coursetype?page=1&take=80',
  //     headers: {
  //       "content-type": "application/json",
  //       "Authorization": "Bearer "+accessToken
  //     }
  //   })
  //   let datacollected = DataOfUser.data.data
  //   setcourseTypeFromAPI(datacollected)
  //   return DataOfUser;
  // }
  let courseNameAndIDObject: {name: any; id: any;}[] = []
  let testArray: { name: any; id: any; }[] = []
  const [courseNameAndIDStorage, setcourseNameAndIDStorage] = useState(courseNameAndIDObject)
  const getCourse = async () => {
    const DataOfUser = await axios({
      method: 'get',
      url: 'https://museum.createscape.com.sg/traineecourses/' + id,
      headers: {
        "content-type": "application/json",
      }
    })

    let datacollected = DataOfUser.data.data

    datacollected.forEach((data: any)=> {
      gather({
        id: data?.course?.id,
        name: data.name
      })
    })
    function gather(object: any) {
      testArray.push(object)
    }
    setcourseNameAndIDStorage(testArray)
    return DataOfUser;
  }
  const [selectedCourseType, setselectedCourseType] = useState({id: "1", name: 'COURSE TYPE'})
  const [selectedDescription, setselectedDescription] = useState('COURSE DESCRIPTION')
  const [selectedEscapeRoomId, setselectedEscapeRoomId] = useState('NIL')
  const [getDuration, setDuration] = useState('')
  const getTargertedCourseData = async (id: any, input: any) => {

    if(input == 'SELECT ASSIGNED COURSE*'){
      setselectedCourseType({id: '1', name: 'COURSE TYPE'})
      setselectedDescription('COURSE DESCRIPTION')
      return false
    } else {
      const DataOfUser = await axios({
        method: 'get',
        url: `https://museum.createscape.com.sg/course/${id}`,
        headers: {
          "content-type": "application/json",
        }
      })
      DataOfUser.data.data.forEach((data: any) => {
        if(data.id == input){
          setselectedCourseType({id: data?.coursetype?.id, name: data?.coursetype?.name})
          setselectedDescription(data.description)
          setselectedEscapeRoomId(data.escaperoom.id)
          setDuration(data.duration);
        }
      });
      return DataOfUser;
    }
  }
  let propslink = `https://museum.createscape.com.sg/props/${selectedCourseId}`
  // validation
  const Validation = async () => {
    const req = {
      "session": sessionId,
      "user": id,
      "course": selectedCourseId,
      "logo": logo,
      "props": propslink,
      "token": accessToken
    }
  }

  // retrieving data from reducer
  const accountTypeInfo = useSelector((state: any) => state.auth.loggedinuser.user.accounttype)
  const name = useSelector((state: any) => state.auth.loggedinuser.user.name)
  const id = useSelector((state: any) => state.auth.loggedinuser.user.id)
  const belongstouser = useSelector((state: any) => state.auth.loggedinuser.user.belongstouser)
  const validsessions = useSelector((state: any) => state.auth.loggedinuser.user.traineeinfo.validsessions)
  const accessToken = useSelector((state: any) => state.auth.loggedinuser.token.accessToken)
  // const sessionid = useSelector((state: any) => state.corporate.GeneratedSessionId)
  let sessionIdComponent1 = id.substring(0, 13)
  let sessionIdComponent2 = accessToken.substring(0, 15)
  function getRandomString(length: any) {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
  let randomcode = getRandomString(10)
  let sessionId = `session-${sessionIdComponent1}-${sessionIdComponent2}-${randomcode}`

  // start game
  const startGame = () => {
    if(selectedCourseType.name != "COURSE TYPE"){
      if(validsessions > 0){
        Validation()
        window.open(`https://www.createscape.com.sg/login/games/escaperoom/index.html?sessionid=${sessionId}&userid=${id}&username=${name}&course=${selectedCourseId}&logo=${logo}&props=${propslink}&duration=${getDuration}`)
      } else {
        dispatch(modal.show.started({type: 'noSessionRemaining', source: null}))
      }
    } else {
      dispatch(modal.show.started({type: 'selectAssignedCourse', source: null}))
    }
  }

  const getTraineeLogo = async () => {
    const DataOfUser = await axios({
      method: 'get',
      url: `https://museum.createscape.com.sg/corporateinfo/${belongstouser}`,
      headers: {
        "content-type": "application/json",
      },
    })

    setLogo(DataOfUser.data.data[0].logo);
  }

  const traineeNameInput = (inputValue: any) =>  {
    settraineeName(inputValue)
  }

  const assignedCourseInput = (inputValue: any) => {
    setselectedCourseId(inputValue)
    getTargertedCourseData(belongstouser, inputValue)
    //getTargertedEscapeData(inputValue)
    setcoursetype(inputValue)
  }

  const coursetypeInput = (inputValue: any) =>  {
    settraineeGroup(inputValue)
  }

  const courseDescriptionInput = (inputValue: any) => {
    setcourseDescription(inputValue)
  }

  // save button
  const saveButton = () => {
    dispatch(modal.show.started({type: 'startTrainingCourseSession', source: ''}))
    // console.log(traineeName)
    // console.log(coursetype)
    // console.log(traineeGroup)
    // console.log(courseDescription)
  }

  // assinged course input
  const AssignedCourseinput = () => {

    return (
      <select id='assignedCourse' onChange={(e) =>assignedCourseInput(e.target.value)}>
        <option>SELECT ASSIGNED COURSE*</option>
        {courseNameAndIDStorage.map(course => <option key={course.id} value={course.id}>{course.name}</option>)}
      </select>
      )
    }

    const downloadInstructionClicked = () => {
      alert('instruction download link is not ready')
    }

  const mainDisplay = (value: any) => {
    switch (value) {
      case 'waiting':
        return(
          <BaseLayoutAdjustable bg={bg}>
            <h1>Please Wait</h1>
          </BaseLayoutAdjustable>
        )
      default:
        return (
          <BaseLayoutAdjustable bg={bg}>
            <div className={classes.topInfo}>
              <img src={headerlogo} alt="logo" className={classes.logo} />
              <AccountInfoUser name={name} />
            </div>
            <div className={classes.container5}>
              <div className="row justify-content-center">
                <div className="col-6">
                  <h3 className={classes.header}>START TRAINING COURSE SESSION</h3>
                </div>
              </div>
              <div className="row justify-content-center pb-3">
                <div className="col-6">
                  <h4 className={classes.requiredfield}>* INDICATES REQUIRED FIELD</h4>
                </div>
              </div>
              <div className={classes.container6}>
                <div className={classes.column1}>
                  <div className={classes.inputWrapper}>
                    <img src={textbg} alt="bg" />
                    <p className={classes.input}>{name}</p>
                  </div>
                  <div className={classes.inputWrapperDropDown}>
                    <img src={textbg} alt="bg" />
                    {AssignedCourseinput()}
                  </div>
                  <div className={classes.downloadInstructions} /* onClick={() => downloadInstructionClicked()} */>
                    <img src={darkBgLong} alt="bg" />
                    <p>DOWNLOAD INSTRUCTIONS</p>
                  </div>
                </div>
                <div className={classes.column1}>
                  <div className={classes.inputWrapper}>
                    <img src={textbg} alt="bg" />
                    <p className={classes.input}>{selectedCourseType.name}</p>
                  </div>
                  <div className={classes.inputWrapper}>
                    <img src={textbg} alt="bg" />
                    <p className={classes.input}>{selectedDescription}</p>
                  </div>
                  <div className={classes.inputWrapperHidden}>
                    <img src={textbg} alt="bg" />
                  </div>
                </div>
              </div>
              <div className={classes.startButtonHolder} onClick={() => startGame()}>
                <p>START</p>
                <img src={yellowBGwithDesign} alt="yellowBGwithDesign" />
              </div>
            </div>
            <div className={classes.pageBottom}>
              <div className={classes.backBtn} onClick={() => dispatch(push('/trainee/overview'))}>
                <img src={BackSymbol} alt="BackSymbol" />
                <p>BACK</p>
              </div>
             {/*  <div className={classes.saveBtn} onClick={() => saveButton()}>
                <img src={SaveSymbol} alt="BackSymbol" />
                <p>SAVE</p>
              </div> */}
            </div>
            <div className={classes.information} onClick={() => window.open('https://www.createscape.com.sg/documents/trainee-account-user-manual.pdf')} >
              <img src={information}/>
            </div>
          </BaseLayoutAdjustable>
        )
    }
  }

  return (
    mainDisplay(displayCriteria)
  )
}

export default StartTrainingCourseSession;
