import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BaseLayoutAdjustable from '../components/form/BaseLayoutGallery'
import bg from 'assets/PlatformHome/AuthoringPlatformBG1.png'
import { makeStyles } from '@material-ui/core/styles';
import Iframe from 'react-iframe';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import propsNew from 'actions/exhibits';
import modal from 'actions/modal';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '40px'
  },
  iframeStyle : {
      width: '100%',
      height: '100vh',
      border: 'none',
      position: 'absolute',
      top: 0,
      zIndex: -1
  },
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '100px',
    float: 'left',
    marginTop: '20px',
    '& img': {
      width: '100%',
    }
  },
  headerBar: {
    color: 'white',
    float: 'left',
    marginRight: 'auto',
    marginTop: '12px',
    marginLeft: '15px',
    '& p': {
      padding: '6px',
      float: 'left',
      textTransform: 'uppercase',
      fontFamily: 'Impact',
    }
  },
  btnSave: {
    marginLeft: '30px',
    position: 'fixed',
    left: '0px',
    bottom: 30,
    cursor: 'pointer',
  },
  nextPrevious: {
    width: '21vh'
  },
}));

const PlatformGallery = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  function ModelLoadedGallery() {
    window.removeEventListener("message", getMessageGallery);
    window.addEventListener("message", getMessageGallery);
  }

  function getMessageGallery(message: any) {
    if(message.data.location != undefined){
      console.log(message.data.location);
      dispatch(propsNew.getpropsbycourseidandlocationid.started({courseid: 'fd6e67c2-fcdb-4d9e-99f4-5a8e4d329685', locationid: message.data.location, timer: 0}))
    }

    if(message.data.gallery != undefined) {
      let tmpstr = message.data.gallery[message.data.gallery.length - 1];
      dispatch(modal.show.started({type: 'showArtist', source: tmpstr}))
    }

    if(message.data.artistdetail != undefined) {
      let tmpstr = message.data.artistdetail[message.data.artistdetail.length - 1];
      dispatch(modal.show.started({type: 'showArtistDetail', source: tmpstr}))
    }

    if(message.data.directory != undefined) {
      dispatch(modal.show.started({type: 'showDirectory', source: ''}))
    }

    if(message.data.extras != undefined) {
      if(message.data.extras == 'info') {
        dispatch(modal.show.started({type: 'showInfo', source: ''}));
      } else if (message.data.extras == 'fullscreen') {
        document.documentElement.requestFullscreen();
      } else if (message.data.extras == 'exitfullscreen') {
        document.exitFullscreen();
      }
    }
  }

  return (
    <BaseLayoutAdjustable bg={bg}>
        {/* <div className={classes.topInfo}>
          <img src={logo} alt="logo" className={classes.logo} />
          <div className={classes.headerBar}>
            <p>{course.data[0] == undefined ? 'COURSE NAME' : course.data[0].name }</p>
            <p>|</p>
            <p>{course.data[0] == undefined ? 'ESCAPEROOM' : course.data[0].escaperoom.name }</p>
          </div>
          <AccountInfoNew />
        </div> */}
        {/* <div className={classes.header}>
          <div className={classes.logo}>
            <img src={logo} alt="logo" />
          </div>
          <div className={classes.headerBar}>
            <p>{course.data[0] == undefined ? 'COURSE NAME' : course.data[0].name }</p>
            <p>|</p>
            <p>{course.data[0] == undefined ? 'ESCAPEROOM' : course.data[0].escaperoom.name }</p>
            <p>|</p>
            <p>GALLERY</p>
          </div>
        </div> */}
      <Iframe
          url={isMobile == true ? 'https://www.artark.com.sg/staging/mobile/index.html' : 'https://www.artark.com.sg/staging/3d/index.html'}
          className={classes.iframeStyle}
          onLoad={ModelLoadedGallery}
          id='GalleryIFrame'
      />
      {/* <PropsBox propsBoxShown={propsBoxShown} courseId={editData.courseid} totalPropCount={totalPropCount} selectedPropId={selectedPropId} currentPropsIndex={currentPropsIndex} environmentName={editData.environment} /> */}
    </BaseLayoutAdjustable>
  )
}

export default PlatformGallery