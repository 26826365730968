import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import modal from 'actions/modal'
import { makeStyles } from '@material-ui/core/styles';


import Iframe from 'react-iframe';

// import ToggleButton4Choice from 'components/Slider/ToggleButton4Choice'


// import FetchingApi from 'components/UploadingFiles/FetchingApi'
// import ReactImageUpload from 'components/UploadingFiles/ReactImageUpload'

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '40px'
  },
  iframeStyle: {
    width: '100%',
    height: '100vh',
    border: 'none',
    position: 'absolute',
    top: 0,
    zIndex: -1
  },
  logo: {
    width: '200px',
    float: 'left',
    '& img': {
      width: '100%',
    }
  },
  headerBar: {
    float: 'left',
    color: 'white',
    marginLeft: '20px',
    '& p': {
      padding: '6px',
      float: 'left'
    }
  },
  btnSave: {
    // float:'left',
    marginLeft: '30px',
    position: 'fixed',
    left: '0px',
    bottom: 30,
    cursor: 'pointer',
    // '& img': {
    //     width: '50%'
    // }
  },
  nextPrevious: {
    width: '21vh'
  },
}));

const PlayGalley = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [count, setCount] = useState(7);
  const [HotSpotNum, setHotSpotNum] = useState(1);
  const fetchURL = `https://museum.createscape.com.sg/props?page=${count}&take=1`
  const [datas, setData] = useState([])
  const [popUpType, setPopUpType] = useState([])
  const [propname, setPropname] = useState([])
  const [id, setid] = useState([])
  // let CountParam = count
  const [image, setimage] = useState([]);

  function ModelLoadedGallery() {
    window.addEventListener("message", getMessageGallery);
  }
  function getMessageGallery(message: any) {
    console.log(message.data.props)
    let ids = ['PTD02', 'PTM01', 'PTM02']
    switch (message.data.props) {
      case ids[0]:
        setCount(7)
        setHotSpotNum(1)
        break;
      case ids[1]:
        setCount(10)
        setHotSpotNum(2)
        break;
      case ids[2]:
        setCount(4)
        setHotSpotNum(3)
        break;
      default:
        return null
    }
  }

  const onClickBackButton = (key: any) => {
    dispatch(modal.show.started({type: 'cancelBack', source: ''}))

  }

  useEffect(() => {
    window.addEventListener('popstate', onClickBackButton)
    window.history.pushState("93ucpo", window.location.href)
  })


  const [options] = React.useState([{
    option1: "A",
    option2: "B",
    option3: "C",
    option4: "D",
  }]);

  // const Button = (countNumber: any) => {
  //   switch (countNumber) {
  //     case 3:
  //       return (
  //         <div>
  //           <img className={classes.nextPrevious} src={previous} alt="next" onClick={() => setCount(count - 1)} />
  //         </div>
  //       )
  //     case 0:
  //       return (
  //         <img className={classes.nextPrevious} src={next} alt="next" onClick={() => setCount(count + 1)} />
  //       )
  //     default:
  //       return (
  //         <div>
  //           <img className={classes.nextPrevious} src={next} alt="next" onClick={() => setCount(count + 1)} />
  //           <img className={classes.nextPrevious} src={previous} alt="next" onClick={() => setCount(count - 1)} />
  //         </div>
  //       )
  //   }
  // }


  return (
    <Iframe
      url='https://www.createscape.com.sg/login/games/escaperoom/index.html'
      className={classes.iframeStyle}
      onLoad={ModelLoadedGallery}
    />
  )
}

export default PlayGalley
function elsif(arg0: boolean) {
  throw new Error('Function not implemented.')
}

