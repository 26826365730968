import { ActionTypes } from 'constants/index'

import actionCreatorFactory from 'typescript-fsa'

const ac = actionCreatorFactory()

interface CreateTraineeCoursesParam { traineecourse: any }
interface CreateTraineeCoursesResult { traineecourse: any }
interface CreateTraineeCoursesError { error: string }

interface GetTraineeCoursesParam { userid: string }
interface GetTraineeCoursesResult { traineecourses: any }
interface GetTraineeCoursesError { error: string }

interface UpdateTraineeCoursesParam { userid: string, traineecourse: any }
interface UpdateTraineeCoursesResult { traineecourse: any }
interface UpdateTraineeCoursesError { error: string }

interface SelectedTraineeCoursesParam { courseid: string }
interface SelectedTraineeCoursesResult { courseid: string }

export default {
    createtraineecourse: ac.async<CreateTraineeCoursesParam, CreateTraineeCoursesResult, CreateTraineeCoursesError>(ActionTypes.CREATE_TRAINEECOURSE),
    gettraineecoursebyuserid: ac.async<GetTraineeCoursesParam, GetTraineeCoursesResult, GetTraineeCoursesError>(ActionTypes.GET_TRAINEECOURSEBYUSERID),
    updatetraineecoursebyuserid: ac.async<UpdateTraineeCoursesParam, UpdateTraineeCoursesResult, UpdateTraineeCoursesError>(ActionTypes.UPDATE_TRAINEECOURSEBYUSERID),
    setselectedtraineecourse: ac.async<SelectedTraineeCoursesParam, SelectedTraineeCoursesResult, undefined>(ActionTypes.SELECTED_TRAINEECOURSE),
}