import { from, Observable, of } from 'rxjs'
import { ofType, Epic } from 'redux-observable'
import { AnyAction } from 'typescript-fsa'
import { ofAction } from 'typescript-fsa-redux-observable'
import actions from 'actions/environment'
import { ajax } from 'rxjs/ajax'
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators'

export const createEnvironment: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.createenvironment.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/environment`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json'/*  */ },
            body: param.payload.environment
        }).pipe(
            map(data => {
                return actions.createenvironment.done({
                    params: param.payload,
                    result: { environment: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.createenvironment.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getEnvironment: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.getenvironmentbyescaperoomid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/environment`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json'/*  */ },
        }).pipe(
            map(data => {
                return actions.getenvironmentbyescaperoomid.done({
                    params: param.payload,
                    result: { environment: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.getenvironmentbyescaperoomid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const updateEnvironment: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.updateenvironment.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/environment/` + param.payload.environmentid,
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json'/*  */ },
            body: param.payload.environment
        }).pipe(
            map(data => {
                return actions.updateenvironment.done({
                    params: param.payload,
                    result: { environment: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.updateenvironment.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const deleteEnvironment: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.deleteenvironment.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/environment/` + param.payload.environmentid,
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json'/*  */ },
        }).pipe(
            map(data => {
                return actions.deleteenvironment.done({
                    params: param.payload,
                    result: { environment: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.deleteenvironment.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)
