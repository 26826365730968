import { from, Observable, of } from 'rxjs'
import { ofType, Epic } from 'redux-observable'
import { AnyAction } from 'typescript-fsa'
import { ofAction } from 'typescript-fsa-redux-observable'
import actions from 'actions/escaperoom'
import { ajax } from 'rxjs/ajax'
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators'

export const createEscaperoom: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.createescaperoom.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/escaperoom`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json'/*  */ },
            body: param.payload.escaperoom
        }).pipe(
            map(data => {
                return actions.createescaperoom.done({
                    params: param.payload,
                    result: { escaperoom: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.createescaperoom.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getEscaperoomByCourseId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.getescaperoombycourseid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/escaperoom/` + param.payload.courseid,
            method: 'GET',
            headers: { 'Content-Type': 'application/json'/*  */ },
        }).pipe(
            map(data => {
                return actions.getescaperoombycourseid.done({
                    params: param.payload,
                    result: { escaperoom: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.getescaperoombycourseid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const updateEscaperoom: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.updateescaperoom.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/escaperoom/` + param.payload.escaperoomid,
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.escaperoom
        }).pipe(
            map(data => {
                return actions.updateescaperoom.done({
                    params: param.payload,
                    result: { escaperoom: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.updateescaperoom.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const deleteEscaperoom: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.deleteescaperoom.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/escaperoom/` + param.payload.escaperoomid,
            method: 'POST',
            headers: { 'Content-Type': 'application/json'/*  */ },
        }).pipe(
            map(data => {
                return actions.deleteescaperoom.done({
                    params: param.payload,
                    result: { escaperoom: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.deleteescaperoom.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)
