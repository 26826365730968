import React, { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { makeStyles } from '@material-ui/core/styles';
import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'
import bg from 'assets/bg.png'
import logo from 'assets/logo.png'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import tablebg from 'assets/CourseTypeManagement/TableBG.svg'
import yellowBg from 'assets/BaseImg/yellowBGshort.png'
import darkBG from 'assets/BaseImg/darkBgshort.png'
import SaveSymbol from 'assets/BaseImg/saveSymbol.png'
import modal from 'actions/modal'
import course from 'actions/course'
import coursetypeActions from 'actions/coursetype'
import users from 'actions/user'
import TableBG from 'components/Tables/TableBG'
import 'assets/stylesheets/application.css'
import { InfoTable8Col } from 'components/Tables/InfoTable8Col'
// import AccountInfo from 'components/AccountInfo';
import AccountInfoNew from 'components/AccountInfoNew'

// for table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { Scrollbar } from 'react-scrollbars-custom';
import SimpleBarReact from "simplebar-react";
import information from 'assets/information.png'

const useStyles = makeStyles(() => ({
  container2: {
    top: "6rem",
    width: "100vw",
    height: "89vh",
    display: "flex",
    position: "absolute",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  hidden: {
    display: "none"
  },
  header: {
    color: '#fffffe',
    fontFamily: 'impact',
    fontSize: '2.5rem'
  },
  // back button
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'fixed',
    bottom: '1rem',
    right: '2rem',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
  // back button and save button style
  backBtn1: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
      cursor: 'pointer'
    }
  },
  saveBtn: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3rem',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
  pageBottom: {
    display: 'flex',
    position: 'fixed',
    bottom: "1rem",
    right: "2rem",
  },
  // page top
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '12rem'
  },
  buttonContainer: {
    width: "103rem",
    height: "11rem",
    cursor: "pointer",
    paddingTop: "2rem",
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '27vw',
      height: '13vh'
    }
  },
  externalRow: {
    display: 'flex',
    position: "relative",
    height: "37vh",
    width: '83vw',
    bottom: "1rem",
    paddingTop: '1.6rem',
    justifyContent: 'center'
  },
  row1: {
    display: 'flex',
    flexDirection: 'row',
    width: "80vw",
    height: "24vh",
    alignItems: "center",
    justifyContent: "space-evenly"
  },
  row2: {
    display: 'flex',
    flexDirection: 'column',
    width: "27vw",
    height: "24vh",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginLeft: '5rem'
  },
  inputWrapper: {
    width: "34rem",
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2vw',
    },
    '& img': {
      width: '100%'
    }
  },
  inputWrapperDark: {
    width: "34rem",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#666767',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2vw',
      // width: '20rem'
    },
    '& img': {
      width: '100%'
    }
  },
  table: {
    minWidth: 650
  },
  tableHeadRow:  {
    // display: "flex",
    // width: "72vw",
    // justifyContent:"space- around"
  },
  cellHead1: {
    color: 'red'
  },
  cellHead2: {
    color: 'red'
  },
  cellHead3: {
    color: 'red'
  },
  cell: {
    borderBottom: "none",
    color: "#FFD84C",
    // alignItems: "center",
    fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    fontSize: '1.2rem',
  },
  cellHead: {
    borderBottom: "none",
    color: "#FFFFFE",
    // alignItems: "center",
    fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    fontSize: '1.2rem',
  },
  // for scroll bar
  scroll: {
    margin: '20px',
    padding: '2px',
    width: '100%',
    height: '54vh',
    borderRadius: '4px',
    position: "relative",
    right: "1.5rem",
    bottom: "1.5rem"
  },
  // for new scroll bar
  simpleBar: {
    maxHeight: '51vh'
  },
  // highlight row
  highlightRow: {
    border: 'solid',
    borderWidth: '2px',
    color: 'white'
  },
  NohighlightRow: {
    border: 'none',
  },
  // custom modal
  paper: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
    outline: "none",
    position: "absolute",
    backgroundColor: "black",
    justifyContent: "center",
    alignItems: "center",
    '& h1': {
      color: '#fff',
      marginBottom: '5%',
      fontSize: '6vh',
      fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
      letterSpacing: '1px',
    },
    '& img': {
      width: '16vw%',
      cursor: 'pointer'
    }
  },
  inputWrapperContainer: {
    display: 'flex',
    width: "100vw",
    justifyContent: "center",
  },
  requiredfield: {
    color: "#FFD84C",
    fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    letterSpacing: '1px',
    paddingBottom: '1rem',
  },
  input: {
    background: 'transparent',
    position: 'absolute',
    top: '23%',
    left: '50%',
    transform: 'translate(-50%,0%)',
    width: '58%',
    border: 'none',
    textAlign: 'center',
    height: '58px',
    color: '#ffd84d',
    fontFamily: "Impact",
    fontSize: "2rem",

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    },
  },
  // edit page
  TypeSetuprequiredfield: {
    color: "#FFD84C",
    fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    letterSpacing: '1px',
    paddingBottom: '1rem',
  },
  trainingcourse: {
    cursor: 'pointer',
    width: '32rem'
  },
  headerTypeSetup: {
    fontFamily: "Impact",
    fontSize: "4rem",
    letterSpacing: "4px",
    paddingBottom: "2rem",
    color: '#FFFFFF'
  },
  inputTypeSetup: {
    background: 'transparent',
    position: 'absolute',
    top: '23%',
    left: '50%',
    transform: 'translate(-50%,0%)',
    width: '58%',
    border: 'none',
    textAlign: 'center',
    height: '58px',
    color: '#ffd84d',
    fontFamily: "Impact",
    fontSize: "2rem",

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputWrapperTypeSetup: {
    position: 'relative'
  },
  externalRowTypeSetup: {
    display: 'flex',
    position: "relative",
    height: "45vh",
    bottom: "0rem"
  },
  row1TypeSetup: {
    display: 'flex',
    flexDirection: 'column',
    width: "43rem",
    height: "17rem",
    alignItems: "center",
    justifyContent: "space-evenly",
    '& img': {
      width: '39rem'
    }
  },
  checkbox: {
    color: '#ffffff',
  },
  information: {
    left: '5vw',
    width: '40px',
    bottom: '10vh',
    height: '40px',
    position: 'absolute',
    cursor: 'pointer',

    '& img': {
      width: '100%',
    }
  }
}));

const CourseTypeManagement = () => {
  const dispatch = useDispatch()
  const classes = useStyles();

  let arraytoStorekeyToHighLight: string[] = []

  const [selectedRow, setSelectedRow] = useState(arraytoStorekeyToHighLight)
  const [selectedRowData, setSelectedRowData] = useState()
  const coursetype = useSelector((state: any) => state.coursetype.data)

  const [showEditPage, setshowEditPage] = useState(false)
  const [buttonShown, setButtonsShown] = useState(false)

  const id = useSelector((state: any) => state.auth.loggedinuser.user.id)
  const deleted = useSelector((state: any) => state.coursetype.deleted)

  const [showEditButton, setshowEditButton] = useState(false)

  useEffect(() => {
    if(deleted) {
      setSelectedRow(arraytoStorekeyToHighLight);
      setButtonsShown(false)
      setshowEditButton(false)
    }
    dispatch(coursetypeActions.getcoursetypebyuserid.started({userid: id}))
  }, [id, deleted])

  const checkBoxInfo = (event: ChangeEvent<HTMLInputElement>, userdata: any) => {
    let checked = event.target.checked

    if (checked === true) {
      selectedRow.push(userdata.id)
    } else {
      selectedRow.splice(selectedRow.indexOf(userdata.id), 1)
    }

    // 0
    if (selectedRow.length < 1) {
      setButtonsShown(false)
    } else {
      // 1
      if (selectedRow.length < 2) {
        setshowEditButton(true)
        setButtonsShown(true)
        setSelectedRowData(userdata);
      } else if (selectedRow.length > 1) {
        setButtonsShown(true)
        setshowEditButton(false)
        setSelectedRowData(userdata);
      }
    }
  };

  const [headers] = React.useState([{
    col1: 'COURSE TYPE NAME',
    col2: 'COURSE TYPE DESCRIPTION',
    col3: 'SELECT',
    key: 0
  }]);

  function deleteButton() {
    let ids = selectedRow.join(",")
    dispatch(modal.show.started({type: 'DeleteCourseType', source: ids}))
  }

  const EditButtons = (value: any) => {
    if (value === true) {
      if (showEditButton === true) {
        return (
          <div className={classes.externalRow}>
            <div className={classes.row1}>
              <div className={classes.inputWrapperDark} onClick={() => dispatch(push('/corporate/createcoursetype'))}>
                <img src={darkBG} alt="bg" />
                <p>CREATE COURSE TYPE</p>
              </div>
              <div className={classes.inputWrapper} onClick={() => dispatch (push('/corporate/coursetypeedit', selectedRowData))}>
                <img src={yellowBg} alt="bg" />
                <p>EDIT COURSE TYPE</p>
              </div>
              <div className={classes.inputWrapper} onClick={() => deleteButton()}>
                <img src={yellowBg} alt="bg" />
                <p>DELETE COURSE TYPE</p>
              </div>
            </div>
          </div>
        )
      } else {
        return (
          <div className={classes.externalRow}>
            <div className={classes.row1}>
              <div className={classes.inputWrapperDark}>
                <img src={darkBG} alt="bg" />
                <p>CREATE COURSE TYPE</p>
              </div>
              <div className={classes.inputWrapperDark}>
                <img src={darkBG} alt="bg" />
                <p>EDIT COURSE TYPE</p>
              </div>
              <div className={classes.inputWrapper} onClick={() => deleteButton()}>
                <img src={yellowBg} alt="bg" />
                <p>DELETE COURSE TYPE</p>
              </div>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className={classes.externalRow}>
          <div className={classes.row1}>
            <div className={classes.inputWrapper} onClick={() => dispatch(push('/corporate/createcoursetype'))}>
              <img src={yellowBg} alt="bg" />
              <p>CREATE COURSE TYPE</p>
            </div>
            <div className={classes.inputWrapperDark}>
              <img src={darkBG} alt="bg" />
              <p>EDIT COURSE TYPE</p>
            </div>
            <div className={classes.inputWrapperDark}>
              <img src={darkBG} alt="bg" />
              <p>DELETE COURSE TYPE</p>
            </div>
          </div>
        </div>
      )
    }
  }

  function tableRow(tabeInfo: any, arrayToCompare: any) {
    if (arrayToCompare.includes(tabeInfo.id) === true) {
      return (
        <TableRow key={tabeInfo.id} className={classes.highlightRow} >
          <TableCell className={classes.cell} align="center" >{tabeInfo.name != null ? tabeInfo.name : null}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.description}</TableCell>
          <TableCell className={classes.cell} align="center"><Checkbox className={classes.checkbox} onChange={(e) => checkBoxInfo(e, tabeInfo)} /></TableCell>
        </TableRow>
      )
    }
    else {
      return (
        <TableRow key={tabeInfo.id} className={classes.NohighlightRow} >
          <TableCell className={classes.cell} align="center" >{tabeInfo.name != null ? tabeInfo.name : null}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.description}</TableCell>
          <TableCell className={classes.cell} align="center"><Checkbox className={classes.checkbox} onChange={(e) => checkBoxInfo(e, tabeInfo)} /></TableCell>
        </TableRow>
      )
    }
  }

  return (
    <BaseLayoutAdjustable bg={bg}>
          <div className={classes.topInfo}>
            <img src={logo} alt="logo" className={classes.logo} />
            <AccountInfoNew />
          </div>
          <div className={showEditPage === true ? classes.hidden : classes.container2}>
            <h3 className={classes.header}>COURSE TYPE MANAGEMENT</h3>
            <TableBG bg={tablebg}>
              <div className={classes.scroll}>
                <SimpleBarReact className={classes.simpleBar}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      {headers.map((col) =>
                        <TableRow key={col.key}>
                          <TableCell className={classes.cellHead} align="center">{col.col1}</TableCell>
                          <TableCell className={classes.cellHead} align="center">{col.col2}</TableCell>
                          <TableCell className={classes.cellHead} align="center">{col.col3}</TableCell>
                        </TableRow>
                      )}
                    </TableHead>
                    <TableBody>
                      {coursetype != null ?
                        coursetype.data.map((row: any) => tableRow(row, selectedRow)) : null
                      }
                    </TableBody>
                  </Table>
                </SimpleBarReact>
                {/* </CustomScrollbars> */}
              </div>
            </TableBG>
            {EditButtons(buttonShown)}
          </div>
          <div className={/* buttonShown === true || showEditPage === true ? classes.hidden :  */classes.backBtn} onClick={() => dispatch (push('/corporate/overview'))}>
            <img src={BackSymbol} alt="BackSymbol" />
            <p>BACK</p>
          </div>
          <div className={classes.information} onClick={() => window.open('https://www.createscape.com.sg/documents/corporate-account-user-manual.pdf')} >
            <img src={information}/>
          </div>
      </BaseLayoutAdjustable>
  )
}

export default CourseTypeManagement;
