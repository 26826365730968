import { from, Observable, of } from 'rxjs'
import { ofType, Epic } from 'redux-observable'
import { AnyAction } from 'typescript-fsa'
import { ofAction } from 'typescript-fsa-redux-observable'
import actions from 'actions/user'
import { ajax } from 'rxjs/ajax'
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators'

export const updateUserById: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.updateuserbyid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/users/` + param.payload.userid,
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.user
        }).pipe(
            map(data => {
                return actions.updateuserbyid.done({
                    params: param.payload,
                    result: { user: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.updateuserbyid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getUserByAccountType: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.getuserbyaccounttype.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/users/` + param.payload.userid + '/' + param.payload.accounttype + '?take=100',
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.getuserbyaccounttype.done({
                    params: param.payload,
                    result: { user: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.getuserbyaccounttype.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const deleteUser: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.delete.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/users/` + param.payload.userids,
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json'/* , 'Authorization': 'Bearer ' + store.value.user.data.token.accessToken */ },
        }).pipe(
            map(data => {
                return actions.delete.done({
                    params: param.payload,
                    result: { user: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.delete.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)
