import { CoporateConstants } from '../constants/index'

export const corporatePageActions = {
  traineeAccountSetup,
  corporateProfileSetup,
  traineeTable,
  hasCoursesTypes,
  hasCourses,
  performanceRecordsTable,
  performanceRecordsBelongToCoporateID,
  performanceRecordsTableRowIndex,
  performanceRecordsDeleteids,
  TriggerToSendDeleteIds,
  gameResultsData,
  TriggerToSendToBackEnd,
  TriggerToDeleteUserAccount,
  SessionIDStorage
}

//Desc Active Param
function traineeAccountSetup(traineeName: string, traineeDateOfBirth: string, traineeEmail: string, groupName: string,
  traineePassword: string, TraineePasswordSymbol: string, traineeCourseType: string, traineeAssignedNumOfSession: string,
  traineeAssignedCourse: string, traineeStartDate: any, traineeEndDate: any, traineeEnableOrDisable: any) {
    // console.log(
    //   `inside traineeActions
    //   name: ${traineeName}
    //   birth: ${traineeDateOfBirth}
    //   email: ${traineeEmail}
    //   groupName: ${groupName}
    //   password: ${traineePassword}
    //   passwordsymbol: ${traineeCourseType}
    //   courseType: ${traineeName}
    //   numOfSession: ${traineeAssignedNumOfSession}
    //   assignedCourse: ${traineeAssignedCourse}
    //   startDate: ${traineeStartDate}
    //   EndDate: ${traineeEndDate}
    //   EnableOrDisable: ${traineeEnableOrDisable}
    //   `
    // )
  return {
    type: CoporateConstants.TRAINEE_ACCOUNT_TABLE,
    traineeName,
    traineeDateOfBirth,
    traineeEmail,
    groupName,
    traineePassword,
    TraineePasswordSymbol,
    traineeCourseType,
    traineeAssignedCourse,
    traineeAssignedNumOfSession,
    traineeStartDate,
    traineeEndDate,
    traineeEnableOrDisable,
  }
}

function traineeTable(name: string, groupName: string, courseType: string, AssignedCourse: string, endDate: any, traineeTableData: any) {
  return {
    type: CoporateConstants.TRAINEE_ACCOUNT_TABLE,
    name,
    groupName,
    courseType,
    AssignedCourse,
    endDate,
    traineeTableData
  }
}

function performanceRecordsTable(sessionId: any, courseType: any, courseName: any, TraineeName: any, GroupName: any, SessionDate: any, SessionTime: any, tableData: any) {
  // console.log(`inside actions : ${tableData}`)
  return {
    type: CoporateConstants.PERFORMANCE_RECORDS_TABLE,
    sessionId,
    courseType,
    courseName,
    TraineeName,
    GroupName,
    SessionDate,
    SessionTime,
    tableData
  }
}

function performanceRecordsBelongToCoporateID(userid: any) {
  return {
    type: CoporateConstants.PERFORMANCE_RECORDS_BELONG_TO_COPORATEID,
    userid
  }
}

function hasCoursesTypes(coursetypes: any) {
  return {
    type: CoporateConstants.HAS_COURSE_TYPES,
    coursetypes
  }
}

function hasCourses(courses: any) {
  return {
    type: CoporateConstants.HAS_COURSES,
    courses
  }
}

function performanceRecordsTableRowIndex(index: any, checked: any) {
  return {
    type: CoporateConstants.PERFORMANCE_RECORDS_TABLE_ROW_INDEX,
    index,
    checked,
  }
}

function performanceRecordsDeleteids(id: any) {
  return {
    type: CoporateConstants.PERFORMANCE_RECORDS_DELETE_IDS,
    id,
  }
}

function TriggerToSendDeleteIds(toSent: boolean) {
  return {
    type: CoporateConstants.TRIGGER_TO_SENT_DELETE_IDS,
    toSent
  }
}

function gameResultsData(hintUsed: any, timetaken: any, quizescore: any, userId: any, gameResultId: any) {
  return {
    type: CoporateConstants.GAME_RESULTS_INFORMATION,
    hintUsed,
    timetaken,
    quizescore,
    userId,
    gameResultId,
  }
}

function corporateProfileSetup(masterAccountManagerName: string, companyName: string, masterAccountManagerEmail: string,
  masterAccountManagerPassword: string, MasterAccPasswordSymbol: string) {
  return {
    type: CoporateConstants.CORPORATE_PROFILE_SETUP,
    masterAccountManagerName,
    companyName,
    masterAccountManagerEmail,
    masterAccountManagerPassword,
    MasterAccPasswordSymbol,
    // for company logo upload
  }
}

function TriggerToSendToBackEnd(toSentProfileSetup: boolean) {
  return {
    type: CoporateConstants.TRIGGER_TO_SEND_TO_BACKEND,
    toSentProfileSetup
  }
}

function TriggerToDeleteUserAccount(IdsToDelete: any, TodeleteTraineeInfo: any) {
  return {
    type: CoporateConstants.TRIGGER_TO_DELETE_USER_ACCOUNT,
    IdsToDelete,
    TodeleteTraineeInfo
  }
}

function SessionIDStorage(SessionId: any) {
  return {
    type: CoporateConstants.SESSION_ID_STORAGE,
    SessionId
  }
}




