import { from, merge, Observable, of } from 'rxjs'
import { ofType, Epic } from 'redux-observable'
import { AnyAction } from 'typescript-fsa'
import { ofAction } from 'typescript-fsa-redux-observable'
import actions from 'actions/course'
import { ajax } from 'rxjs/ajax'
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators'
import { corporatePageActions } from 'actions/coporatePageActions'

export const createCourse: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.createcourse.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/course`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.course
        }).pipe(
            map(data => {
                return actions.createcourse.done({
                    params: param.payload,
                    result: { course: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.createcourse.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getCoursesByUserId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.getcoursesbyuserid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/course/` + param.payload.userid,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            /* map(data => {
                return actions.getcoursesbyuserid.done({
                    params: param.payload,
                    result: { course: data.response },
                })
            }), */
            mergeMap(data => {
                return merge(
                    of(actions.getcoursesbyuserid.done({
                        params: param.payload,
                        result: { course: data.response },
                    })),
                    of(corporatePageActions.hasCourses(data.response.data.length))
                )
            }),
            catchError(error =>
                Observable.of(actions.getcoursesbyuserid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const updateCourse: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.updatecourse.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/course/` + param.payload.courseid,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.course
        }).pipe(
            map(data => {
                return actions.updatecourse.done({
                    params: param.payload,
                    result: { course: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.updatecourse.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const deleteCourse: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.deletecourse.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/course/` + param.payload.courseids,
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.deletecourse.done({
                    params: param.payload,
                    result: { course: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.deletecourse.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)