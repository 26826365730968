import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import bg from 'assets/CoporateUserOverView/CoporateUserOverviewBG.png'
import { makeStyles } from '@material-ui/core/styles';
// import { useHistory } from 'react-router-dom'


import Iframe from 'react-iframe';
import { dispatch } from 'rxjs/internal-compatibility';
import { push } from 'connected-react-router'
import modal from 'actions/modal'
import { isNull } from 'util';
// import { browserHistory } from 'react-router';

// import ToggleButton4Choice from 'components/Slider/ToggleButton4Choice'


// import FetchingApi from 'components/UploadingFiles/FetchingApi'
// import ReactImageUpload from 'components/UploadingFiles/ReactImageUpload'

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '40px'
  },
  iframeStyle: {
    width: '100%',
    height: '100vh',
    border: 'none',
    position: 'absolute',
    top: 0,
    zIndex: -1,
    pointerEvents: "none"
  },
  logo: {
    width: '200px',
    float: 'left',
    '& img': {
      width: '100%',
    }
  },
  headerBar: {
    float: 'left',
    color: 'white',
    marginLeft: '20px',
    '& p': {
      padding: '6px',
      float: 'left'
    }
  },
  btnSave: {
    // float:'left',
    marginLeft: '30px',
    position: 'fixed',
    left: '0px',
    bottom: 30,
    cursor: 'pointer',
    // '& img': {
    //     width: '50%'
    // }
  },
  nextPrevious: {
    width: '21vh'
  },
}));

const PlayGalley = () => {
  const classes = useStyles();
  const [count, setCount] = useState(7);
  const dispatch = useDispatch()

  // const [locationKeys, setLocationKeys] = useState([])
  // const history = useHistory()

  // useEffect(() => {
  //   return history.listen((location:any) => {
  //     if (history.action === 'PUSH') {
  //       console.log(location)
  //       // setLocationKeys([location.key])
  //     }

  //     if (history.action === 'POP') {
  //       if (locationKeys[1] === location.key) {
  //         setLocationKeys(([_, ...keys]) => keys)

  //         // Handle forward event

  //       } else {
  //         // setLocationKeys((keys) => [location.key, ...keys])
  //         console.log('nothing much')
  //         // Handle back event

  //       }
  //     }
  //   })
  // }, [locationKeys,])

  // let [disable, setDisable] = useState(false)

  const onClickBackButton = (key: any) => {
    // let x = window.confirm("Do you want to go back without submitting details?")
    // if (x === true) {
    //   dispatch(push('/CorporateUserOverView'))
    // }
    // else {
    //   console.log('selection false')
    // }
    // console.log(key.state)
    dispatch(modal.show.started({type: 'cancelBack', source: ''}))
        // if (x === false) {
        //   console.log(x)
        //   window.onpopstate = function () {
        //     window.history.go(1);
        //   }
        // }
        // else {
        //   console.log(x)
        // }
  }

  useEffect(() => {
    window.addEventListener('popstate', onClickBackButton)
    window.history.pushState("93ucpo", window.location.href)
    // console.log(`before disable ${disable}`)
    // if (disable === true) {
    //   window.history.pushState(null, window.location.href);
    // }
    // else {
    //   console.log('continue')
    // }
    // window.addEventListener('popstate', function (event) {
    //   console.log(event)
    //   history.pushState(null, document.title, location.href);
    // });
    // this works
    // console.log(window.location.href)
    // window.history.pushState(null, window.location.href);
    // window.onpopstate = function () {
    //   window.history.go(1);
    // };
  })
  const name = useSelector((state: any) => state.user.name)
  const id = useSelector((state: any) => state.user.id)
  const email = useSelector((state: any) => state.user.email)
  // console.log(name)
  // console.log(id)
  // console.log(email)
  let sessionId = '499f8e3f-bb83-41a2-b27b-896a33ac939f'
  let courseId = '1e5f8228-ef69-476c-bcc5-08c90c81f67f'
  const sendInfo =() => {
    sendMessagetoUnity(`${name} | ${id} | ${email} | ${courseId} | ${sessionId}`)
  }

  return (
      <Iframe
      url='https://www.createscape.com.sg/login/games/escaperoom/index.html'
        className={classes.iframeStyle}
      onLoad={sendInfo}
      />
      // <h1>hello</h1>
  )
}

export default PlayGalley
function elsif(arg0: boolean) {
  throw new Error('Function not implemented.')
}

function onClickBackButton(arg0: string, onClickBackButton: any) {
  throw new Error('Function not implemented.');
}

function sendMessagetoUnity(dataToSentOver: string) {
  console.log(`information to sent to unity: ${dataToSentOver}`)
}

