import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { makeStyles } from '@material-ui/core/styles';

import HomeBtn from '../../../assets/CorporateProfileSetup/HomeBtn.png'
import Edit from '../../../assets/CourseManagerManagement/EditCourseManagerYellow.png'
import Create from '../../../assets/CourseManagerManagement/CreatenewCourseManagerYellow.png'
import DeleteCourse from '../../../assets/CourseManagerManagement/DeleteCourseManagerYellow.png'

import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'
// import bg from 'assets/CoporateUserOverView/CoporateUserOverviewBG.png'
import bg from 'assets/bg.png'
import textbg from 'assets/textfield.svg'
import logo from 'assets/logo.png'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import SaveSymbol from 'assets/BaseImg/saveSymbol.png'
import Calendar from 'assets/BaseImg/calendar.svg'
import tablebg from 'assets/CourseTypeManagement/TableBG.svg'
import yellowBgshort from 'assets/BaseImg/yellowBGshort.png'
import darkBG from 'assets/BaseImg/darkBgShort.png'

import modal from 'actions/modal'
import auth from 'actions/auth'
import agentinfo from 'actions/agentinfo'
import TableBG from 'components/Tables/TableBG'

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import 'assets/stylesheets/application.css'
import { InfoTable8Col } from 'components/Tables/InfoTable8Col'
// import AccountInfo from 'components/AccountInfo';
import AccountInfoNew from 'components/AccountInfoNew'

// for swiper
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import 'assets/stylesheets/pagination.min.css'
// import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

// import Swiper core and required modules
import SwiperCore, {
  Pagination, Navigation
} from 'swiper/core';

// experiment test swiper
import arrowLeft from 'assets/swiperItems/arrowYellowLeft.png'
import arrowRight from 'assets/swiperItems/arrowYellowRight.png'

// experiement toggle button
import enableButton from 'assets/ToggleButton/enableButton.png'
import disableButton from 'assets/ToggleButton/disableButton.png'
import user from 'actions/user';
import { countries } from 'epics/countries';
import information from 'assets/information.png'

const useStyles = makeStyles(() => ({
  container2: {
    top: "6rem",
    width: "100vw",
    height: "89vh",
    display: "flex",
    position: "absolute",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  header: {
    color: '#fffffe',
    fontFamily: 'impact',
    fontSize: '2.5rem'
  },
  // page top
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '12rem'
  },
  buttonContainer: {
    width: "103rem",
    height: "11rem",
    cursor: "pointer",
    paddingTop: "2rem",
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '27vw',
      height: '13vh'
    }
  },
  inputWrapperContainer: {
    display: 'flex',
    width: "100vw",
    justifyContent: "center",
  },
  inputWrapperContainerHidden: {
    display: 'flex',
    width: "100vw",
    justifyContent: "center",
    position: "fixed",
    bottom: "74vw",
  },
  inputWrapper: {
    position: 'relative',
    width: "24vw",
    height: "13vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: '2rem',
    marginLeft: '2rem',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2rem',
      width: '20rem'
    },
    '& img': {
      width: '25vw'
    }
  },
  inputWrapperHidden: {
    opacity: 0,
    position: 'relative',
    width: "24vw",
    height: "13vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: '2rem',
    marginLeft: '2rem',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2rem',
      width: '20rem'
    },
    '& img': {
      width: '25vw'
    }
  },
  inputWrapperDropdown: {
    position: 'relative',
    width: "24vw",
    height: "13vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginRight: '2rem',
    marginLeft: '2rem',
    '& select': {
      position: "fixed",
      background: "none",
      border: "none",
      color: "#FFD60F",
      fontFamily: "Impact",
      letterSpacing: '1px',
      fontSize: '1.2rem',
      textAlign: 'center',
      width: "320px",
    },
    '& img': {
      width: '25vw'
    }
  },
  input: {
    background: 'transparent',
    position: 'absolute',
    border: 'none',
    textAlign: 'center',
    height: '58px',
    color: '#ffd84d',
    fontFamily: "Impact",
    letterSpacing: "1px",
    fontSize: "1.2rem",
    width: "23rem",

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },

    '&[type="date"]::-webkit-calendar-picker-indicator': {
      color: 'rgba(255, 255, 255, 1)',
      backgroundImage: `url(${Calendar})`,
    },

    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputPass: {
    top: 0,
    left: 0,
    color: '#ffd84d',
    width: '100%',
    border: 'none',
    padding: '0px 48px',
    position: 'absolute',
    background: 'transparent',
    textAlign: 'center',
    fontSize: '1.2rem',
    height: '100%',
    fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",

    '&::placeholder': {
      color: '#ffd84d83',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  togglebutton: {
    top: '0px',
    right: '25px',
    position: 'absolute',
    height: '100%',
    maxHeight: 'none',
  },
  InputAPass: {
    color: 'white',
  },
  row2: {
    display: 'flex',
    marginTop: '3rem'
  },
  // swiper
  swiperWrapper: {
    width: "100vw",
    height: "84vh",
  },
  //back and save buttons style
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
      cursor: 'pointer'
    }
  },
  saveBtn: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3rem',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
  pageBottom: {
    display: 'flex',
    position: 'fixed',
    bottom: "1rem",
    right: "2rem",
  },
  information: {
    left: '5vw',
    width: '40px',
    bottom: '10vh',
    height: '40px',
    position: 'absolute',
    cursor: 'pointer',

    '& img': {
      width: '100%',
    }
  }
}));

const AgentAccountSetup = () => {
  const dispatch = useDispatch()
  const classes = useStyles();

  // retrieving inputs
  const [AgentName, setAgentName] = useState('')
  const [AgentEmail, setAgentEmail] = useState('')
  const [AgentCountry, setAgentCountry] = useState('')
  const [AgentContact, setAgentContact] = useState('')
  const [AgentPassword, setAgentPassword] = useState('')
  const [EnableDisable, setEnableDisable] = useState(true)

  const id = useSelector((state: any) => state.auth.loggedinuser.user.id)
  const registered = useSelector((state: any) => state.auth.registered)
  const registereduser = useSelector((state: any) => state.auth.registereduser)
  const error = useSelector((state: any) => state.auth.error)
  const [toggleOption, settoggleOption] = useState('enable')

  function retrieveInfoFromInput1(inputType: any, input: any) {
    inputType(input)
  }

  const saveButtonClicked = () => {
    sentToBackEnd()
  }

  // axios
  const sentToBackEnd = () => {
    dispatch(auth.register.started({
      user: {
        "name": AgentName,
        "email": AgentEmail,
        "accounttype": "AGENT",
        "password": AgentPassword,
        "status": EnableDisable,
        "belongstouser": id,
        "belongstoagent": "",
      },
      userinfo: {
        "country": AgentCountry,
        "contacts": AgentContact,
      }
    }))
    dispatch(modal.show.started({type: 'saveAgentSetup', source: ''}))
  }

  useEffect(() => {
    if(registered){
      dispatch(agentinfo.createagentinfo.started({agentinfo: {
        "country": registereduser.userinfo.country,
        "contacts": registereduser.userinfo.contacts,
        "user": registereduser.reguser.id
      }}));
      dispatch(modal.show.started({type: 'saveAgentSetup', source: ''}))
    }

    if(error == true ) {
      dispatch(modal.show.started({type: 'formHasErrors', source: ''}))
    }
    return () => {
    };
  }, [registered, error]);

  const disableButtonClicked = () => {
    settoggleOption('enable')
    setEnableDisable(true)
  }
  const enableButtonClicked = () => {
    settoggleOption('disable')
    setEnableDisable(false)
  }

  const [values, setValues] = React.useState({
    password: AgentPassword,
    showPassword: false,
  });

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const customToggleButton = (isItEnableOrDisable: any) => {
    if (isItEnableOrDisable === 'disable') {
      return (
        <img src={disableButton} alt="disableButton" onClick={() => disableButtonClicked()}/>
      )
    } else {
      return (
        <img src={enableButton} alt="enableButton" onClick={() => enableButtonClicked()}/>
      )
    }
  }

  // get current date
  const mydate = require('current-date')
  const [hasDateInputBeenClick, sethasDateInputBeenClick] = useState(false)

  return (
    <BaseLayoutAdjustable bg={bg}>
      <div className={classes.topInfo}>
        <img src={logo} alt="logo" className={classes.logo} />
        <AccountInfoNew />
      </div>
      <div className="SwiperTestMainContainer">
        <h3>AGENT ACCOUNT SETUP</h3>
        <h5>*INDICATES REQUIRED FIELD</h5>
        <div className="swiperTestContainer">
          <div className={classes.inputWrapperContainer}>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" className={classes.input} placeholder="AGENT NAME*" type="text" onChange={(e) => retrieveInfoFromInput1(setAgentName, e.target.value)} />
            </div>
            <div className={classes.inputWrapperDropdown}>
              <img src={textbg} alt="bg" />
              <select defaultValue={'AGENT COUNTRY*'} onChange={(e) => retrieveInfoFromInput1(setAgentCountry, e.target.value)}>
                <option key={0} value="DEFAULT">AGENT COUNTRY*</option>
                {countries.map((data) => {
                  return <option key={data.value} value={data.label} >{data.label}</option>
                })}
              </select>
            </div>
          </div>
          <div className={classes.inputWrapperContainer}>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <input autoComplete="new-email" className={classes.input} placeholder="AGENT EMAIL*" type="email" required onChange={(e) => retrieveInfoFromInput1(setAgentEmail, e.target.value)} />
            </div>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              <input autoComplete="off" className={classes.input} placeholder="AGENT CONTACTS*" type="number" onChange={(e) => retrieveInfoFromInput1(setAgentContact, e.target.value)} />
            </div>
          </div>
          <div className={classes.inputWrapperContainer}>
            <div className={classes.inputWrapper}>
              <img src={textbg} alt="bg" />
              {/* <input autoComplete="new-password" className={classes.input} placeholder="AGENT PASSWORD*" type="password" onChange={(e) => retrieveInfoFromInput1(setAgentPassword, e.target.value)} /> */}
              <input
                placeholder="AGENT PASSWORD*"
                type={values.showPassword ? 'text' : 'password'}
                value={AgentPassword}
                autoComplete="new-password"
                className={classes.inputPass}
                onChange={(e) => retrieveInfoFromInput1(setAgentPassword, e.target.value)}
              />
              <InputAdornment position="end" className={classes.togglebutton}>
                    <IconButton className={classes.InputAPass}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
            </div>
            <div className={classes.inputWrapper}>
              {customToggleButton(toggleOption)}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.pageBottom}>
        <div className={classes.backBtn} onClick={() => dispatch(push('/admiral/agentlisting'))}>
          <img src={BackSymbol} alt="BackSymbol" />
          <p onClick={() => dispatch(push('/admiral/agentlisting'))}>BACK</p>
        </div>
        <div className={classes.saveBtn} onClick={() => saveButtonClicked()}>
          <img src={SaveSymbol} alt="BackSymbol" />
          <p>SAVE</p>
        </div>
      </div>
      <div className={classes.information} onClick={() => window.open('https://www.createscape.com.sg/documents/admiral-account-user-manual.pdf')} >
        <img src={information}/>
      </div>
    </BaseLayoutAdjustable>
  )
}

export default AgentAccountSetup;
