import immutable from 'immutability-helper'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import actions from 'actions/user'

export const userState = {
    status: 'idle',
    deleted: false,
    updated: false,
    error: false,
    data: null,
}

export default {
    user: reducerWithInitialState(userState)
        .case(actions.delete.started, (state, action) => {
            return immutable(state, {
                status: { $set: 'running' },
                deleted: { $set: false },
            })
        })
        .case(actions.delete.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                deleted: { $set: true },
            })
        })
        .case(actions.delete.failed, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                deleted: { $set: false },
            })
        })
        .case(actions.getuserbyaccounttype.started, (state) => {
            return immutable(state, {
                status: { $set: 'running' },
                data: { $set: null }
            })
        })
        .case(actions.getuserbyaccounttype.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: action.result.user }
            })
        })
        .case(actions.getuserbyaccounttype.failed, (state) => {
            return immutable(state, {
                status: { $set: 'idle' },
                data: { $set: null }
            })
        })
        .case(actions.updateuserbyid.started, (state) => {
            return immutable(state, {
                status: { $set: 'running' },
                updated: { $set: false },
                error: { $set: false },
            })
        })
        .case(actions.updateuserbyid.done, (state, action) => {
            return immutable(state, {
                status: { $set: 'idle' },
                updated: { $set: true },
            })
        })
        .case(actions.updateuserbyid.failed, (state) => {
            return immutable(state, {
                status: { $set: 'idle' },
                updated: { $set: false },
                error: { $set: true },
            })
        })
    ,
}
