import { from, Observable, of } from 'rxjs'
import { ofType, Epic } from 'redux-observable'
import { AnyAction } from 'typescript-fsa'
import { ofAction } from 'typescript-fsa-redux-observable'
import actions from 'actions/traineeinfo'
import { ajax } from 'rxjs/ajax'
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators'

export const createTraineeInfo: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.createtraineeinfo.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/traineeinfo`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.traineeinfo
        }).pipe(
            map(data => {
                return actions.createtraineeinfo.done({
                    params: param.payload,
                    result: { traineeinfo: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.createtraineeinfo.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getTraineeInfoByUserId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.gettraineeinfobyuserid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/traineeinfo/` + param.payload.userid,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.gettraineeinfobyuserid.done({
                    params: param.payload,
                    result: { traineeinfo: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.gettraineeinfobyuserid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const updateTraineeInfoByUserId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.updatetraineeinfobyuserid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/traineeinfo/` + param.payload.userid,
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.traineeinfo
        }).pipe(
            map(data => {
                return actions.updatetraineeinfobyuserid.done({
                    params: param.payload,
                    result: { traineeinfo: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.updatetraineeinfobyuserid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)