import { from, merge, Observable, of } from 'rxjs'
import { ofType, Epic } from 'redux-observable'
import { AnyAction } from 'typescript-fsa'
import { ofAction } from 'typescript-fsa-redux-observable'
import actions from 'actions/exhibits'
import { ajax } from 'rxjs/ajax'
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators'
import modal from 'actions/modal'
import { dispatch } from 'rxjs/internal-compatibility'

export const createProps: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.createprops.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/exhibits`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.props
        }).pipe(
            map(data => {
                return actions.createprops.done({
                    params: param.payload,
                    result: { props: data },
                })
            }),
            catchError(error =>
                Observable.of(actions.createprops.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getPropsByCourseId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.getpropsbycourseid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/exhibits/` + param.payload.courseid + '/' + param.payload.locationname,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.getpropsbycourseid.done({
                    params: param.payload,
                    result: { props: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.getpropsbycourseid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getPropsByCourseIdAndLocationId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.getpropsbycourseidandlocationid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/exhibits/` + param.payload.courseid + '/' + param.payload.locationid,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            mergeMap(data => {
                const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement => input !== null && input.tagName === 'IFRAME';

                let iframe: any = document.getElementById('PyramidIFrame');
                let sequence;
                let phonenum;
                let file;

                if(data.response.data[0].environment?.name == 'Pyramid'){
                    iframe = document.getElementById('PyramidIFrame');
                } else if (data.response.data[0].environment?.name == 'Chamber') {
                    iframe = document.getElementById('ChamberIFrame');
                } else if (data.response.data[0].environment?.name == 'Controlroom') {
                    iframe = document.getElementById('CommandIFrame');
                }

                if(data.response.data[0].file == null || data.response.data[0].file == ''){
                    file = '';
                } else {
                    file = '****' + data.response.data[0].file.substr(data.response.data[0].file.length - 4);
                }

                if(data.response.data[0].propid == 'APTP03'){
                    sequence = data.response.data[0].assignablevalue;
                    phonenum = '0000';
                } else if (data.response.data[0].propid == 'APTP06') {
                    sequence = '1234';
                    phonenum = data.response.data[0].assignablevalue;
                } else {
                    sequence = '1234';
                    phonenum = '0000';
                }

                if (isIFrame(iframe) && iframe.contentWindow) {
                    setTimeout(() => {
                        iframe.contentWindow.postMessage({
                            'assignableprops': data.response.data[0].propid
                        }, "*");
                    }, param.payload.timer);
                }

                return merge(
                    of(actions.getpropsbycourseidandlocationid.done({
                        params: param.payload,
                        result: { props: data.response },
                        }),
                        modal.show.started({type: 'showArtwork', source: data.response})
                    ),
                )
            }),
            catchError(error =>
                Observable.of(actions.getpropsbycourseidandlocationid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const updateProps: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.updateprops.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/exhibits/` + param.payload.propsid,
            method: 'PATCH',
            headers: { 'Content-Type': 'multipart/form-data' },
            body: param.payload.props
        }).pipe(
            map(data => {
                return actions.updateprops.done({
                    params: param.payload,
                    result: { props: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.updateprops.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const deleteProps: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.deleteprops.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/exhibits/` + param.payload.propsid,
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.deleteprops.done({
                    params: param.payload,
                    result: { props: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.deleteprops.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)
