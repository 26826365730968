import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'

import { makeStyles } from '@material-ui/core/styles';
import AccountInfoNew from 'components/AccountInfoNew'
import modal from 'actions/modal'
import BaseLayoutAdjustable from '../../../components/form/BaseLayoutAdjustable'

import BackSymbol from 'assets/BaseImg/backSymbol.png'
import SaveSymbol from 'assets/BaseImg/saveSymbol.png'

import bg from 'assets/bg.png'
import logo from 'assets/logo.png'
import textbg from 'assets/textfield.svg'

import axios from 'axios';
import information from 'assets/information.png'

const useStyles = makeStyles(() => ({
    TypeSetuprequiredfield: {
        color: "#FFD84C",
        fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
        letterSpacing: '1px',
        paddingBottom: '1rem',
    },
    trainingcourse: {
        cursor: 'pointer',
        width: '32rem'
    },
  headerTypeSetup: {
    fontFamily: "Impact",
    fontSize: "2.5rem",
    letterSpacing: "4px",
    paddingBottom: "2rem",
    color: '#FFFFFF'
  },
  inputTypeSetup: {
    background: 'transparent',
    position: 'absolute',
    top: '23%',
    left: '50%',
    transform: 'translate(-50%,0%)',
    width: '58%',
    border: 'none',
    textAlign: 'center',
    height: '58px',
    color: '#ffd84d',
    fontFamily: "Impact",
    fontSize: "1.2rem",

    '&::placeholder': {
      textAlign: 'center',
      color: '#ffd84d',
      textTransform: 'uppercase',
      border: 'none'
    },
    '&:focus': {
      border: 'none',
      outline: 'none'
    }
  },
  inputWrapperTypeSetup: {
    position: 'relative'
  },
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
      cursor: 'pointer'
    }
  },
  saveBtn: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3rem',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
  pageBottom: {
    display: 'flex',
    position: 'fixed',
    bottom: "1rem",
    right: "2rem",
  },
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '12rem'
  },
  externalRowTypeSetup: {
    display: 'flex',
    position: "relative",
    height: "45vh",
    bottom: "0rem"
  },
  row1TypeSetup: {
    display: 'flex',
    flexDirection: 'column',
    width: "43rem",
    height: "17rem",
    alignItems: "center",
    justifyContent: "space-evenly",
    '& img': {
      width: '39rem'
    }
  },
  information: {
    left: '5vw',
    width: '40px',
    bottom: '10vh',
    height: '40px',
    position: 'absolute',
    cursor: 'pointer',

    '& img': {
      width: '100%',
    }
  }
}));

const CourseTypeEdit = () => {
  const dispatch = useDispatch()
  const classes  = useStyles();

  const editData = useSelector((state: any) => state.router.location.state)
  const [CourseTypeName, setCourseTypeName] = React.useState(editData.name)
  const [CourseTypeDescription, setCourseTypeDescription] = React.useState(editData.description)

  function retrieveInfoFromInput(inputType: any, input: any) {
    switch (inputType) {
      case 'coursetype':
        setCourseTypeName(input);
        break;
      case 'description':
        setCourseTypeDescription(input);
        break;
      default:
        return false;
    }
  }

  // save button activation
  const saveButtonClicked = () => {
    if (CourseTypeName === "empty" || CourseTypeDescription === "empty") {
      alert('the name input or the description input is empty')
    } else {
      UpdateProps(CourseTypeName, CourseTypeDescription)
      dispatch(modal.show.started({type: 'saveCourseType', source: ''}))
    }
  }

  // axios
  const UpdateProps = async (name: string, description: string) => {
    const req = {
      "name": name,
      "description": description,
    }
    const response = await axios({
      method: 'PATCH',
      url: `${process.env.REACT_APP_BACKEND_URL}/coursetype/` + editData.id,
      headers: {
        "content-type": "application/json",
      },
      data: req
    })
    return response;
  }

  return (
        <BaseLayoutAdjustable bg={bg}>
            <div className={classes.topInfo}>
              <img src={logo} alt="logo" className={classes.logo} />
              <AccountInfoNew />
            </div>
          <div className="containerCoporateProfileSetup">
            <div className="row justify-content-center">
              <h3 className={classes.headerTypeSetup}>COURSE TYPE SETUP</h3>
            </div>
            <div className="row justify-content-center pb-3">
              <h4 className={classes.TypeSetuprequiredfield}>* INDICATES REQUIRED FIELD</h4>
            </div>
            <div className={classes.externalRowTypeSetup}>
              <div className={classes.row1TypeSetup}>
                <div className={classes.inputWrapperTypeSetup}>
                  <img src={textbg} alt="bg" />
                <input autoComplete="off" value={CourseTypeName} className={classes.inputTypeSetup} placeholder="COURSE TYPE NAME*" type="text"  onChange={(e) => retrieveInfoFromInput('coursetype', e.target.value)} />
                </div>
                <div className={classes.inputWrapperTypeSetup}>
                  <img src={textbg} alt="bg" />
                  <input autoComplete="off" value={CourseTypeDescription} className={classes.inputTypeSetup} placeholder="COURSE TYPE DESCRIPTION*" type="text" onChange={(e) => retrieveInfoFromInput('description', e.target.value)}/>
                </div>
              </div>
            </div>
          </div>
            <div className={classes.pageBottom}>
          <div className={classes.backBtn} onClick={() => dispatch(push('/corporate/coursetypelisting'))}>
                <img src={BackSymbol} alt="BackSymbol" />
                <p>BACK</p>
              </div>
              <div className={classes.saveBtn} onClick={() => saveButtonClicked()}>
                <img src={SaveSymbol} alt="BackSymbol" />
                <p>SAVE</p>
              </div>
            </div>
            <div className={classes.information} onClick={() => window.open('https://www.createscape.com.sg/documents/corporate-account-user-manual.pdf')} >
              <img src={information}/>
            </div>
        </BaseLayoutAdjustable>
  )
}

export default CourseTypeEdit;
