import { Observable } from 'rxjs'
import { Epic } from 'redux-observable'
import { AnyAction } from 'typescript-fsa'
import { ofAction } from 'typescript-fsa-redux-observable'
import actions from 'actions/accountpackage'
import { ajax } from 'rxjs/ajax'
import { catchError, map, mergeMap } from 'rxjs/operators'

export const createAccountPackage: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.createaccountpackage.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/accountpackage`,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.accountpackage
        }).pipe(
            map(data => {
                return actions.createaccountpackage.done({
                    params: param.payload,
                    result: { accountpackage: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.createaccountpackage.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getAccountPackageByUserId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.getaccountpackagebyuserid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/accountpackage/` + param.payload.userid,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.getaccountpackagebyuserid.done({
                    params: param.payload,
                    result: { accountpackage: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.getaccountpackagebyuserid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const updateAccountPackageByUserId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.updateaccountpackagebyuserid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/accountpackage/` + param.payload.userid,
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: param.payload.accountpackage
        }).pipe(
            map(data => {
                return actions.updateaccountpackagebyuserid.done({
                    params: param.payload,
                    result: { accountpackage: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.updateaccountpackagebyuserid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)