import { from, Observable, of } from 'rxjs'
import { ofType, Epic } from 'redux-observable'
import { AnyAction } from 'typescript-fsa'
import { ofAction } from 'typescript-fsa-redux-observable'
import actions from 'actions/gameresult'
import { ajax } from 'rxjs/ajax'
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators'

export const getGameresultByUserId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.getgameresultbyuserid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/gameresult/` + param.payload.userid + '?order=' + param.payload.order,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.getgameresultbyuserid.done({
                    params: param.payload,
                    result: { gameresult: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.getgameresultbyuserid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const getGameresultByCorporateId: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.getgameresultbycorporateid.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/gameresult/corporate/` + param.payload.corporateid + '?order=' + param.payload.order,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.getgameresultbycorporateid.done({
                    params: param.payload,
                    result: { gameresult: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.getgameresultbycorporateid.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)

export const deleteGameResults: Epic<AnyAction> = (action$, store) => action$.pipe(
    ofAction(actions.deletegameresult.started),
    mergeMap((param) =>
        ajax({
            url: `${process.env.REACT_APP_BACKEND_URL}/gameresult/` + param.payload.gameresultids,
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }).pipe(
            map(data => {
                return actions.deletegameresult.done({
                    params: param.payload,
                    result: { gameresult: data.response },
                })
            }),
            catchError(error =>
                Observable.of(actions.deletegameresult.failed({
                    params: param.payload,
                    error: error,
                })),
            ),
        ),
    ),
)