import React, { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { makeStyles } from '@material-ui/core/styles';
import BaseLayoutAdjustable from 'components/form/BaseLayoutAdjustable'
import bg from 'assets/bg.png'
import logo from 'assets/logo.png'
import BackSymbol from 'assets/BaseImg/backSymbol.png'
import tablebg from 'assets/CourseTypeManagement/TableBG.svg'
import yellowBg from 'assets/BaseImg/yellowBGshort.png'
import darkBG from 'assets/BaseImg/darkBgshort.png'
import SaveSymbol from 'assets/BaseImg/saveSymbol.png'
import modal from 'actions/modal'
import gameresult from 'actions/gameresult'
import TableBG from 'components/Tables/TableBG'
import 'assets/stylesheets/application.css'
import { InfoTable8Col } from 'components/Tables/InfoTable8Col'
// import AccountInfo from 'components/AccountInfo';
import AccountInfoNew from 'components/AccountInfoNew'

// for table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { Scrollbar } from 'react-scrollbars-custom';
import SimpleBarReact from "simplebar-react";

// for editing page
import { corporatePageActions } from 'actions/coporatePageActions';
import information from 'assets/information.png'

const useStyles = makeStyles(() => ({
  container2: {
    top: "6rem",
    width: "100vw",
    height: "89vh",
    display: "flex",
    position: "absolute",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  hidden: {
    visibility: "hidden",
    pointerEvents: "none",
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'fixed',
    bottom: '1rem',
    right: '2rem',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
  header: {
    color: '#fffffe',
    fontFamily: 'impact',
    fontSize: '2.5rem'
  },
  // back button
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'fixed',
    bottom: '1rem',
    right: '2rem',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '2vw',
      height: '100%',
    }
  },
  // back button and save button style
  backBtn1: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
      cursor: 'pointer'
    }
  },
  saveBtn: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '3rem',
    cursor: 'pointer',
    '& p': {
      color: '#FFD84E',
      fontFamily: 'impact',
      fontSize: '1.2rem',
      letterSpacing: '1px',
      paddingLeft: '1rem'
    },
    '& img': {
      width: '3vw%',
      height: '100%',
    }
  },
  pageBottom: {
    display: 'flex',
    position: 'fixed',
    bottom: "1rem",
    right: "2rem",
  },
  // page top
  topInfo: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "6rem",
    paddingRight: "6rem",
  },
  logo: {
    width: '12rem'
  },
  buttonContainer: {
    width: "103rem",
    height: "11rem",
    cursor: "pointer",
    paddingTop: "2rem",
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '27vw',
      height: '13vh'
    }
  },
  inputWrapper: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2vw',
      width: '20rem'
    },
    '& img': {
      width: '20vw'
    }
  },
  inputWrapperDark: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#666767',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2vw',
      width: '20rem'
    },
    '& img': {
      width: '20vw'
    }
  },
  inputWrapperShort: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: '#FFFFFF',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2vw',
      width: '20rem'
    },
    '& img': {
      width: '20vw'
    }
  },
  inputWrapperShortDark: {
    position: 'relative',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '3rem',
    '& p': {
      color: '#666767',
      fontFamily: 'impact',
      letterSpacing: '1px',
      position: 'fixed',
      fontSize: '1.2vw',
      width: '20rem'
    },
    '& img': {
      width: '20vw'
    }
  },
  row2: {
    display: 'flex',
    marginTop: '1rem'
  },
  table: {
    minWidth: 650,
  },
  cell: {
    borderBottom: "none",
    color: "#FFD84C",
    alignItems: "center",
    fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    fontSize: '1.2rem',
  },
  cellHead: {
    borderBottom: "none",
    color: "#FFFFFE",
    alignItems: "center",
    fontFamily: "Georgia, Haettenschweiler, 'Arial Narrow Bold', sans-serif",
    fontSize: '1.2rem',
  },
  // for scroll bar
  scroll: {
    width: '100%',
    height: '54vh',
    borderRadius: '4px',
    position: "relative",
  },
  // for new scroll bar
  simpleBar: {
    maxHeight: '51vh'
  },
  // highlight row
  highlightRow: {
    border: 'solid',
    borderWidth: '2px',
    color: 'white'
  },
  NohighlightRow: {
    border: 'none',
  },
  checkbox: {
    color: '#ffffff',
  },
  information: {
    left: '5vw',
    width: '40px',
    bottom: '10vh',
    height: '40px',
    position: 'absolute',
    cursor: 'pointer',

    '& img': {
      width: '100%',
    }
  }
}));

const CorporateListing = () => {
  const dispatch = useDispatch()
  const classes = useStyles();

  let arraytoStorekeyToHighLight: string[] = []

  const [selectedRow, setSelectedRow] = useState(arraytoStorekeyToHighLight)
  const [showEditPage, setshowEditPage] = useState(false)
  const [buttonShown, setButtonsShown] = useState(false)

  const id = useSelector((state: any) => state.auth.loggedinuser.user.id)
  const gameresults = useSelector((state: any) => state.gameresult.data)
  const deleted = useSelector((state: any) => state.user.deleted)

  useEffect(() => {
    if(deleted) {
      setSelectedRow(arraytoStorekeyToHighLight);
      setButtonsShown(false)
    }
    dispatch(gameresult.getgameresultbycorporateid.started({corporateid: id, order: 'DESC'}))
  }, [id, deleted])

  const checkBoxInfo = (event: ChangeEvent<HTMLInputElement>, gameresult: any) => {
    let checked = event.target.checked

    if (checked === true) {
      selectedRow.push(gameresult.id)
    } else {
      selectedRow.splice(selectedRow.indexOf(gameresult.id), 1)
    }

    if (selectedRow.length < 1) {
      setButtonsShown(false)
    } else {
      if (selectedRow.length < 2) {
        setButtonsShown(true)
      } else {
        setButtonsShown(true)
      }
    }
  };

  const [headers] = React.useState([{
    col1: 'COURSE TYPE',
    col2: 'COURSE NAME',
    col3: 'TRANIEE/USER NAME',
    col4: 'SESSION DATE',
    col5: 'GAME TIME',
    col6: 'PERCENTAGE COMPLETED',
    col7: 'HINT USED',
    col8: 'SELECT',
    key: "0",
  }]);

  function deleteButton() {
    let ids = selectedRow.join(",")
    dispatch(modal.show.started({type: 'DeletePerformanceRecords', source: ids}))
  }

  function convertDate(value: any) {
    let newDate1 = value.substring(0, 10).split('-').reverse().join('/');
    var hours = parseInt(value.substring(11, 13), 10),
        minutes = parseInt(value.substring(14, 17), 10),
        ampm    = 'am';
    if (hours == 12) {
      ampm = 'pm';
    } else if (hours == 0) {
      hours = 12;
    } else if (hours > 12) {
      hours -= 12;
      ampm = 'pm';
    }

    let newDate2 = hours + ':' + minutes + ' ' + ampm;
    let newConstructedDate = `${newDate1} ${newDate2}`
    return newConstructedDate
  }

  const EditButtons = (value: any) => {
    switch (value) {
      case true:
        return (
          <div className={classes.row2}>
            {/* <button onClick={()=> populateTable()}>show records</button> */}
            <div className={classes.inputWrapper} onClick={() => deleteButton()}>
              <img src={yellowBg} alt="bg" />
              <p>DELETE PERFORMANCE RECORDS</p>
            </div>
            <div className={classes.inputWrapper}>
              <img src={yellowBg} alt="bg" />
              <p>UPDATE</p>
            </div>
          </div>
        )
      default:
        return (
          <div className={classes.row2}>
            <div className={classes.inputWrapperDark}>
              <img src={darkBG} alt="bg" />
              <p>DELETE PERFORMANCE RECORDS</p>
            </div>
            <div className={classes.inputWrapper}>
              <img src={yellowBg} alt="bg" />
              <p>UPDATE</p>
            </div>
          </div>
        )
    }
  }

  function tableRow(tabeInfo: any, arrayToCompare: any) {
    if (arrayToCompare.includes(tabeInfo.id) === true) {
      return (
        <TableRow key={tabeInfo.id} className={classes.highlightRow} >
          <TableCell className={classes.cell} align="center">{tabeInfo.course.coursetype.name}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.course.name}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.user.name}</TableCell>
          <TableCell className={classes.cell} align="center">{convertDate(tabeInfo.datetaken)}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.timetaken}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.percentcompletion}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.hintused}</TableCell>
          <TableCell className={classes.cell} align="center"><Checkbox className={classes.checkbox} onChange={(e) => checkBoxInfo(e, tabeInfo)} /></TableCell>
        </TableRow>
      )
    } else {
      return (
        <TableRow key={tabeInfo.id} className={classes.NohighlightRow} >
          <TableCell className={classes.cell} align="center">{tabeInfo.course.coursetype.name}</TableCell>
          <TableCell className={classes.cell} align="center" >{tabeInfo.course.name}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.user.name}</TableCell>
          <TableCell className={classes.cell} align="center">{convertDate(tabeInfo.datetaken)}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.timetaken}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.percentcompletion}</TableCell>
          <TableCell className={classes.cell} align="center">{tabeInfo.hintused}</TableCell>
          <TableCell className={classes.cell} align="center"><Checkbox className={classes.checkbox} onChange={(e) => checkBoxInfo(e, tabeInfo)} /></TableCell>
        </TableRow>
      )
    }
  }

  return (
    <BaseLayoutAdjustable bg={bg}>
      <div className={classes.topInfo}>
        <img src={logo} alt="logo" className={classes.logo} />
        <AccountInfoNew />
      </div>
      <div className={showEditPage === true ? classes.hidden : classes.container2}>
        <h3 className={classes.header}>PAST TRAINING SESSIONS PERFORMANCE RECORDS</h3>
        <TableBG bg={tablebg}>
          <div className={classes.scroll}>
            <SimpleBarReact className={classes.simpleBar}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  {headers.map((col) =>
                    <TableRow key={col.key}>
                      <TableCell className={classes.cellHead} align="center">{col.col1}</TableCell>
                      <TableCell className={classes.cellHead} align="center">{col.col2}</TableCell>
                      <TableCell className={classes.cellHead} align="center">{col.col3}</TableCell>
                      <TableCell className={classes.cellHead} align="center">{col.col4}</TableCell>
                      <TableCell className={classes.cellHead} align="center"> {col.col5}</TableCell>
                      <TableCell className={classes.cellHead} align="center"> {col.col6}</TableCell>
                      <TableCell className={classes.cellHead} align="center"> {col.col7}</TableCell>
                      <TableCell className={classes.cellHead} align="center"> {col.col8}</TableCell>
                    </TableRow>
                  )}
                </TableHead>
                <TableBody>
                  {gameresults != null ?
                      gameresults.data.map((row: any) =>
                        tableRow(row, selectedRow)
                      )
                    : null}
                </TableBody>
              </Table>
            </SimpleBarReact>
            {/* </CustomScrollbars> */}
          </div>
        </TableBG>
        {EditButtons(buttonShown)}
      </div>
      <div className={buttonShown === true || showEditPage === true ? classes.hidden : classes.backBtn} onClick={() => dispatch (push('/corporate/overview'))}>
        <img src={BackSymbol} alt="BackSymbol" />
        <p>BACK</p>
      </div>
      <div className={classes.information} onClick={() => window.open('https://www.createscape.com.sg/documents/corporate-account-user-manual.pdf')} >
        <img src={information}/>
      </div>
    </BaseLayoutAdjustable>
  )
}

export default CorporateListing;
